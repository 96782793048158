import React, { useState, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useTier, useAdminRole } from '../../../../auth/hooks'
import {
    Flex,
    Box,
    Center,
    Text,
    IconButton,
    Button,
    Collapse,
    useColorModeValue,
    useBreakpoint,
    keyframes,
} from '@chakra-ui/react'
import { isMobileOnly, isIOS, isDesktop, isTablet } from 'react-device-detect'
import { MdOutlineExpandLess } from 'react-icons/md'

import SidebarFolderItem from './SidebarFolderItem'
import DataCountBadge from './DataCountBadge'
import LayersIcon from '../../../../icons/menu-icons/LayersLineIcon'
import CloseIcon from '../../../../icons/menu-icons/CloseIcon'
import PremiumBadge from '../../PremiumBadge'
import MenuIcon from '../../../../icons/menu-icons/MenuIcon'

import {
    dataModeState,
    layerSelectionState,
    contrastState,
    briefingOptionsState,
    briefingStandardState,
    fullscreenModalVisibleState,
    menuExpandedState,
    setMenuExpandedState,
    closeLayerSelectorHandler,
    simulateFreeUserState,
    mfaVerifiedState,
    mapDisabledState,
    isBriefingOnlyState,
    wipMenuItemsState,
} from '../../../../globalState'

//BUTTON PULSE
const pulsate = keyframes`
0% {-webkit-transform: scale(0.8, 0.8); opacity: 0.0; }
50% {opacity: 0.5; }
100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;  }`

export default function SidebarCollection({ id, items }) {
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const [briefingOptions, setBriefingOptions] =
        useRecoilState(briefingOptionsState)
    const [briefingStandard, setBriefingStandard] = useRecoilState(
        briefingStandardState
    )
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const [active, setActive] = useState(false)
    const [selectedLayer, setSelectedLayer] = useState(null)
    const mounted = useRef(false)
    const contrastHandler = useRecoilValue(contrastState)
    const fullscreenModalOpen = useRecoilValue(fullscreenModalVisibleState)
    const closeLayerSelector = useSetRecoilState(closeLayerSelectorHandler)
    const setExpanded = useSetRecoilState(setMenuExpandedState)
    const menuExpanded = useRecoilValue(menuExpandedState)
    const wipMenuItems = useRecoilValue(wipMenuItemsState)
    const expanded = menuExpanded[id]
    const [pulse, setPulse] = useState(false)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isAdmin = useAdminRole()
    const isHidden = !(wipMenuItems && isAdmin)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const breakpoint = useBreakpoint()
    const hideMenuButton = fullscreenModalOpen && !expanded

    function expandHandler() {
        briefingOptions && setBriefingOptions(false)
        briefingStandard && setBriefingStandard(false)
        if (isDesktop || isTablet) {
            if (mapDisabled && id === 'aero' && !expanded) {
                // stops AERO menu expanding when selected
                setExpanded({ [id]: false })
            } else {
                setExpanded({ [id]: !expanded })
            }
            dataMode !== id && setDataMode(id)
        } else {
            if (
                // stops AERO menu expanding when selected
                !(mapDisabled && id === 'aero')
            ) {
                ;(dataMode === id || id === 'met') &&
                    setExpanded({ [id]: !expanded })
            }
            dataMode !== id && setDataMode(id)
        }
    }

    // if opening a fullscreen modal, close the layer selector
    useEffect(() => {
        if (mapDisabled && fullscreenModalOpen && dataMode === 'aero') {
            closeLayerSelector()
        } else if (fullscreenModalOpen && breakpoint === 'base') {
            closeLayerSelector()
        }
    }, [
        fullscreenModalOpen,
        breakpoint,
        dataMode,
        mapDisabled,
        isHidden,
        closeLayerSelector,
    ])

    //TODO - update this to adapt for briefing draw mode when developed.

    useEffect(() => {
        if (mapDisabled && id === 'aero') {
            // stops AERO menu expanding when initially selected
            setExpanded({ [id]: false })
        } else if (!isBriefingOnly) {
            if (
                ((mounted.current && (isDesktop || isTablet)) ||
                    dataMode === 'met') &&
                dataMode !== 'brief'
            ) {
                setExpanded({ [id]: dataMode === id })
            }
            if (dataMode === 'brief') {
                setExpanded({ [id]: false })
            }
        }
        setActive(!(briefingOptions || briefingStandard) && dataMode === id)
        mounted.current = true
    }, [
        mapDisabled,
        isBriefingOnly,
        dataMode,
        id,
        briefingOptions,
        briefingStandard,
        setExpanded,
    ])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }
    const focusShadow = {
        _focus: {
            boxShadow: useColorModeValue(
                '0px 1px 12px -5px rgba(0, 0, 0, 0.45)',
                '0px 1px 12px -5px rgba(0, 0, 0, 0.45)'
            ),
        },
    }

    const buttonShadowOpen = useColorModeValue(
        '0px 1px 12px -5px rgba(0, 0, 0, 0.5)',
        '0px 1px 12px -5px rgba(0, 0, 0, 0.5)'
    )

    const buttonShadowClosed = useColorModeValue(
        '0px 3px 12px -5px rgba(0, 0, 0, 0.3)',
        '0px 2px 20px -8px rgba(255,255,255,0.3)'
    )

    // Sidebar Style Colors (Light and Dark Mode)

    const collapseButtonOpen = useColorModeValue('light.10', 'dark.10')
    const collapseButtonColor = useColorModeValue('brand.strong', 'dark.80')
    const collapseButtonOpenHover = useColorModeValue('light.10', 'dark.20')
    const sidebarText = useColorModeValue('light.100', 'dark.100')
    const textHover = useColorModeValue('light.80', 'dark.80')
    const collectionItem = useColorModeValue('brand.strong', 'dark.90')
    const collectionItemBg = useColorModeValue('light.20', 'dark.20')
    const collectionItemHover = useColorModeValue('light.20', 'dark.20')
    const collectionItemActive = useColorModeValue('light.200', 'dark.200')
    const collectionItemPremium = useColorModeValue('light.300', 'dark.81')
    const folderItemColor = useColorModeValue('brand.strong', 'dark.100')
    const folderItemBg = useColorModeValue('light.30', 'dark.30')
    const folderItemBgMobile = useColorModeValue('light.10', 'dark.30')
    const collapseButton = useColorModeValue('#eff2f5', 'dark.20')
    const collapseButtonIcon = useColorModeValue('brand.strong', 'dark.90')
    const collapseButtonHigh = useColorModeValue('light.10', 'dark.304')
    const collapseButtonMedium = useColorModeValue('light.10', 'dark.303')
    const collapseButtonStandard = useColorModeValue('light.10', 'dark.301')
    const buttonPulse = useColorModeValue('rgba(255,255,255,1)', 'dark.306')
    const collapseButtonHover = useColorModeValue('light.10', 'dark.304')
    const navButtonRingInner = useColorModeValue('light.10', 'dark.53')

    // Below we detect what layers are active
    useEffect(() => {
        const subfolders = !!items.filter((c) => c.items).length
        const allLayers = items.flatMap((c) => c.items || c)
        const active = Object.keys(layerSelection[dataMode]).filter(
            (k) => layerSelection[dataMode][k] === true
        )
        const selectedLayers = allLayers.filter((c) => c.id === active[0])
        setSelectedLayer(
            subfolders && selectedLayers.length ? selectedLayers[0] : null
        )
    }, [dataMode, items, layerSelection])

    function openCloseIconHandler() {
        if (expanded) {
            return <CloseIcon color={collapseButtonIcon} boxSize="7" />
        } else if (!selectedLayer) {
            return <LayersIcon color={collapseButtonIcon} boxSize="6" />
        }
    }

    const selectedIconHandler = () => {
        if (expanded) {
            return <CloseIcon color={collapseButtonIcon} boxSize="7" />
        } else {
            return (
                <MenuIcon
                    boxSize="7"
                    id={selectedLayer.id}
                    color={collectionItemActive}
                    active={true}
                />
            )
        }
    }

    const selectedFolderIconHandler = () => {
        return (
            <MenuIcon
                id={selectedLayer.id}
                color={
                    contrastHandler === 'high'
                        ? 'light.10' // If High Contrast Layer
                        : contrastHandler === 'medium'
                        ? 'light.10' // If Medium Contrast Layer
                        : 'light.10' // All other layers
                }
            />
        )
    }

    const pulseAnimation = `${pulsate} infinite 2s linear`

    const met = layerSelection && layerSelection.met
    const isFullscreenModal =
        (dataMode === 'met' &&
            (met.rainRadarImagery ||
                met.graforCharts ||
                met.cloudImagery ||
                met.sigwxCharts ||
                met.sigmetCharts ||
                met.mslCharts ||
                met.spacewx ||
                met.lowcloudforecast ||
                met.rainforecast)) ||
        dataMode === 'brief'

    return (
        <>
            {dataMode === 'met' &&
                !briefingStandard &&
                selectedLayer &&
                layerSelection &&
                !isFullscreenModal && (
                    <Box //Breadcrumb
                        pos={'fixed'}
                        bottom={'37px'}
                        left={65}
                        display={{
                            base: 'none',
                            md:
                                fullscreenModalOpen ||
                                selectedLayer.id === 'sigmet' ||
                                selectedLayer.id === 'sigwx'
                                    ? 'none'
                                    : 'block',
                        }}
                    >
                        <Center
                            h={'36px'}
                            w={'36px'}
                            bgColor={
                                contrastHandler === 'high'
                                    ? 'rgba(180, 195, 200,0.8)' // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? 'rgba(180, 195, 200,0.5)' // If Medium Contrast Layer
                                    : 'rgba(180, 195, 200, 0.4)' // All other layers
                            }
                            boxShadow={
                                contrastHandler === 'high'
                                    ? '0px 3px 12px -5px rgba(0, 0, 0, 0.5)' // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? '0px 3px 12px -5px rgba(0, 0, 0, 0.4)' // If Medium Contrast Layer
                                    : '0px 2px 12px -5px rgba(0, 0, 0, 0.2)' // All other layers
                            }
                            borderRadius={'20px'}
                            backdropFilter="blur(5px)"
                            border={'1.5px solid'}
                            borderColor={'rgba(255,255,255,0.7)'}
                            pos={'absolute'}
                            transform={'translate(0%, -50%)'}
                            color={'light.10'}
                            top={'50%'}
                            left={0}
                            zIndex="1"
                        >
                            {selectedFolderIconHandler()}
                        </Center>
                        <Flex
                            boxShadow={
                                contrastHandler === 'high'
                                    ? '0px 5px 30px -7px rgba(0, 0, 0, 0.4)' // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? '0px 5px 30px -7px rgba(0, 0, 0, 0.2)' // If Medium Contrast Layer
                                    : '0px 5px 35px -7px rgba(0, 0, 0, 0.07)' // All other layers
                            }
                            bgColor={
                                contrastHandler === 'high'
                                    ? 'rgba(255,255,255,0.35)' // If High Contrast Layer
                                    : contrastHandler === 'medium'
                                    ? 'rgba(255,255,255,0.25)' // If Medium Contrast Layer
                                    : 'rgba(255,255,255,0.18)' // All other layers
                            }
                            backdropFilter="blur(5px)"
                            pt={'6px'}
                            pb={'7px'}
                            pl={'47px'}
                            pr={'14px'}
                            minHeight="34px"
                            borderRadius={'20px'}
                            alignItems="center"
                        >
                            <Text
                                textShadow="1px 1px 3px rgba(0,0,0,0.25)"
                                color={'white'}
                                fontWeight={'600'}
                                fontSize="0.8rem"
                                letterSpacing={'0.4px'}
                            >
                                {/* {`${subfolderProps.layer} >   // Use if we want primary folder listed also */}
                                {`${selectedLayer.label}`}

                                {/* )} */}
                            </Text>
                            <DataCountBadge id={selectedLayer.id} />
                        </Flex>
                    </Box>
                )}

            {!isBriefingOnly && (
                <Flex /// *** Main Navigation Buttons - AERO, MET, ADVS, BRIEF ***
                    zIndex="1"
                    position={'relative'}
                    flexDir={{ base: 'row', md: 'column' }}
                    w={{ base: '20vw', md: '100%' }}
                    color={folderItemColor}
                    alignItems={'center'}
                >
                    <Button
                        {...noFocus}
                        id={id}
                        borderRadius={0}
                        border="none"
                        px="0px"
                        w={{ base: '20vw', md: '100%' }}
                        marginBottom={{
                            base: isMobileOnly && isIOS ? '-15px' : '0',
                            md: '0',
                        }}
                        paddingBottom={{
                            base: isMobileOnly && isIOS ? '15px' : 'initial',
                            md: 'initial',
                        }}
                        height={{
                            base: isMobileOnly && isIOS ? '85px' : '70px',
                            md: '70px',
                        }}
                        onClick={() => {
                            expandHandler()
                        }}
                        variant="outline"
                        color={collectionItem}
                        bgColor={{
                            base: 'none',
                            md: active && collectionItemBg,
                        }}
                        _focus={{
                            border: 'none',
                            backgroundColor: {
                                base: 'none',
                                md: collectionItemHover,
                            },
                            color: textHover,
                        }}
                        _hover={{
                            border: 'none',
                            backgroundColor: {
                                base: 'none',
                                md: collectionItemHover,
                            },
                            color: textHover,
                        }}
                        _active={{
                            border: 'none',
                            backgroundColor: {
                                base: 'none',
                                md: collectionItemHover,
                            },
                            color: textHover,
                        }}
                    >
                        <Flex
                            pt={{ base: '0px', md: 'unset' }}
                            w="100%"
                            height="100%"
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            zIndex="1"
                        >
                            <Flex //DATAMODE ICON
                                marginBottom={{
                                    base: active ? '22px' : '2px',
                                    md: '0px',
                                }}
                                transition="all ease-out 300ms"
                                zIndex="1"
                            >
                                <MenuIcon
                                    id={id}
                                    active={active}
                                    color={
                                        id === 'advs' && isPremium
                                            ? collectionItemPremium
                                            : active
                                            ? collectionItemActive
                                            : collectionItem
                                    }
                                />

                                {/* *** PREMIUM BADGE *** */}
                                {id === 'advs' && isPremium && (
                                    <Flex
                                        zIndex="1"
                                        position="absolute"
                                        marginLeft={{
                                            base: '18px',
                                            md: '18px',
                                        }}
                                        mt={{
                                            base: '-3px',
                                            md: '-3px',
                                        }}
                                        transition="all ease-in 200ms"
                                    >
                                        <PremiumBadge />
                                    </Flex>
                                )}
                            </Flex>
                            <Text // DATAMODE TITLE
                                position={{
                                    base: 'absolute',
                                    md: 'relative',
                                }}
                                mt={{
                                    base: active ? '45px' : '25px',
                                    md: '2px',
                                }}
                                fontSize={{
                                    base: '0.65rem',
                                    md: active ? '0.65rem' : '0.55rem',
                                }}
                                color={
                                    id === 'advs' && isPremium
                                        ? collectionItemPremium
                                        : active
                                        ? collectionItemActive
                                        : collectionItem
                                }
                                opacity={{ base: active ? '1' : '0', md: '1' }}
                                height={{
                                    base: '15px',
                                    md: 'auto',
                                }}
                                fontWeight={active ? '500' : '400'}
                                letterSpacing="0.5px"
                                _active={{
                                    color:
                                        id === 'advs' && isPremium
                                            ? collectionItemPremium
                                            : active
                                            ? collectionItemActive
                                            : collectionItem,
                                }}
                                _selected={{
                                    color:
                                        id === 'advs' && isPremium
                                            ? collectionItemPremium
                                            : collectionItemActive,
                                }}
                                transition="all ease-out 400ms"
                            >
                                {
                                    id.toUpperCase() /* use the upper case version (col) */
                                }
                            </Text>
                        </Flex>
                        <Flex
                            display={{ base: 'flex', md: 'none' }}
                            direction="column"
                            alignItems="center"
                            justifyContent="flex-start"
                            position="absolute"
                            bottom="0"
                            width="100%"
                            height={{
                                base: isMobileOnly && isIOS ? '85px' : '70px',
                                md: '70px',
                            }}
                            marginBottom={{
                                base: isMobileOnly && isIOS ? '-3px' : '-3px',
                                md: '100%',
                            }}
                            opacity={active ? '1' : '0'}
                            _hover={{ opacity: '1' }}
                        >
                            <Flex // SELECTED DATAMODE INNER CIRCLE
                                opacity={active ? '1' : '0'}
                                position="absolute"
                                width={active ? '42px' : '0px'}
                                height={active ? '42px' : '0px'}
                                boxShadow="inset 0px 2px 6px -2px rgba(0,0,0,0.25)"
                                bg={navButtonRingInner}
                                transition="all ease-in 250ms"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="100%"
                            ></Flex>
                        </Flex>
                    </Button>
                    {/* *** Mobile Nav *** */}
                    <Flex
                        willChange="auto"
                        id={`${id}Layers`}
                        direction={'column'}
                        position={{ base: 'fixed', md: 'relative' }}
                        borderRadius={{ base: '25px', md: '0px' }}
                        w={{ base: '46px', md: '100%' }}
                        left={{ base: '18px', md: 0 }}
                        bottom={{
                            base: isMobileOnly && isIOS ? '121px' : '106px',
                            md: 0,
                        }}
                        transition="all ease-in 250ms"
                        backgroundColor={{
                            base: expanded ? folderItemBgMobile : 'transparent',
                            md: folderItemBg,
                        }}
                        _active={{
                            transition: 'all ease-in 300ms',
                        }}
                        pt={{ base: 'none', md: expanded && '10px' }}
                        boxShadow={{
                            base: 'none',
                            md: 'inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)',
                        }}
                        display={active ? 'block' : 'none'}
                        opacity={hideMenuButton ? '0' : '1'}
                        visibility={hideMenuButton ? 'hidden' : 'visible'}
                    >
                        <Collapse
                            animateOpacity
                            in={active && expanded}
                            position={'relative'}
                            style={{
                                overflow: expanded ? 'initial' : 'hidden',
                                opacity: expanded ? '1' : '0',
                                clipPath: 'inset(0px -350px -60px 0px)',
                            }}
                        >
                            <Flex
                                w="100%"
                                direction={'column'}
                                position={'relative'}
                                pt={{ base: '12px', md: '0px' }}
                                pb={{
                                    base:
                                        dataMode === 'met' && expanded && '0px', // increase this if any subfolder menu is longer than overall folder menu height (drops below close button)
                                    md: '0',
                                }}
                            >
                                {items.map((item, i) => (
                                    <SidebarFolderItem
                                        key={`SidebarCollection${id}${item}${i}`}
                                        item={item}
                                        folderIsActive={active && expanded}
                                        isPremium={isPremium}
                                    />
                                ))}
                                <Flex
                                    display={{ base: 'none', md: 'block' }}
                                    position={'relative'}
                                >
                                    {/* *** Desktop Nav bottom collapse chevron *** */}
                                    <IconButton
                                        m={0}
                                        w={'30px'}
                                        fontSize="1.4rem"
                                        borderRadius={20}
                                        minWidth="30px"
                                        height="30px"
                                        margin="10px"
                                        mt="0px"
                                        color={sidebarText}
                                        bgColor={{
                                            base: folderItemBg,
                                            md: folderItemBg,
                                        }}
                                        _hover={{
                                            backgroundColor: collapseButton,
                                        }}
                                        aria-label="Search database"
                                        icon={<MdOutlineExpandLess />}
                                        onClick={() =>
                                            setExpanded({ [id]: false })
                                        }
                                        {...noFocus}
                                    />
                                </Flex>
                            </Flex>
                        </Collapse>

                        {/* *** Mobile Layer Selector Button  *** */}
                        {!(mapDisabled && id === 'aero') && (
                            <Flex
                                id={'mobileNavButton'}
                                marginLeft="-12px"
                                marginTop="5px"
                                marginBottom={'-10px'}
                                display={{ base: 'flex', md: 'none' }}
                                w={{ base: '70px', md: '40px' }}
                                h={{ base: '70px', md: '40px' }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...focusShadow}
                                    w={{
                                        base: '50px',
                                        md: '40px',
                                    }}
                                    h={{
                                        base: '50px',
                                        md: '40px',
                                    }}
                                    borderRadius="100%"
                                    backdropFilter="blur(5px)"
                                    boxShadow={
                                        expanded
                                            ? buttonShadowOpen
                                            : buttonShadowClosed
                                    }
                                    background={{
                                        base: expanded
                                            ? collapseButtonOpen
                                            : contrastHandler === 'high'
                                            ? collapseButtonHigh // If High Contrast Layer
                                            : contrastHandler === 'medium'
                                            ? collapseButtonMedium // If Medium Contrast Layer
                                            : collapseButtonStandard, // All other layers
                                        md: folderItemBg,
                                    }}
                                    color={collapseButtonColor}
                                    _active={{
                                        backgroundColor: expanded
                                            ? collapseButtonOpenHover
                                            : contrastHandler === 'high'
                                            ? collapseButtonHigh // If High Contrast Layer
                                            : contrastHandler === 'medium'
                                            ? collapseButtonMedium // If Medium Contrast Layer
                                            : collapseButtonStandard, // All other layers
                                    }}
                                    _hover={{
                                        backgroundColor: expanded
                                            ? collapseButtonOpenHover
                                            : contrastHandler === 'high'
                                            ? collapseButtonHover // If High Contrast Layer
                                            : contrastHandler === 'medium'
                                            ? collapseButtonHigh // If Medium Contrast Layer
                                            : collapseButtonMedium, // All other layers
                                    }}
                                    _focus={{
                                        backgroundColor: expanded
                                            ? collapseButtonOpenHover
                                            : contrastHandler === 'high'
                                            ? collapseButtonHover // If High Contrast Layer
                                            : contrastHandler === 'medium'
                                            ? collapseButtonHigh // If Medium Contrast Layer
                                            : collapseButtonMedium, // All other layers
                                    }}
                                    opacity={hideMenuButton ? '0' : '1'}
                                    visibility={
                                        hideMenuButton ? 'hidden' : 'visible'
                                    }
                                    transition="opacity ease-out 300ms, visibility ease-out 300m"
                                    zIndex="1"
                                    position="absolute"
                                    onClick={() => {
                                        setExpanded({ [id]: !expanded })
                                        setPulse((prevState) => !prevState)
                                    }}
                                    willChange="auto"
                                >
                                    {selectedLayer
                                        ? selectedIconHandler()
                                        : openCloseIconHandler()}
                                </Button>
                                <Box //OPENING PULSE
                                    position="absolute"
                                    w={{
                                        base: pulse ? '70px' : '40px',
                                        md: '40px',
                                    }}
                                    h={{
                                        base: pulse ? '70px' : '40px',
                                        md: '40px',
                                    }}
                                    margin="auto"
                                    borderRadius="100%"
                                    background={{
                                        base: pulse
                                            ? buttonPulse
                                            : 'transparent',
                                        md: 'none',
                                    }}
                                    sx={{
                                        animationDuration: '500ms',
                                        animationIterationCount: '1',
                                        animationDelay: '0s',
                                    }}
                                    opacity={{
                                        base: pulseAnimation ? '0' : '1',
                                        md: '0',
                                    }}
                                    transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 200ms"
                                    animation={pulse && pulseAnimation}
                                ></Box>
                                <Box // CLOSING PULSE
                                    animation={!pulse && pulseAnimation}
                                    position="absolute"
                                    w={{
                                        base: !pulse ? '70px' : '40px',
                                        md: '40px',
                                    }}
                                    h={{
                                        base: !pulse ? '70px' : '40px',
                                        md: '40px',
                                    }}
                                    margin="auto"
                                    borderRadius="100%"
                                    background={{
                                        base: !pulse
                                            ? buttonPulse
                                            : 'transparent',
                                        md: 'none',
                                    }}
                                    sx={{
                                        animationDuration: '500ms',
                                        animationIterationCount: '1',
                                        animationDelay: '0s',
                                    }}
                                    opacity={{
                                        base: pulseAnimation ? '0' : '1',
                                        md: '0',
                                    }}
                                    transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 200ms"
                                ></Box>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            )}
        </>
    )
}

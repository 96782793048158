// READ THIS FIRST
// The functionality of the map changing between light/dark mode has been disabled for now
// To re-enable, uncomment the imports at the top, remove the live handleTransition from the useEffect, and uncomment the other actions in the useEffect

import { useCallback, useContext, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
// import { useColorMode } from '@chakra-ui/react'
import { MapContext } from 'react-mapbox-gl'
import { lightLayers } from '../basemap-definitions/light'
// import { darkLayers } from '../basemap-definitions/dark'
import { basemapState } from '../../globalState'

export default function MapColorModeHandler() {
    // const { colorMode } = useColorMode()
    const mapInstance = useContext(MapContext)
    const basemap = useRecoilValue(basemapState)

    // this handleTransition function maps through the various layout / paint properties of the standard mapbox light or dark maps and sets the properties accordingly
    // this strategy likely only works between comparitive maps (ie light / dark), and propbably wouldn't work with more complex map changes (ie the light style to the outdoors style)
    const handleTransition = useCallback(
        (layers, basemap) => {
            // set layout properties
            layers.map((layer) => {
                if (layer.layout && Object.keys(layer.layout).length > 0) {
                    Object.keys(layer.layout).map((prop) => {
                        mapInstance.setLayoutProperty(
                            layer.id,
                            prop,
                            layer.layout[prop]
                        )
                        return null
                    })
                }

                // set paint properties
                if (layer.paint && Object.keys(layer.paint).length > 0) {
                    Object.keys(layer.paint).map((prop) => {
                        // set water layer color based on basemap
                        if (layer.id === 'water' && prop === 'fill-color') {
                            mapInstance.setPaintProperty(
                                layer.id,
                                'fill-color',
                                basemap === 'vnc'
                                    ? 'hsl(204, 33%, 93%)'
                                    : basemap === 'linztopo'
                                    ? 'hsl(204, 23%, 89%)'
                                    : 'hsl(202, 60%, 80%)'
                            )
                        }
                        // set water-depth layer opacity based on basemap
                        else if (
                            layer.id === 'water-depth' &&
                            prop === 'fill-opacity'
                        ) {
                            const waterDepthOpacity =
                                basemap === 'vnc' || basemap === 'linztopo'
                                    ? 0
                                    : [
                                          'interpolate',
                                          ['exponential', 1.5],
                                          ['zoom'],
                                          2,
                                          0.3,
                                          7,
                                          0.3,
                                          8,
                                          0.25,
                                          9,
                                          0.15,
                                          10,
                                          0.05,
                                          11,
                                          0,
                                      ]
                            mapInstance.setPaintProperty(
                                layer.id,
                                'fill-opacity',
                                waterDepthOpacity
                            )
                        }
                        // else just apply the config
                        else {
                            mapInstance.setPaintProperty(
                                layer.id,
                                prop,
                                layer.paint[prop]
                            )
                        }
                        return null
                    })
                }

                // set filters
                if (layer.filter) {
                    mapInstance.setFilter(layer.id, layer.filter)
                }
                return null
            })
        },
        [mapInstance]
    )

    // the following useEffect uses the imported default mapbox light / dark styles, and handles the transition between them
    useEffect(() => {
        // if (colorMode === 'light') {
        //     handleTransition(lightLayers)
        // }
        // if (colorMode === 'dark') {
        //     handleTransition(darkLayers)
        // }
        handleTransition(lightLayers, basemap)
    }, [/*colorMode, */ basemap, handleTransition])

    return null
}

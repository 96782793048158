import React, { useEffect, useContext, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import {
    Flex,
    Box,
    useDisclosure,
    LightMode,
    Icon,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    useBreakpointValue,
    Text,
} from '@chakra-ui/react'
import { useSwipeable } from 'react-swipeable'

import WebcamsContent from './aerodrome/webcams'
import {
    clickedFeaturesState,
    zoomToActiveState,
    mapZoomLevelState,
    fullscreenModalVisibleState,
    mapDisabledState,
} from '../../../globalState'
import { ReactComponent as webcam } from '../../../icons/assets/camera.svg'

export default function WebcamModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const zoomToActive = useRecoilValue(zoomToActiveState)
    const handleFullscreenModal = useRecoilValue(fullscreenModalVisibleState)
    const mapInstance = useContext(MapContext)
    const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomLevelState)
    const mapDisabled = useRecoilValue(mapDisabledState)

    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)

    const feat = clickedFeatures[0]
    const elevation = feat && feat?.properties?.elevation

    const webcamsData =
        clickedFeatures[0]?.properties?.webcams &&
        JSON.parse(clickedFeatures[0].properties.webcams)

    // Expand drawer wider
    const [fullscreen, setFullscreen] = useState(false)
    useEffect(() => {
        if (!isOpen) {
            setFullscreen(false)
        }
    }, [isOpen])

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
        setZoomLevel(null)
        setMinimiseOnZoom(false)
    }

    // Close if Fullscreen modal opens
    useEffect(() => {
        if (handleFullscreenModal && !mapDisabled) {
            onClose()
            setClickedFeatures([])
            setZoomLevel(null)
        }
    }, [
        handleFullscreenModal,
        mapDisabled,
        onClose,
        setClickedFeatures,
        setZoomLevel,
    ])

    // Zoom-out function when closing Aerodrome Drawer on button only (clicking overlay does not)
    function zoomOutOnClose() {
        if (
            !mapDisabled &&
            zoomToActive &&
            clickedFeatures.length === 1 &&
            clickedFeatures[0].layer === 'webcams'
        ) {
            mapInstance.flyTo({
                center: clickedFeatures[0]?.geometry?.coordinates,
                zoom: zoomLevel,
                curve: 1.2,
                offset: [0, 0],
            })
            setZoomLevel(null)
        }
    }

    const headerSwipe = useSwipeable({
        onSwipedDown: () => {
            onCloseHandler()
            zoomOutOnClose()
        },
    })

    const mapDisabledScreenWidthSmall = mapDisabled && window.innerWidth < 1165

    const closeButtonPositionSmallScreen = useBreakpointValue({
        base: { top: '0px', right: '0px' },
        md: { display: 'none' },
    })
    const closeButtonPositionLargeScreen = useBreakpointValue({
        base: { display: 'none' },
        md: {
            marginRight: '0',
            left: '10px',
            right: 'auto',
        },
    })
    const headerAlign = useBreakpointValue({
        base: 'left',
        md: 'right',
    })
    const overlayBG = useBreakpointValue({
        base: { background: 'blackAlpha.700', backdropFilter: 'blur(2px)' },
        md: {
            background: mapDisabledScreenWidthSmall
                ? 'blackAlpha.700'
                : mapDisabled
                ? 'none'
                : 'none',
            backdropFilter: mapDisabledScreenWidthSmall
                ? 'blur(2px)'
                : mapDisabled
                ? 'none'
                : 'none',
            width: mapDisabled ? '100%' : '0vw',
            pointerEvents: 'none',
        },
        xl: {
            background: 'none',
            backdropFilter: 'none',
            width: mapDisabled ? '100%' : '0vw',
            pointerEvents: 'none',
        },
    })
    const DrawerSize = useBreakpointValue({
        base: { size: 'full' },
        md: { size: fullscreen ? 'full' : 'xl' },
    })
    const DrawerLayout = useBreakpointValue({
        base: 'bottom',
        md: 'right',
    })
    const webcamsModalFullscreen = useBreakpointValue({
        base: false,
        xl: fullscreen,
    })
    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <LightMode>
            <Drawer
                // variant="webcam"
                // size="5xl"
                motionPreset="slideInBottom"
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                variant="webcamDrawer"
                onClose={() => {
                    onCloseHandler()
                    zoomOutOnClose()
                }}
                isOpen={isOpen}
                isCentered
                placement={DrawerLayout}
                onChange={DrawerLayout}
                {...DrawerSize}
                mt="60px"
                // containerStyle={{ width: '0vw' }}
                trapFocus={false}
                background="white"
            >
                <DrawerCloseButton
                    {...closeButtonPositionSmallScreen}
                    color={{ base: 'white', md: '#444444' }}
                    zIndex="1301"
                    opacity="1"
                    fontSize="1.1rem"
                    boxShadow="none !important"
                    {...noFocus}
                    width="48px"
                    height="48px"
                />
                <DrawerOverlay
                    {...overlayBG}
                    onClick={() => {
                        onCloseHandler()
                    }}
                />
                <DrawerContent
                    height={{ base: 'calc(100% - 47px)', md: '100%' }}
                    maxHeight={{ base: 'calc(100% - 47px)', md: '100%' }}
                    borderTopRadius={{ base: '27px', md: '0px' }}
                    boxShadow={{
                        base: '0px -5px 20px -7px rgba(0,0,0,0.4)',
                        md: fullscreen
                            ? 'inset 9px 0px 15px -15px rgba(0,0,0,0.4)'
                            : '-5px 0px 15px -5px rgba(0,0,0,0.2)',
                    }}
                    width={{
                        base: '100%',
                        md: 'calc(100% - 50px)',
                        ml: fullscreen
                            ? 'calc(100% - 50px) !important'
                            : '70vw',
                        xl: fullscreen
                            ? 'calc(100% - 50px) !important'
                            : mapDisabled
                            ? 'calc(100% - 500px) !important'
                            : '60vw',
                    }}
                    maxWidth={{
                        base: '100%',
                        md: 'calc(100% - 50px)',
                        ml: fullscreen
                            ? 'calc(100% - 50px) !important'
                            : '70vw',
                        xl: fullscreen
                            ? 'calc(100% - 50px) !important'
                            : mapDisabled
                            ? 'calc(100% - 500px) !important'
                            : '60vw',
                    }}
                    transition={{
                        base: 'unset',
                        md: 'all ease 200ms',
                    }}
                >
                    <DrawerHeader
                        zIndex="1"
                        {...headerSwipe}
                        backgroundColor={'#576B84'}
                        bgImage={
                            'linear-gradient(140deg, rgb(18 76 95) 0%, rgb(75 147 169) 85%)'
                        }
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        paddingInlineStart={{ base: 4, md: 5 }}
                        paddingInlineEnd={{ base: 4, md: 5 }}
                        minHeight="73px"
                        paddingTop="3"
                        borderTopRadius={{ base: '25px', md: '0px' }}
                        clipPath="inset(0px 0px 0px 0px)"
                    >
                        <DrawerCloseButton
                            {...closeButtonPositionLargeScreen}
                            color="white"
                            opacity="0.8"
                            boxShadow="none !important"
                            _hover={{ opacity: '1' }}
                            {...noFocus}
                            fontSize="1rem"
                            width="40px"
                            height="40px"
                            mt="-4px"
                        />

                        <Flex
                            flexDirection="row"
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Flex
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Icon
                                    pointerEvents="none"
                                    top="16px"
                                    position="absolute"
                                    transform={{
                                        base: 'scale(2) translateX(-2px) translateY(0px)',
                                        md: 'scale(2.1) translateX(-4px) translateY(0px)',
                                    }}
                                    opacity="0.05"
                                    boxSize="42px"
                                    as={webcam}
                                    alt="!"
                                    color="#ffffff"
                                />
                            </Flex>
                            <Flex
                                direction="row"
                                justifyContent={{
                                    base: 'flex-start',
                                    md: 'flex-end',
                                }}
                                alignItems="center"
                                width="100%"
                            >
                                <Icon
                                    display={{
                                        base: 'flex',
                                        md: 'none',
                                    }}
                                    ml="10px"
                                    boxSize="36px"
                                    as={webcam}
                                    alt="!"
                                    color="#ffffff"
                                />
                                <Box pl={4}>
                                    <Text
                                        textAlign={headerAlign}
                                        color="white"
                                        fontSize={{
                                            base:
                                                feat?.properties?.location &&
                                                feat.properties.location
                                                    .length > 22
                                                    ? '0.9rem'
                                                    : feat?.properties
                                                          ?.location &&
                                                      feat.properties.location
                                                          .length > 16
                                                    ? '1rem'
                                                    : '1.2rem',
                                            md: '1.2rem',
                                        }}
                                        fontWeight="bold"
                                        lineHeight={{
                                            base:
                                                feat?.properties?.location &&
                                                feat.properties.location
                                                    .length > 22
                                                    ? '1.8'
                                                    : feat?.properties
                                                          ?.location &&
                                                      feat.properties.location
                                                          .length > 16
                                                    ? '1.6'
                                                    : '1.3',
                                            md: '1.3',
                                        }}
                                    >
                                        {feat?.properties?.location
                                            ? feat.properties.location
                                            : ''}
                                    </Text>
                                    <Text
                                        textAlign={headerAlign}
                                        color="white"
                                        fontSize="0.8rem"
                                        pt="2px"
                                        fontWeight={500}
                                    >
                                        Webcam
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody
                        overflow={'hidden'}
                        pt={{ base: '0', ml: '0.7rem' }}
                        pb={{
                            base: minimiseOnZoom ? '16px' : '20px',
                            ml: '15px',
                        }}
                        px={{
                            base: minimiseOnZoom ? '0px' : '15px',
                            md: '15px',
                        }}
                        transition="all ease 400ms"
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            marginLeft="auto"
                            marginRight="auto"
                            flexDirection="column"
                        >
                            {clickedFeatures[0]?.properties?.webcams && (
                                <WebcamsContent
                                    data={{
                                        webcams: webcamsData,
                                    }}
                                    elevation={elevation}
                                    fullscreen={fullscreen}
                                    setFullscreen={setFullscreen}
                                    minimiseOnZoom={minimiseOnZoom}
                                    setMinimiseOnZoom={setMinimiseOnZoom}
                                    webcamsModalFullscreen={
                                        webcamsModalFullscreen
                                    }
                                />
                            )}
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </LightMode>
    )
}

import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { setModalVisibilityState } from '../../globalState'
import Lottie from 'lottie-react'
import faqLottie from '../assets/faq.json'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Flex,
    Text,
    LightMode,
    useDisclosure,
    Collapse,
    Button,
    useBreakpointValue,
    Link,
    Icon,
    Image,
} from '@chakra-ui/react'
import { isIOS, isAndroid } from 'react-device-detect'
import { ReactComponent as ChromeIcon } from '../assets/chromeIcon.svg'
import { ReactComponent as FirefoxIcon } from '../assets/firefoxIcon.svg'
import { ReactComponent as SafariIcon } from '../assets/safariIcon.svg'
import { ReactComponent as MobileSMSIcon } from '../../icons/assets/mobile-sms.svg'
import { ReactComponent as MobileAuthenticatorIcon } from '../../icons/assets/mobile-authenticator.svg'
import { ReactComponent as EmailIcon } from '../../icons/assets/email.svg'
import { ReactComponent as Bulletpoint } from '../../icons/assets/bulletpoint.svg'
import managesubscription from '../assets/manage-subscription.png'
import passwordreset from '../assets/password-reset.png'
import iOSAddToHomescreen from '../assets/ios-add-to-homescreen.png'
import androidAddToHomescreen from '../assets/android-add-to-homescreen.png'
import savedurls from '../assets/saved-urls.png'
import { RiStarLine } from 'react-icons/ri'

export default function FAQs(props) {
    const { faqsRef } = props
    const { isOpen, onToggle } = useDisclosure()
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowTermsModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'terms', value })
        },
        [setModalVisibility]
    )
    const setShowFAQModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'faqs', value })
        },
        [setModalVisibility]
    )
    const mobileView = window.innerWidth < 1120
    const faqIconAlignment = useBreakpointValue({
        base: '0px',
        md: '0px',
    })

    const showPremiumBadge = useBreakpointValue({
        base: false,
        smd: true,
    })

    return (
        <LightMode>
            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                className="faq-content"
                width="100%"
                fontFamily="'Poppins','Open Sans',sans-serif"
                fontSize="1rem"
            >
                <Flex
                    position={{ base: 'relative', md: 'absolute' }}
                    justifyContent={{ base: 'flex-end', md: 'flex-start' }}
                    opacity="0.04"
                    color="dark.10"
                    top={{
                        base: faqsRef ? '-40px' : '-10px',
                        md: faqsRef ? '27px' : '55px',
                    }}
                    right={{ base: faqsRef ? '0px' : '15px', md: 'unset' }}
                    marginLeft={{ base: 'auto', md: '-30px' }}
                    width={{
                        base: faqsRef ? '200px' : '150px',
                        md: '250px',
                        lg: '310px',
                        xl: '370px',
                    }}
                    height={{ base: '30px', md: '370px' }}
                >
                    <Lottie
                        className="faq-icon"
                        loop={true}
                        autoplay={true}
                        animationData={faqLottie}
                        style={{
                            width: '100%',
                            height: 'auto',
                            minWidth: '100%',
                            minHeight: 'auto',
                            position: 'absolute',
                            top: faqIconAlignment,
                            pointerEvents: 'none',
                            opacity: '1',
                        }}
                    />
                </Flex>
                <Flex
                    width={{ base: '100%', md: '30%' }}
                    px={{ base: '15px', md: '20px' }}
                    pt={{ base: '0px', md: mobileView ? '100px' : '125px' }}
                    pb={{ base: '30px', md: '0px' }}
                    flexDirection="column"
                >
                    <Flex
                        flexDirection="column"
                        width={{
                            base: '100%',
                            md: faqsRef ? '100%' : 'calc(30% - 50px)',
                        }}
                        position={{
                            base: 'relative',
                            md: faqsRef ? 'relative' : 'absolute',
                        }}
                    >
                        <Text
                            className="modeLabel"
                            fontSize={{
                                base: faqsRef ? '1.9rem' : '1.5rem',
                                md: '1.9rem',
                            }}
                            fontWeight="600"
                        >
                            Have a question?
                        </Text>

                        <Text pt="10px" color="gray.500" pl="1px">
                            Here are some common questions about PreFlight
                        </Text>
                    </Flex>
                </Flex>
                <Flex
                    width={{ base: '100%', md: '70%' }}
                    pt={{ base: '10px', md: '40px' }}
                    zIndex="2"
                >
                    <Accordion
                        allowToggle
                        width="100%"
                        pl={{ base: '0px', md: '10px' }}
                        pr={{ base: '0px', md: '0px' }}
                    >
                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        What is PreFlight?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    Aeropath, a subsidiary of Airways, and
                                    MetService have joined forces to develop a
                                    one-stop platform to better support the
                                    needs of pilots throughout New Zealand.
                                </Text>
                                <Text pt="10px">
                                    The PreFlight platform provides a suite of
                                    additional benefits to the aviation sector,
                                    through delivery of both safety critical
                                    weather and aeronautical information
                                    required for commercial and recreational
                                    pilots.
                                </Text>
                                <Text pt="10px">
                                    Information is provided in a modern,
                                    interactive format, combining both weather
                                    and aeronautical data, on desktop or mobile
                                    devices.
                                </Text>
                                <Text pt="10px">
                                    PreFlight is available{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                        }}
                                    >
                                        free for personal use
                                    </span>
                                    , with an option to upgrade to a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: 'rgba(0, 188, 248, 1)',
                                        }}
                                    >
                                        Premium
                                    </span>{' '}
                                    subscription plan for more functionality:
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#007DC8',
                                                }}
                                            >
                                                PLUS
                                            </span>{' '}
                                            plan –{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                individuals only and NOT for
                                                commercial use
                                            </span>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#8D38E3',
                                                }}
                                            >
                                                PRO
                                            </span>{' '}
                                            plan –{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                for commercial use
                                            </span>
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        Who can register for an account?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    PreFlight is available to anyone, though it
                                    is designed for student, private and
                                    commercial pilots in New Zealand.
                                </Text>
                                <Text pt="10px">
                                    Any New Zealand licenced pilot or student
                                    pilot can self-register for a{' '}
                                    <strong>FREE</strong> account on this page,
                                    or upgrade to a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    account from within the PreFlight web-app.
                                </Text>
                                <Text pt="20px">
                                    Organisations with a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    (commercial) account will need to contact us
                                    to register accounts for their pilots or
                                    staff.
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:aviationsales@metservice.com?subject=New PRO Account Enquiry for PreFlight"
                                            >
                                                aviationsales@metservice.com
                                            </Link>
                                            ; or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app.
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem borderTop="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        How do I register for an account?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    For <strong>FREE</strong> and{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    accounts (
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        individuals only and not for commercial
                                        use
                                    </span>
                                    ):
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Click on the{' '}
                                            <strong>REGISTER</strong> button to
                                            create your FREE account, for
                                            personal use.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Select{' '}
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: 'rgba(0, 188, 248, 1)',
                                                }}
                                            >
                                                Upgrade to Premium
                                            </span>{' '}
                                            in your profile, located in the
                                            admin dashboard within the PreFlight
                                            web-app to upgrade to the{' '}
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#007DC8',
                                                }}
                                            >
                                                PLUS
                                            </span>{' '}
                                            account.
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="20px">
                                    For a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    account (
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        for commercial use
                                    </span>
                                    ):
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:aviationsales@metservice.com?subject=New PRO Account Enquiry for PreFlight"
                                            >
                                                aviationsales@metservice.com
                                            </Link>
                                            ; or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app.
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="10px"></Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        Why are there optional paid
                                        subscriptions (Premium plans)?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    PreFlight is available{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                        }}
                                    >
                                        free for personal use
                                    </span>
                                    , with an option to upgrade to a{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: 'rgba(0, 188, 248, 1)',
                                        }}
                                    >
                                        Premium
                                    </span>{' '}
                                    subscription plan for more functionality:
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#007DC8',
                                                }}
                                            >
                                                PLUS
                                            </span>{' '}
                                            plan –{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                individuals only and NOT for
                                                commercial use
                                            </span>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#8D38E3',
                                                }}
                                            >
                                                PRO
                                            </span>{' '}
                                            plan –{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                for commercial use
                                            </span>
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="15px">
                                    Premium subscriptions are integral to the
                                    successful continuation of PreFlight.
                                </Text>
                                <Text pt="10px">
                                    While it would be great to offer PreFlight
                                    as a free, all-inclusive package,
                                    unfortunately the current operating model
                                    for the supply of aviation weather data in
                                    NZ does not facilitate this, as compared to
                                    that in some other countries (where costs
                                    are covered in the flight fees and levies
                                    paid each flight).
                                </Text>
                                <Text pt="10px">
                                    In New Zealand the provision of aviation
                                    services is partially funded by the Ministry
                                    of Transport. For PreFlight, this only
                                    covers those Meteorological products that
                                    are available in the <strong>FREE</strong>{' '}
                                    tier. MetService, as a State Owned
                                    Enterprise, must also operate as a
                                    commercial company.
                                </Text>
                                <Text pt="10px">
                                    No additional funding was provided for the
                                    development of PreFlight, without this
                                    source of revenue, PreFlight would not have
                                    been possible as a replacement to the
                                    recently retired and outdated MetFlight.
                                </Text>
                                <Text pt="10px">
                                    The recurring revenue generated from premium
                                    subscriptions helps cover PreFlight's
                                    initial build costs, on-going maintenance,
                                    support and future enhancements. This is
                                    necessary for the sustainability and
                                    development of the web-app, ensuring it
                                    remains relevant and continues to provide
                                    our users with useful, fit-for-purpose
                                    information for their flights.
                                </Text>
                                <Text pt="10px">
                                    Management of PreFlight's premium services
                                    for individuals, or organisations using it
                                    for commercial purposes, is in line with the
                                    PreFlight{' '}
                                    <Link
                                        color="light.200"
                                        fontWeight="500"
                                        onClick={() => {
                                            setShowTermsModal(true)
                                            setShowFAQModal(false)
                                        }}
                                    >
                                        Terms and Conditions
                                    </Link>
                                    .
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        What do I get with a paid subscription?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    In addition to the <strong>FREE</strong>{' '}
                                    plan, PreFlight offers users paid
                                    subscription options that provide additional
                                    benefits to what is offered for free.
                                </Text>
                                <Text pt="10px">
                                    A paid subscription comprises of a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    plan for individuals and{' '}
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        NOT FOR COMMERCIAL USE
                                    </span>
                                    , and a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    plan for organisations who will use it{' '}
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        FOR COMMERCIAL USE
                                    </span>
                                    .
                                </Text>
                                <Text pt="10px">
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    plan users get all <strong>FREE</strong>{' '}
                                    plan features, and...
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            SFC – FL600.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Visual Navigation Chart and LINZ
                                            Topo basemaps.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Ruler.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Convert UTC to NZST/NZDT time.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Advanced aerodrome data (incl.
                                            decoded TAF and METAR, Webcams, and
                                            Charts).
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            SIGMET and SIGWX on the map.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Advanced Cloud imagery / forecast.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Advanced Rain radar / forecast.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Additional MSL Analysis Charts.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Automatic Weather Stations.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Advisory Mode - the best pre-flight
                                            situational awareness tool available
                                            for NZ airspace.
                                        </li>
                                        <ul>
                                            <li
                                                style={{
                                                    marginLeft: '20px',
                                                    paddingLeft: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                view En-route NOTAMs graphically
                                                on the map.
                                            </li>
                                            <li
                                                style={{
                                                    marginLeft: '20px',
                                                    paddingLeft: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                view SIGMET and SIGWX alongside
                                                other dynamic information.
                                            </li>
                                        </ul>
                                    </ul>
                                </Box>
                                <Text pt="20px">
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    plan users get all{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    plan features for{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                        }}
                                    >
                                        COMMERCIAL use
                                    </span>
                                    , and...
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Multiple users.
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        How long does a paid subscription last
                                        for?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                pl="10px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Box marginLeft="35px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#007DC8',
                                                }}
                                            >
                                                PLUS
                                            </span>{' '}
                                            plans
                                        </li>
                                    </ul>
                                    <Text pl="5px">
                                        With our{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                                color: '#007DC8',
                                            }}
                                        >
                                            PLUS
                                        </span>{' '}
                                        plan subscription, you enjoy the
                                        convenience of monthly billing. Pay as
                                        you go each month and have the
                                        flexibility to cancel anytime.
                                    </Text>
                                    <Text pt="10px" pl="5px">
                                        Your subscription operates on an{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            automatic monthly billing cycle{' '}
                                        </span>{' '}
                                        starting from the date you sign up. For
                                        example, if you sign up on September
                                        14th, your billing cycle will run until
                                        October 14th, with automatic renewal on
                                        this date for the next month unless
                                        cancelled beforehand.
                                    </Text>
                                    <Text pt="10px" pl="5px">
                                        If you decide to cancel your
                                        subscription part way through a
                                        subscription month, you'll maintain
                                        access to PreFlight's premium services
                                        until the end of your current month
                                        billing cycle. Please note that refunds
                                        are not provided for the remaining
                                        portion of the cancelled month.
                                    </Text>
                                    <ul>
                                        <li
                                            style={{
                                                paddingTop: '25px',
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#8D38E3',
                                                }}
                                            >
                                                PRO
                                            </span>{' '}
                                            plans
                                        </li>
                                    </ul>
                                    <Text pl="5px">
                                        A paid subscription through a{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                                color: '#8D38E3',
                                            }}
                                        >
                                            PRO
                                        </span>{' '}
                                        plan is negotiated with MetService for
                                        what works best for your organisation.
                                        This may be on a monthly or annual
                                        basis, and can also be cancelled
                                        anytime.
                                    </Text>
                                </Box>
                                <Text pl="20px" pt="25px">
                                    Please note that refunds are not provided
                                    for the remaining portion of the cancelled
                                    period should you choose to cancel your
                                    premium subscription before the end of your
                                    current billing cycle.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        How do I upgrade to a paid subscription?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    For a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    account (
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        individuals only and NOT for commercial
                                        use
                                    </span>
                                    ):
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Log in to PreFlight at
                                            gopreflight.co.nz
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            From the admin dashboard, select
                                            your profile
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Click{' '}
                                            <span
                                                style={{
                                                    fontWeight: '700',
                                                    color: 'rgba(0, 188, 248, 1)',
                                                }}
                                            >
                                                Upgrade to Premium
                                            </span>{' '}
                                            – this will direct you to an
                                            external payment gateway (Stripe) to
                                            enter payment details
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Once payment has been processed your
                                            account will be updated
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            You may need to log in again
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            You will now have access to
                                            additional features
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="20px">
                                    For a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    account (
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        for commercial use
                                    </span>
                                    ):
                                </Text>
                                <Text pt="10px" marginLeft="12px">
                                    Organisations must ensure that each employee
                                    maintains an individual account.
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:aviationsales@metservice.com?subject=New PRO Account Enquiry for PreFlight"
                                            >
                                                aviationsales@metservice.com
                                            </Link>
                                            ; or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app.
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        How do I cancel my paid subscription?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    For a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    account (
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        individuals only and NOT for commercial
                                        use
                                    </span>
                                    ), you can easily cancel your subscription
                                    within PreFlight:
                                </Text>
                                <Box marginLeft="25px" pt="10px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Log in to PreFlight at
                                            gopreflight.co.nz
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            From the{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                admin dashboard
                                            </span>
                                            , select your{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                profile
                                            </span>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Select the{' '}
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                }}
                                            >
                                                Profile
                                            </span>{' '}
                                            tab
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Click{' '}
                                            <strong>Manage Subscription</strong>{' '}
                                            – this will take you to an external
                                            payment gateway (Stripe) where you
                                            can select to cancel your
                                            subscription plan
                                        </li>
                                    </ul>
                                </Box>
                                <Flex
                                    pt="20px"
                                    pb="25px"
                                    px="12px"
                                    width="100%"
                                    justifyContent="center"
                                >
                                    <Image
                                        alt={'Manage Subscription'}
                                        // fit={'cover'}
                                        borderRadius="15px"
                                        align={'center'}
                                        maxWidth={'100%'}
                                        h={'auto'}
                                        src={managesubscription}
                                        boxShadow={{
                                            base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                            md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                        }}
                                    />
                                </Flex>
                                <Text pl="10px" pt="10px">
                                    Cancelling your{' '}
                                    <span
                                        style={{
                                            fontWeight: '500',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    subscription plan will revert your account
                                    to the{' '}
                                    <span
                                        style={{
                                            fontWeight: '500',
                                        }}
                                    >
                                        FREE
                                    </span>{' '}
                                    version at the end of the current
                                    subscription month you've already paid for.
                                    This allows continued access to most of
                                    PreFlight's services for free thereafter.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    You can easily upgrade back to a{' '}
                                    <span
                                        style={{
                                            fontWeight: '500',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    plan again at any time on your account if
                                    you wish.
                                </Text>
                                <Text pt="40px">
                                    For a{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    account (
                                    <span
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        for commercial use
                                    </span>
                                    ), please get in touch with the MetService
                                    Aviation Sales team:
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:aviationsales@metservice.com?subject=Cancellation of Existing PRO Account in PreFlight"
                                            >
                                                aviationsales@metservice.com
                                            </Link>
                                            ; or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app.
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="30px">
                                    Please note that refunds are not provided
                                    for the remaining portion of the cancelled
                                    period should you choose to cancel your
                                    premium subscription before the end of your
                                    current billing cycle.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        What is multi-factor authentication
                                        (MFA)?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Flex
                                    gap="5px"
                                    pt="15px"
                                    pb="20px"
                                    pl={{
                                        base: '15px',
                                        md: '20px',
                                    }}
                                    pr={{
                                        base: '15px',
                                        smd: '5px',
                                        md: '20px',
                                    }}
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        flexWrap="wrap"
                                        fontSize="0.9rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Text pb="0px" pr="5px">
                                            MFA is a requirement for –
                                        </Text>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Flex
                                                display={
                                                    showPremiumBadge
                                                        ? 'flex'
                                                        : 'none'
                                                }
                                                gap="5px"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Text>Access to</Text>
                                                <Flex
                                                    height="20px"
                                                    bg="rgba(0, 188, 248, 1)"
                                                    color="#FFFFFF"
                                                    fontFamily="'Open Sans', sans-serif"
                                                    pl="4px"
                                                    pr="6px"
                                                    borderRadius="20px"
                                                    fontSize="0.75rem"
                                                    fontWeight="600"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    gap="4px"
                                                    width="90px"
                                                >
                                                    <RiStarLine
                                                        color="#FFFFFF"
                                                        fontSize="0.9rem"
                                                    />
                                                    PREMIUM
                                                </Flex>
                                            </Flex>
                                            <Text textAlign="left">
                                                {showPremiumBadge
                                                    ? ''
                                                    : 'Access to PREMIUM'}{' '}
                                                features on{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#007DC8',
                                                    }}
                                                >
                                                    PLUS
                                                </span>{' '}
                                                and{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#8D38E3',
                                                    }}
                                                >
                                                    PRO
                                                </span>{' '}
                                                subscription plans.
                                            </Text>
                                        </Flex>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Text textAlign="left">
                                                Managing account details and
                                                password changes (all users).
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Text pt="30px">
                                    Multi-factor Authentication (MFA), also
                                    known as 'two-step verification', is an
                                    enhanced security method that requires users
                                    to provide two or more verification factors
                                    to access their accounts.
                                </Text>
                                <Text pt="10px">
                                    Instead of solely relying on a username and
                                    password, MFA adds an extra layer of
                                    protection, significantly reducing the risk
                                    of unauthorised access to your account.
                                </Text>
                                <Text pt="10px">
                                    We provide Multi-factor Authentication
                                    through a 6-digit verification code obtained
                                    through one of the three verification
                                    options below.
                                </Text>
                                <Flex
                                    pt="20px"
                                    pb="10px"
                                    width={{ base: '100%', sm: '100%' }}
                                    flexDirection={{
                                        base: 'column',
                                        smd: 'row',
                                    }}
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                    px={{ base: '0px', smd: '20px' }}
                                    gap="30px"
                                >
                                    <Flex
                                        width={{ base: '100%', smd: '30%' }}
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                        fontSize="0.8rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="50px"
                                        >
                                            <Icon
                                                as={MobileSMSIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Text
                                            width="100%"
                                            textAlign="center"
                                            borderBottom="1px solid #e2e5e9"
                                            pb="5px"
                                            mb="10px"
                                            color="light.200"
                                            fontWeight="500"
                                            fontSize="0.9rem"
                                        >
                                            SMS
                                        </Text>
                                        <Text
                                            width="100%"
                                            px={{
                                                base: '15px',
                                                smd: '0px',
                                            }}
                                            textAlign="center"
                                            color="gray.500"
                                            fontWeight="300"
                                        >
                                            Get a text message with verification
                                            code sent to your mobile
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width={{ base: '100%', smd: '30%' }}
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                        fontSize="0.8rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="50px"
                                        >
                                            <Icon
                                                as={MobileAuthenticatorIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Text
                                            width="100%"
                                            textAlign="center"
                                            borderBottom="1px solid #e2e5e9"
                                            pb="5px"
                                            mb="10px"
                                            fontWeight="500"
                                            color="light.200"
                                            fontSize="0.9rem"
                                        >
                                            Authenticator
                                        </Text>
                                        <Text
                                            width="100%"
                                            px={{
                                                base: '15px',
                                                smd: '0px',
                                            }}
                                            textAlign="center"
                                            color="gray.500"
                                            fontWeight="300"
                                        >
                                            Get a time-based verification code
                                            from an Authenticator app, such as{' '}
                                            <Link
                                                color="light.200"
                                                fontWeight="500"
                                                href={
                                                    isIOS
                                                        ? 'https://apps.apple.com/us/app/google-authenticator/id388497605'
                                                        : isAndroid
                                                        ? 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                                                        : 'https://www.google.com/search?q=google+authenticator'
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Google Authenticator
                                            </Link>{' '}
                                            or{' '}
                                            <Link
                                                color="light.200"
                                                fontWeight="500"
                                                href={
                                                    'https://www.microsoft.com/en-nz/security/mobile-authenticator-app'
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Microsoft Authenticator
                                            </Link>
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width={{ base: '100%', smd: '30%' }}
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                        fontSize="0.8rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight="50px"
                                        >
                                            <Icon
                                                as={EmailIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Text
                                            width="100%"
                                            textAlign="center"
                                            borderBottom="1px solid #e2e5e9"
                                            pb="5px"
                                            mb="10px"
                                            fontWeight="500"
                                            color="light.200"
                                            fontSize="0.9rem"
                                        >
                                            Email
                                        </Text>
                                        <Text
                                            width="100%"
                                            px={{
                                                base: '15px',
                                                smd: '0px',
                                            }}
                                            textAlign="center"
                                            color="gray.500"
                                            fontWeight="300"
                                        >
                                            Get a verification code sent to your
                                            email address
                                        </Text>
                                    </Flex>
                                </Flex>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        Why do we need multi-factor
                                        authentication?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Flex
                                    gap="5px"
                                    pt="15px"
                                    pb="20px"
                                    pl={{
                                        base: '15px',
                                        md: '20px',
                                    }}
                                    pr={{
                                        base: '15px',
                                        smd: '5px',
                                        md: '20px',
                                    }}
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        flexWrap="wrap"
                                        fontSize="0.9rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Text pb="0px" pr="5px">
                                            MFA is a requirement for –
                                        </Text>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Flex
                                                display={
                                                    showPremiumBadge
                                                        ? 'flex'
                                                        : 'none'
                                                }
                                                gap="5px"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Text>Access to</Text>
                                                <Flex
                                                    height="20px"
                                                    bg="rgba(0, 188, 248, 1)"
                                                    color="#FFFFFF"
                                                    fontFamily="'Open Sans', sans-serif"
                                                    pl="4px"
                                                    pr="6px"
                                                    borderRadius="20px"
                                                    fontSize="0.75rem"
                                                    fontWeight="600"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    gap="4px"
                                                    width="90px"
                                                >
                                                    <RiStarLine
                                                        color="#FFFFFF"
                                                        fontSize="0.9rem"
                                                    />
                                                    PREMIUM
                                                </Flex>
                                            </Flex>
                                            <Text textAlign="left">
                                                {showPremiumBadge
                                                    ? ''
                                                    : 'Access to PREMIUM'}{' '}
                                                features on{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#007DC8',
                                                    }}
                                                >
                                                    PLUS
                                                </span>{' '}
                                                and{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#8D38E3',
                                                    }}
                                                >
                                                    PRO
                                                </span>{' '}
                                                subscription plans.
                                            </Text>
                                        </Flex>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Text textAlign="left">
                                                Managing account details and
                                                password changes (all users).
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Text pt="30px">
                                    MFA is an important part of the security and
                                    continuity of premium services.
                                </Text>
                                <Text pt="10px">
                                    PreFlight offers a Premium subscription plan
                                    to provide additional features and
                                    functionality beyond the free services. MFA
                                    is an important and necessary requirement
                                    for users with a Premium plan primarily for
                                    the following reasons –
                                </Text>
                                <Text
                                    pl="10px"
                                    pt="15px"
                                    color="gray.500"
                                    fontSize="0.9rem"
                                    fontWeight="600"
                                >
                                    Security
                                </Text>
                                <Text pl="25px" pt="5px">
                                    The security of our user's accounts is
                                    paramount. By integrating MFA, we ensure an
                                    additional level of security to safeguard
                                    against unauthorised access. This proactive
                                    approach significantly reduces the risk of
                                    data breaches and ensures the
                                    confidentiality of sensitive information.
                                </Text>
                                <Text pl="25px" pt="10px">
                                    MFA has become a standard practice across
                                    various sectors, including government
                                    agencies, in response to evolving
                                    cybersecurity threats. Its integration
                                    aligns with prevailing security standards,
                                    underscoring our commitment to adhering to
                                    best practices in safeguarding user data.
                                    Furthermore, to minimise privacy risks, we
                                    collect only essential personal information.
                                </Text>
                                <Text
                                    pl="10px"
                                    pt="20px"
                                    color="gray.500"
                                    fontSize="0.9rem"
                                    fontWeight="600"
                                >
                                    Managing Premium Services
                                </Text>
                                <Text pl="25px" pt="5px">
                                    In tandem with security, MFA plays a pivotal
                                    role in the management of premium services
                                    offered by PreFlight. Our Premium
                                    subscription plan unlocks additional
                                    features and functionality beyond the free
                                    services. MFA also helps to address a known
                                    issue with non-paying users gaining access
                                    to these premium services, particularly in
                                    shared device environments. This assures
                                    that only eligible subscribers enjoy the
                                    benefits of our premium features, thereby
                                    ensuring the long-term sustainability of
                                    PreFlight's operating model.
                                </Text>
                                <Text pt="20px">
                                    We understand that MFA may be inconvenient,
                                    particularly for users who like to update
                                    information while flying. Although we can
                                    see the benefits of having updated
                                    information swiftly available particularly
                                    on longer journeys, PreFlight is
                                    specifically designed for pre-flight
                                    planning, and is not intended nor endorsed
                                    for in-flight use. Updating your information
                                    before departure ensures you have the most
                                    relevant data and insights when it matters
                                    most. The emphasis on pre-flight planning
                                    helps pilots make informed decisions for
                                    safer flights.
                                </Text>
                                <Text pt="10px">
                                    PreFlight will still provide all the{' '}
                                    <strong>FREE</strong> tier services when a
                                    user's MFA has expired and is yet to be
                                    reset. Upon resetting your MFA, premium
                                    services will be reactivated.
                                </Text>
                                <Text pt="10px">
                                    All safety-related information required for
                                    pre-flight planning, as previously provided
                                    through MetFlight, is available in the
                                    standard format for all users, regardless of
                                    their subscription plan –{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#718096',
                                        }}
                                    >
                                        FREE
                                    </span>
                                    ,{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    or{' '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>
                                    . Subscribers to a Premium plan get this
                                    information presented in an enhanced format
                                    along with additional user-friendly benefits
                                    and features.
                                </Text>
                                <Text pt="10px">
                                    As a compromise between convenience and
                                    security, MFA is only required periodically,
                                    not with every login. For security, this
                                    excludes management of a user's premium
                                    subscription plan or profile settings, which
                                    is required any time these are updated.
                                </Text>
                                <Text pt="10px">
                                    Management of PreFlight's premium services
                                    for individuals, or organisations using it
                                    for commercial purposes, is in line with the
                                    PreFlight{' '}
                                    <Link
                                        color="light.200"
                                        fontWeight="500"
                                        onClick={() => {
                                            setShowTermsModal(true)
                                            setShowFAQModal(false)
                                        }}
                                    >
                                        Terms and Conditions
                                    </Link>
                                    .
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        When will I be required to use
                                        multi-factor authentication?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Flex
                                    gap="5px"
                                    pt="15px"
                                    pb="20px"
                                    pl={{
                                        base: '15px',
                                        md: '20px',
                                    }}
                                    pr={{
                                        base: '15px',
                                        smd: '5px',
                                        md: '20px',
                                    }}
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        flexWrap="wrap"
                                        fontSize="0.9rem"
                                        color="gray.500"
                                        flexDirection="column"
                                    >
                                        <Text pb="0px" pr="5px">
                                            MFA is a requirement for –
                                        </Text>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Flex
                                                display={
                                                    showPremiumBadge
                                                        ? 'flex'
                                                        : 'none'
                                                }
                                                gap="5px"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Text>Access to</Text>
                                                <Flex
                                                    height="20px"
                                                    bg="rgba(0, 188, 248, 1)"
                                                    color="#FFFFFF"
                                                    fontFamily="'Open Sans', sans-serif"
                                                    pl="4px"
                                                    pr="6px"
                                                    borderRadius="20px"
                                                    fontSize="0.75rem"
                                                    fontWeight="600"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    gap="4px"
                                                    width="90px"
                                                >
                                                    <RiStarLine
                                                        color="#FFFFFF"
                                                        fontSize="0.9rem"
                                                    />
                                                    PREMIUM
                                                </Flex>
                                            </Flex>
                                            <Text textAlign="left">
                                                {showPremiumBadge
                                                    ? ''
                                                    : 'Access to PREMIUM'}{' '}
                                                features on{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#007DC8',
                                                    }}
                                                >
                                                    PLUS
                                                </span>{' '}
                                                and{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: '#8D38E3',
                                                    }}
                                                >
                                                    PRO
                                                </span>{' '}
                                                subscription plans.
                                            </Text>
                                        </Flex>
                                        <Flex
                                            pt="10px"
                                            marginLeft={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '15px',
                                            }}
                                            gap="5px"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            fontSize={{
                                                base: '0.8rem',
                                                md: '0.85rem',
                                            }}
                                        >
                                            <Icon
                                                mt="3px"
                                                mr={{
                                                    base: '5px',
                                                    smd: '0px',
                                                    md: '5px',
                                                }}
                                                color="light.200"
                                                as={Bulletpoint}
                                                width="0.9rem"
                                                height="0.9rem"
                                            />
                                            <Text textAlign="left">
                                                Managing account details and
                                                password changes (all users).
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>

                                <Text pt="30px">
                                    MFA will be prompted for the following –
                                </Text>
                                <Box pl="25px" pt="10px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Making changes to your account's
                                            personal details.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Changing your password.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Access to premium features on{' '}
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    color: '#007DC8',
                                                }}
                                            >
                                                PLUS
                                            </span>{' '}
                                            or{' '}
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    color: '#8D38E3',
                                                }}
                                            >
                                                PRO
                                            </span>{' '}
                                            subscription plans.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Managing a premium subscription
                                            plan.
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Accessing premium subscription
                                            payments history (applicable for
                                            current and previous premium plans).
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="15px">
                                    For{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                        }}
                                    >
                                        premium
                                    </span>{' '}
                                    subscription users only –
                                </Text>
                                <Text pl="28px" pt="10px">
                                    You will be required to enable MFA during
                                    the initial upgrade to a{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#007DC8',
                                        }}
                                    >
                                        PLUS
                                    </span>{' '}
                                    or{' '}
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            color: '#8D38E3',
                                        }}
                                    >
                                        PRO
                                    </span>{' '}
                                    subscription plan. The MFA remains valid for{' '}
                                    <strong>14 days</strong> until it requires
                                    resetting. After each 14-day period, a new
                                    code will be needed upon your next login.
                                </Text>
                                <Text pl="28px" pt="10px">
                                    Within three days before your MFA expires, a
                                    notification will appear in the admin
                                    dashboard while you are logged into the
                                    web-app. This prompt allows you to
                                    conveniently reset your MFA, ensuring
                                    continuous access to premium services
                                    without potential disruptions or the need to
                                    reset at an inconvenient time.
                                </Text>
                                <Text pl="28px" pt="10px">
                                    Please note that changes to your premium
                                    subscription plan, profile details, or
                                    password, will necessitate resetting MFA
                                    when initiated, irrespective of the 14-day
                                    period's validity.
                                </Text>
                                <Text pl="28px" pt="10px">
                                    PreFlight will still provide all the{' '}
                                    <strong>FREE</strong> tier services when a
                                    user's MFA has expired and is yet to be
                                    reset. Upon resetting your MFA, premium
                                    services will be reactivated.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        What devices work best with this web
                                        application?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    <strong>Device Requirements:</strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    This web application has been designed to
                                    make it easier to access and interpret
                                    pre-flight information. The key to this is
                                    making it more visual and therefore the
                                    dynamic map is a core part of the
                                    application.
                                </Text>
                                <Text pt="10px" pl="10px">
                                    However, because of the amount of
                                    information processed and presented, this
                                    does come at a small cost in that it
                                    requires newer devices and computers to run
                                    smoothly.
                                </Text>
                                <Text pt="20px">
                                    <strong>Recommended Browsers:</strong>
                                </Text>

                                <Flex
                                    pt="10px"
                                    width={{ base: '100%', sm: 'max-content' }}
                                    flexDirection="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    px="9px"
                                >
                                    <Flex
                                        width={{
                                            base: '100%',
                                            sm: 'max-content',
                                        }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                as={ChromeIcon}
                                                boxSize="10"
                                                mx="10px"
                                                mt="1px"
                                                pl="1px"
                                            />
                                        </Flex>
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                            mt="-2px"
                                        >
                                            <Icon
                                                as={FirefoxIcon}
                                                boxSize="10"
                                                mx="10px"
                                            />
                                        </Flex>
                                        <Flex
                                            width="80px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                as={SafariIcon}
                                                boxSize="10"
                                                mx="10px"
                                            />
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        fontSize="0.7rem"
                                        borderBottom="1px solid #e2e5e9"
                                        pt="5px"
                                        pb="5px"
                                        color="gray.400"
                                        fontWeight="300"
                                    >
                                        <Text width="80px" textAlign="center">
                                            Chrome
                                        </Text>
                                        <Text width="80px" textAlign="center">
                                            Firefox
                                        </Text>
                                        <Text width="80px" textAlign="center">
                                            Safari
                                        </Text>
                                    </Flex>
                                    <Flex
                                        color="gray.500"
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        pt="5px"
                                        width={{ base: '100%', sm: 'auto' }}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            104+
                                        </Text>
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            91+
                                        </Text>
                                        <Text
                                            pl="2px"
                                            width="80px"
                                            textAlign="center"
                                        >
                                            15+
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Text pl="10px" pt="10px">
                                    We recommend the current version of Chrome,
                                    Firefox or Safari.{' '}
                                </Text>
                                <Text pt="20px">
                                    <strong>Recommended Devices:</strong>
                                </Text>
                                <Text pl="10px" pt="10px">
                                    Preflight will run on Apple, Android and
                                    Windows devices, however we recommend that
                                    you use smart phones, tablets or computers
                                    that are approximately{' '}
                                    <strong>less than 4 years old</strong>{' '}
                                    <em>
                                        (this is subject to the graphics
                                        hardware of your particular make and
                                        model)
                                    </em>
                                    .
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If you are using a computer with a dedicated
                                    graphics processor (GPU), you will get
                                    better performance if you ensure this is
                                    used for the web browser running this web
                                    application.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    If applicable, your web browser may support
                                    Hardware Acceleration. This may also improve
                                    performance if this is turned ON in your
                                    browser settings.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Older devices may still run this web
                                    application, but you are more likely to
                                    experience longer loading times with older
                                    devices and those with limited graphics
                                    performance.
                                </Text>
                                <Text pl="10px" pt="10px">
                                    If using an older device we recommend you
                                    access the briefing function or Met
                                    functions instead of doing a lot of
                                    navigating around the map as these functions
                                    won't require as much performance from your
                                    device.
                                </Text>

                                <Text pl="10px" pt="10px">
                                    Alternatively, you can use{' '}
                                    <Link
                                        fontWeight="600"
                                        color="light.200"
                                        href="https://ifis.airways.co.nz/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        IFIS
                                    </Link>{' '}
                                    to access the traditional briefing
                                    information.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        How can I improve performance issues on
                                        my mobile device (i.e. iPhone)?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    As mentioned in the previous FAQ, older
                                    devices or those that have very limited
                                    graphics hardware can potentially have
                                    difficulty running PreFlight smoothly. This
                                    is more common on older mobile devices, in
                                    particular older generation iPads, Android
                                    tablets, iPhones, and cheaper branded mobile
                                    phones.
                                </Text>
                                <Text pt="10px">
                                    Later generation models of these devices
                                    (i.e. typically less than 4 years old)
                                    generally do not experience performance
                                    issues that we are aware of.
                                </Text>

                                <Text pt="10px">
                                    Most performance and technical issues we
                                    have seen that users are experiencing on
                                    their devices have been resolved by{' '}
                                    <strong>
                                        updating the system software to the
                                        latest version
                                    </strong>{' '}
                                    in the device's settings.
                                </Text>
                                <Text pt="10px">
                                    However, there are some additional actions
                                    you can take to help improve the device's
                                    performance:
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Closing PreFlight and all other open
                                            apps. Sometimes certain native apps
                                            still running in the background can
                                            influence your device’s performance;
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Clearing your browser cache from
                                            within the device’s settings (some
                                            devices do this through an
                                            Optimization method);
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            If necessary, carry out a forced
                                            restart of the mobile device to
                                            clear out any memory blockages.
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="10px">
                                    You shouldn’t need to do these actions all
                                    the time, it is just to see if anything else
                                    is impacting on your device’s performance.
                                </Text>
                                <Text pt="10px">
                                    Restart PreFlight and see if it is now
                                    running more smoothly.
                                </Text>
                                <Text pt="25px">
                                    The following are some useful links that
                                    provide further information (if applicable):
                                </Text>

                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://support.apple.com/en-nz/guide/iphone/iph8903c3ee6/ios"
                                            >
                                                How to force restart an iPhone
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://support.apple.com/en-nz/guide/ipad/ipad9955c007/ipados"
                                            >
                                                How to force restart an iPad
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.samsung.com/nz/support/mobile-devices/how-to-reset-the-device/"
                                            >
                                                How to soft reset a Samsung
                                                device
                                            </Link>
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            <Link
                                                fontWeight="400"
                                                color="light.200"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.samsung.com/nz/support/mobile-devices/how-to-reboot-a-frozen-galaxy-device/"
                                            >
                                                How to force reboot a Samsung
                                                Galaxy device
                                            </Link>
                                        </li>
                                    </ul>
                                </Box>
                                <Text pt="20px">
                                    If you are still experiencing issues with
                                    your mobile device, get in touch with us and
                                    we will be happy to assist you further.
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app;
                                            or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:helpdesk@airwaysinternational.co.nz?subject=Device Performance Issues with PreFlight"
                                            >
                                                helpdesk@airwaysinternational.co.nz
                                            </Link>
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        Why am I receiving emails about
                                        suspicious activity on my account?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text>
                                    <strong>Auth0</strong> is a key security
                                    component of PreFlight, responsible for
                                    managing user authentication and
                                    safeguarding personal information and
                                    subscription access across different user
                                    types. It enhances security by detecting and
                                    preventing any potential malicious attacks,
                                    ensuring the integrity of our data.
                                </Text>
                                <Text pt="10px">
                                    Auth0 supports the principle of layered
                                    protection in security that uses a variety
                                    of signals to detect and mitigate attacks.
                                    Examples of suspicious activities can
                                    include:
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Too many failed login attempts
                                            (using incorrect credentials) within
                                            a period of time;
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Access attempts from multiple
                                            foreign IP addresses, particularly
                                            those outside New Zealand.
                                        </li>
                                    </ul>
                                </Box>

                                <Text pt="20px" fontWeight="600">
                                    Suspicious Activity Alerts
                                </Text>
                                <Flex
                                    mt="10px"
                                    ml="15px"
                                    py="10px"
                                    px="15px"
                                    width="calc(100% - 15px)"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                    fontWeight="300"
                                    fontStyle="italic"
                                    color="#718096"
                                    textAlign="center"
                                >
                                    Detected suspicious activity in your
                                    PreFlight account
                                </Flex>
                                <Text pt="10px" pl="15px">
                                    When Auth0 detects unusual behavior or
                                    potential attacks on a user’s account, it
                                    will automatically send the user an email
                                    alert. Users will be notified by email once
                                    per hour regardless of the number of
                                    suspicious logins detected.
                                </Text>
                                <Text pt="20px" fontWeight="600">
                                    Brute-Force Protection
                                </Text>
                                <Text pt="10px" pl="15px">
                                    If multiple failed login attempts are made
                                    from the same IP address using the same user
                                    account, Auth0 activates brute-force
                                    protection. This security measure
                                    temporarily blocks further login attempts to
                                    protect the account.
                                </Text>

                                <Text pt="20px" fontWeight="600">
                                    Possible Causes of Mistaken Alerts
                                </Text>
                                <Text pt="10px" pl="15px">
                                    While mistaken alerts are rare, they can
                                    occur under specific circumstances.
                                </Text>
                                <Text pt="10px" pl="15px">
                                    For example, if a user changes their
                                    PreFlight password but does not update it in{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        EFB's
                                    </span>{' '}
                                    or other{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        third-party applications
                                    </span>{' '}
                                    that store login credentials and provide the
                                    ability to automatically log into PreFlight
                                    on a user’s behalf (e.g.{' '}
                                    <span style={{ fontWeight: '500' }}>
                                        AvPlan
                                    </span>{' '}
                                    or{' '}
                                    <span style={{ fontWeight: '500' }}>
                                        OzRunways
                                    </span>
                                    ), those applications may continue using
                                    outdated credentials.
                                </Text>
                                <Text pt="10px" pl="15px">
                                    This could lead to repeated failed login
                                    attempts, which Auth0 might mistakenly
                                    interpret as suspicious activity, triggering
                                    email alerts and potentially activating
                                    brute-force protection on the user’s
                                    PreFlight account.
                                </Text>
                                <Text pt="20px" fontWeight="600">
                                    Important Reminder
                                </Text>
                                <Text pt="10px" pl="15px">
                                    If you've recently changed your PreFlight
                                    password, please ensure this is updated in
                                    any third-party applications where it is
                                    stored. Failing to do so may cause repeated
                                    failed login attempts, resulting in
                                    additional suspicious activity alerts and
                                    potential account blocking.
                                </Text>
                                <Text pt="20px" fontWeight="600">
                                    Account Access Issues
                                </Text>
                                <Text pt="10px" pl="15px">
                                    If your account is blocked, you continue to
                                    receive suspicious activity emails, or
                                    encounter other related issues, please
                                    contact us for assistance. We can remove the
                                    security block and help resolve any
                                    problems.
                                </Text>
                                <Box pt="10px" marginLeft="35px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request by clicking{' '}
                                            <strong>Contact Us</strong> on the
                                            PreFlight homepage; or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app
                                            (if able to login); or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:helpdesk@airwaysinternational.co.nz?subject=Device Performance Issues with PreFlight"
                                            >
                                                helpdesk@airwaysinternational.co.nz
                                            </Link>
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <h2>
                                <AccordionButton
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    fontWeight="500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                    py="3"
                                    _expanded={{
                                        bg: 'light.20',
                                        color: 'light.200',
                                        boxShadow:
                                            '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                    }}
                                    _hover={{ background: 'light.30' }}
                                >
                                    <Box flex="1" textAlign="left">
                                        Why is my account blocked?
                                    </Box>
                                    <AccordionIcon fontSize="1.3rem" />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pt="20px"
                                pb={6}
                                px="30px"
                                color="gray.500"
                                fontSize={{
                                    base: '0.8rem',
                                    md: '0.9rem',
                                }}
                            >
                                <Text
                                    fontStyle="italic"
                                    fontWeight="300"
                                    width="100%"
                                    textAlign="center"
                                    color="gray.400"
                                    pb="5px"
                                >
                                    The answer below repeats from the previous
                                    question
                                </Text>
                                <Text pt="10px">
                                    <strong>Auth0</strong> is a key security
                                    component of PreFlight, responsible for
                                    managing user authentication and
                                    safeguarding personal information and
                                    subscription access across different user
                                    types. It enhances security by detecting and
                                    preventing any potential malicious attacks,
                                    ensuring the integrity of our data.
                                </Text>
                                <Text pt="10px">
                                    Auth0 supports the principle of layered
                                    protection in security that uses a variety
                                    of signals to detect and mitigate attacks.
                                    Examples of suspicious activities can
                                    include:
                                </Text>
                                <Box pt="10px" marginLeft="25px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Too many failed login attempts
                                            (using incorrect credentials) within
                                            a period of time;
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Access attempts from multiple
                                            foreign IP addresses, particularly
                                            those outside New Zealand.
                                        </li>
                                    </ul>
                                </Box>

                                <Text pt="20px" fontWeight="600">
                                    Suspicious Activity Alerts
                                </Text>
                                <Flex
                                    mt="10px"
                                    ml="15px"
                                    py="10px"
                                    px="15px"
                                    width="calc(100% - 15px)"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                    fontWeight="300"
                                    fontStyle="italic"
                                    color="#718096"
                                    textAlign="center"
                                >
                                    Detected suspicious activity in your
                                    PreFlight account
                                </Flex>
                                <Text pt="10px" pl="15px">
                                    When Auth0 detects unusual behavior or
                                    potential attacks on a user’s account, it
                                    will automatically send the user an email
                                    alert. Users will be notified by email once
                                    per hour regardless of the number of
                                    suspicious logins detected.
                                </Text>
                                <Text pt="20px" fontWeight="600">
                                    Brute-Force Protection
                                </Text>
                                <Text pt="10px" pl="15px">
                                    If multiple failed login attempts are made
                                    from the same IP address using the same user
                                    account, Auth0 activates brute-force
                                    protection. This security measure
                                    temporarily blocks further login attempts to
                                    protect the account.
                                </Text>

                                <Text pt="20px" fontWeight="600">
                                    Possible Causes of Mistaken Alerts
                                </Text>
                                <Text pt="10px" pl="15px">
                                    While mistaken alerts are rare, they can
                                    occur under specific circumstances.
                                </Text>
                                <Text pt="10px" pl="15px">
                                    For example, if a user changes their
                                    PreFlight password but does not update it in{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        EFB's
                                    </span>{' '}
                                    or other{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        third-party applications
                                    </span>{' '}
                                    that store login credentials and provide the
                                    ability to automatically log into PreFlight
                                    on a user’s behalf (e.g.{' '}
                                    <span style={{ fontWeight: '500' }}>
                                        AvPlan
                                    </span>{' '}
                                    or{' '}
                                    <span style={{ fontWeight: '500' }}>
                                        OzRunways
                                    </span>
                                    ), those applications may continue using
                                    outdated credentials.
                                </Text>
                                <Text pt="10px" pl="15px">
                                    This could lead to repeated failed login
                                    attempts, which Auth0 might mistakenly
                                    interpret as suspicious activity, triggering
                                    email alerts and potentially activating
                                    brute-force protection on the user’s
                                    PreFlight account.
                                </Text>
                                <Text pt="20px" fontWeight="600">
                                    Important Reminder
                                </Text>
                                <Text pt="10px" pl="15px">
                                    If you've recently changed your PreFlight
                                    password, please ensure this is updated in
                                    any third-party applications where it is
                                    stored. Failing to do so may cause repeated
                                    failed login attempts, resulting in
                                    additional suspicious activity alerts and
                                    potential account blocking.
                                </Text>
                                <Text pt="20px" fontWeight="600">
                                    Account Access Issues
                                </Text>
                                <Text pt="10px" pl="15px">
                                    If your account is blocked, you continue to
                                    receive suspicious activity emails, or
                                    encounter other related issues, please
                                    contact us for assistance. We can remove the
                                    security block and help resolve any
                                    problems.
                                </Text>
                                <Box pt="10px" marginLeft="35px">
                                    <ul>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request by clicking{' '}
                                            <strong>Contact Us</strong> on the
                                            PreFlight homepage; or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Submit a request using the{' '}
                                            <strong>Send us feedback</strong>{' '}
                                            tool within the PreFlight web-app
                                            (if able to login); or
                                        </li>
                                        <li
                                            style={{
                                                paddingBottom: '5px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Contact:{' '}
                                            <Link
                                                color="light.200"
                                                href="mailto:helpdesk@airwaysinternational.co.nz?subject=Device Performance Issues with PreFlight"
                                            >
                                                helpdesk@airwaysinternational.co.nz
                                            </Link>
                                        </li>
                                    </ul>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        {/* MINIMSED SECTION */}
                        <Collapse in={isOpen} animateOpacity>
                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I’m an individual, how do I register
                                            for an account?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        <strong>How to self-register –</strong>
                                    </Text>

                                    <Text pt="10px">
                                        For a <strong>FREE</strong> account (
                                        <span
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            individuals only and not for
                                            commercial use
                                        </span>
                                        ):
                                    </Text>
                                    <Box pt="10px" marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Go to gopreflight.co.nz
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Click on the{' '}
                                                <strong>REGISTER</strong> button
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Enter the required information
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Check your email <br />
                                                (check your spam or junk email
                                                folders if you don’t receive
                                                anything within a few minutes)
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Click on the link in the email
                                                to activate your account
                                            </li>
                                        </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I’m an organisation, how do I
                                            register for an account?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        For a{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                                color: '#8D38E3',
                                            }}
                                        >
                                            PRO
                                        </span>{' '}
                                        account (
                                        <span
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            for commercial use
                                        </span>
                                        ):
                                    </Text>
                                    <Text pt="10px" marginLeft="12px">
                                        Organisations must ensure that each
                                        employee maintains an individual
                                        account.
                                    </Text>
                                    <Box pt="10px" marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Contact:{' '}
                                                <Link
                                                    color="light.200"
                                                    href="mailto:aviationsales@metservice.com?subject=New PRO Account Enquiry for PreFlight"
                                                >
                                                    aviationsales@metservice.com
                                                </Link>
                                                ; or
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Submit a request using the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                tool within the PreFlight
                                                web-app.
                                            </li>
                                        </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I’m an organisation and already have
                                            a PRO (commercial) account, what do
                                            I need to do?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    The MetService Aviation Sales team will be
                                    in contact with you directly to arrange
                                    account access.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I’m an organisation, how do I have
                                            users added or removed from my PRO
                                            (commercial) account?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Box pt="10px" marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Submit a request using the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                tool within the PreFlight
                                                web-app; or
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Contact:{' '}
                                                <Link
                                                    color="light.200"
                                                    href="mailto:aviationsales@metservice.com?subject=Update Existing PRO Account Enquiry for PreFlight"
                                                >
                                                    aviationsales@metservice.com
                                                </Link>
                                            </li>
                                        </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I have a new PRO (commercial)
                                            account, but my activation link has
                                            expired, what do I do?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text pt="10px">
                                        When your{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                                color: '#8D38E3',
                                            }}
                                        >
                                            PRO
                                        </span>{' '}
                                        (commercial) account is set up for your
                                        organisation, an email is sent to you
                                        with an activation link. Just for
                                        security purposes, this link expires
                                        after 5 days.
                                    </Text>

                                    <Text pt="20px">
                                        If you did not activate your account in
                                        this time, this is not a problem. Simply
                                        follow the steps below to activate and
                                        access your account:
                                    </Text>
                                    <Box marginLeft="25px" pt="10px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Select the{' '}
                                                <strong>Log In</strong> button
                                                on the PreFlight homepage at
                                                gopreflight.co.nz
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                At the bottom of the login page,
                                                select '
                                                <strong>
                                                    Don't remember your
                                                    password?
                                                </strong>
                                                '
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Enter your email address and an
                                                email will be sent to you with a
                                                link to reset your password.
                                            </li>
                                        </ul>
                                    </Box>
                                    <Flex width="100%" justifyContent="center">
                                        <Image
                                            alt={'Reset Password'}
                                            // fit={'cover'}
                                            align={'center'}
                                            maxWidth={'300px'}
                                            h={'auto'}
                                            src={passwordreset}
                                        />
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            Will my existing MetFlight or IFIS
                                            logins work on PreFlight?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    No, you will need to create a new account on
                                    PreFlight.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            Do I need a CAA ID number?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    pl="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    You do not need a CAA ID number to register,
                                    however you can record this information in
                                    your profile when you create an account.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            Can I still use MetFlight?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        After being retired as a product at the
                                        end of June 2023, MetFlight is no longer
                                        available.
                                    </Text>
                                    <Text pt="10px">
                                        We recommend using PreFlight as the
                                        suitable replacement for MetFlight.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            Can I still use IFIS?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        Yes, IFIS will continue running in
                                        parallel with PreFlight, however you are
                                        encouraged to register and start using
                                        PreFlight.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            How do I change my account email
                                            address?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text pt="10px">
                                        Just at the moment, users do not have
                                        the ability to change their account
                                        email address themselves within
                                        PreFlight, however you can easily
                                        request this change through PreFlight
                                        Support.
                                    </Text>
                                    <Text pt="15px" fontWeight="600">
                                        Let us know your new email address by:
                                    </Text>{' '}
                                    <Box pt="10px" marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Submitting a request using the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                tool within the PreFlight
                                                web-app; or
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Contacting:{' '}
                                                <Link
                                                    color="light.200"
                                                    href="mailto:helpdesk@airwaysinternational.co.nz?subject=Change Email Address in PreFlight"
                                                >
                                                    helpdesk@airwaysinternational.co.nz
                                                </Link>
                                            </li>
                                        </ul>
                                    </Box>
                                    <Text pt="20px" fontStyle="italic">
                                        Be sure to include your new email
                                        address.
                                    </Text>
                                    <Text pt="10px" fontStyle="italic">
                                        Please note that PreFlight Support
                                        operate's during normal business hours
                                        only, Monday to Friday. A confirmation
                                        email will be sent to you once this
                                        change has been made on your account.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            How do I reset my password?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text pt="10px">
                                        If you wish to change your password, or
                                        you cannot remember it, there are two
                                        methods to reset this.
                                    </Text>

                                    <Text pt="15px" fontWeight="600">
                                        When logged in to PreFlight:
                                    </Text>
                                    <Box marginLeft="25px" pt="10px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                From the admin dashboard, select
                                                your profile
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Click{' '}
                                                <strong>Change Password</strong>
                                            </li>
                                        </ul>
                                    </Box>
                                    <Text pt="20px" fontWeight="600">
                                        Alternatively, if you cannot remember
                                        your password:
                                    </Text>
                                    <Box marginLeft="25px" pt="10px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Select the{' '}
                                                <strong>Log In</strong> button
                                                on the PreFlight homepage at
                                                gopreflight.co.nz
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                At the bottom of the login page,
                                                select '
                                                <strong>
                                                    Don't remember your
                                                    password?
                                                </strong>
                                                '
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Enter your email address and an
                                                email will be sent to you with a
                                                link to reset your password.
                                            </li>
                                        </ul>
                                    </Box>
                                    <Flex width="100%" justifyContent="center">
                                        <Image
                                            alt={'Reset Password'}
                                            // fit={'cover'}
                                            align={'center'}
                                            maxWidth={'300px'}
                                            h={'auto'}
                                            src={passwordreset}
                                        />
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            How can I save a 'favourite'
                                            Briefing to use again later?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    pl="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        Whilst we are yet to provide{' '}
                                        <span style={{ fontWeight: '600' }}>
                                            saved favourites
                                        </span>{' '}
                                        in the Briefing, currently there is an
                                        alternative solution to help swiftly
                                        obtain a briefing based on your
                                        'favourite' criteria.
                                    </Text>
                                    <Text pt="10px">
                                        After you have generated a Briefing,
                                        deselected any unnecessary aerodromes or
                                        AWS that you do not need, and filtered
                                        the information to only show what you
                                        require, you can copy and save the URL
                                        from the address bar and use this at a
                                        later stage.{' '}
                                    </Text>
                                    <Box
                                        pt="25px"
                                        pb="25px"
                                        px="0px"
                                        width="100%"
                                        textAlign="center"
                                        color="gray.300"
                                        fontWeight="400"
                                    >
                                        <Image
                                            alt=""
                                            fit={'cover'}
                                            align={'center'}
                                            maxWidth="900px"
                                            w={'100%'}
                                            h={'100%'}
                                            borderRadius="12px"
                                            src={savedurls}
                                            boxShadow={{
                                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                            }}
                                        />
                                    </Box>
                                    <Text pt="10px">
                                        Loading this saved URL directly in your
                                        browser address bar will load that same
                                        briefing again based on your filtered
                                        selections but with up-to-date
                                        information, which will bypass all the
                                        steps prior, speeding up this process.{' '}
                                    </Text>
                                    <Text pt="10px">
                                        You could save this custom URL (and
                                        others) as a favourite on your browser
                                        and use this each time when required
                                        (assuming the aerodromes and areas you
                                        require are the same). This will work in
                                        browsers on desktop and mobile devices
                                        and is a way of loading a "favourite" in
                                        lieu of 'saved favourites' functionality
                                        which we intend to roll out at a later
                                        date.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            Where do I find PreFlight on the
                                            Apple App Store or Google Play
                                            Store?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        PreFlight has been built as a web-based
                                        application. Essentially it is a website
                                        that utilises dynamic data and mapping
                                        software. Currently there is no
                                        PreFlight 'app' available from the Apple
                                        App Store or Google Play Store.
                                    </Text>
                                    <Text pt="10px">
                                        However, PreFlight has been designed in
                                        a way to be used like a dedicated
                                        application through features available
                                        in your mobile device's web browser
                                        settings.
                                    </Text>
                                    <Text pt="10px">
                                        With the PreFlight website loaded in
                                        your web browser on your mobile phone or
                                        tablet, open your{' '}
                                        <strong>web browser tools menu</strong>.
                                        From here you can save PreFlight to your
                                        home screen. This will save an icon to
                                        your mobile device, and removes the web
                                        address bar to fill the whole screen, so
                                        you can use PreFlight in a similar way
                                        to a native app.
                                    </Text>

                                    <Flex
                                        pt="30px"
                                        pb="10px"
                                        width="100%"
                                        justifyContent="space-evenly"
                                        gap={{
                                            base: '35px',
                                            smd: '20px',
                                        }}
                                        flexDirection={{
                                            base: 'column',
                                            smd: 'row',
                                        }}
                                    >
                                        <Flex
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            gap="20px"
                                        >
                                            <Image
                                                borderRadius="15px"
                                                boxShadow="0px 10px 30px -15px rgba(0,0,0,0.7)"
                                                alt={'iOS'}
                                                align={'center'}
                                                maxWidth={'200px'}
                                                h={'auto'}
                                                src={iOSAddToHomescreen}
                                            />
                                            <Text
                                                width="100%"
                                                textAlign="center"
                                                fontWeight="500"
                                                color="gray.400"
                                            >
                                                iOS
                                            </Text>
                                        </Flex>
                                        <Flex
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            gap="20px"
                                        >
                                            <Image
                                                borderRadius="15px"
                                                boxShadow="0px 10px 30px -15px rgba(0,0,0,0.7)"
                                                alt={'Android'}
                                                align={'center'}
                                                maxWidth={'205px'}
                                                h={'auto'}
                                                src={androidAddToHomescreen}
                                            />
                                            <Text
                                                width="100%"
                                                textAlign="center"
                                                fontWeight="500"
                                                color="gray.400"
                                            >
                                                Android
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            If I discover aeronautical
                                            information on PreFlight differs
                                            from the AIP, what should I trust?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        Aeronautical information on the map is
                                        solely intended to enhance situational
                                        awareness within PreFlight only, and{' '}
                                        <strong>should not</strong> be
                                        considered a suitable alternative for
                                        the aeronautical charts within AIPNZ.
                                    </Text>
                                    <Text pt="10px">
                                        The AIPNZ shall supersede PreFlight if
                                        there are any differences identified.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            How do I provide feedback or ask a
                                            question?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        You can provide comments and
                                        suggestions, ask a question, or report
                                        any bugs that you may find on the
                                        web-app by using the{' '}
                                        <strong>Send us feedback</strong> form
                                        within the PreFlight web-app. This is
                                        located in the admin dashboard.
                                    </Text>

                                    <Text pt="10px">
                                        Complete the form and submit. We will be
                                        in touch if your query requires a
                                        response.
                                    </Text>
                                    <Text pt="10px">
                                        All feedback will help us to improve and
                                        plan future development of the web-app.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I have upgraded to a paid
                                            subscription, but I cannot access
                                            the additional features?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    pl="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Text>
                                        When purchasing a paid subscription,
                                        i.e. upgrading from a FREE plan to a{' '}
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                color: '#007DC8',
                                            }}
                                        >
                                            PLUS
                                        </span>{' '}
                                        plan, your account is automatically
                                        updated to unlock the benefits of this
                                        paid subscription.
                                    </Text>
                                    <Text pt="10px">
                                        However on some devices, this change of
                                        the account type may not be immediately
                                        recognised. After upgrading your
                                        susbscription, please log out of your
                                        account and then log back in. This will
                                        update the cache for your device which
                                        will then unlock these additional
                                        features.
                                    </Text>
                                    <Text pt="10px">
                                        Should you experience any further issues
                                        accessing your paid subscription
                                        features, reach out to us and we will
                                        help sort this for you.
                                    </Text>
                                    <Box pt="15px" pl="20px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Let us know your issue through
                                                the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                tool within the PreFlight
                                                web-app; or
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Contact:{' '}
                                                <Link
                                                    color="light.200"
                                                    href="mailto:helpdesk@airwaysinternational.co.nz?subject=Cannot Access Premium Features in PreFlight"
                                                >
                                                    helpdesk@airwaysinternational.co.nz
                                                </Link>
                                            </li>
                                        </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I have a METEOROLOGICAL related
                                            query, how do I contact MetService?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Box marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Select the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                form within the PreFlight
                                                web-app. This is located in the
                                                admin dashboard.
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                From the options presented,
                                                select{' '}
                                                <strong>
                                                    My feedback relates to MET
                                                    information
                                                </strong>
                                                .
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Complete the form and submit.
                                            </li>
                                        </ul>
                                    </Box>

                                    <Text pt="10px">
                                        We will be in touch if your query
                                        requires a response.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I have an AERONAUTICAL related
                                            query, how do I contact Aeropath?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Box marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Select the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                form within the PreFlight
                                                web-app. This is located in the
                                                admin dashboard.
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                From the options presented,
                                                select{' '}
                                                <strong>
                                                    My feedback relates to AERO
                                                    information
                                                </strong>
                                                .
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Complete the form and submit.
                                            </li>
                                        </ul>
                                    </Box>
                                    <Text pt="10px">
                                        We will be in touch if your query
                                        requires a response.
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border="none">
                                <h2>
                                    <AccordionButton
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        fontWeight="500"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        py="3"
                                        _expanded={{
                                            bg: 'light.20',
                                            color: 'light.200',
                                            boxShadow:
                                                '0px 10px 20px -15px rgba(0,0,0,0.4)',
                                        }}
                                        _hover={{ background: 'light.30' }}
                                    >
                                        <Box flex="1" textAlign="left">
                                            I’m having technical issues, who can
                                            I contact?
                                        </Box>
                                        <AccordionIcon fontSize="1.3rem" />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    pt="20px"
                                    pb={6}
                                    px="30px"
                                    color="gray.500"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.9rem',
                                    }}
                                >
                                    <Box pt="10px" marginLeft="25px">
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Submit a request using the{' '}
                                                <strong>
                                                    Send us feedback
                                                </strong>{' '}
                                                tool within the PreFlight
                                                web-app; or
                                            </li>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Contact:{' '}
                                                <Link
                                                    color="light.200"
                                                    href="mailto:helpdesk@airwaysinternational.co.nz?subject=Technical Issue with PreFlight"
                                                >
                                                    helpdesk@airwaysinternational.co.nz
                                                </Link>
                                            </li>
                                        </ul>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                        </Collapse>
                        <Flex
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            p="20px"
                        >
                            <Button
                                size="md"
                                color="gray.400"
                                onClick={onToggle}
                                variant="outline"
                                fontSize="0.8rem"
                                borderRadius="25px"
                                fontWeight="500"
                                px="25px"
                                _hover={{
                                    color: 'gray.500',
                                    background: 'light.20',
                                }}
                            >
                                {isOpen ? 'Show less' : 'Show more FAQs'}
                            </Button>
                        </Flex>
                    </Accordion>
                </Flex>
            </Flex>
        </LightMode>
    )
}

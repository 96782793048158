import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
    modalVisibilityState,
    dataModeState,
    layerSelectionState,
    setModalVisibilityState,
    tourVisibilityState,
    selectDefaultMapLocationState,
    mapDisabledState,
    isBriefingOnlyState,
} from '../../globalState'

import ClickedMultiple from './ClickedMultiple'
import SingleModal from './SingleModal'
import { clickedFeaturesState } from '../../globalState'
import AnnouncementsModal from './announcements'
import TermsModal from './terms-and-conditions'
import FAQsModal from './FAQs'
// import AboutModal from './About'
import DeviceDetailsModal from './device-details'
import DeviceWarningModal from '../../device-warning'
import DeviceRequirementsModal from '../../device-warning/DeviceRequirements'
import DataStatusModal from './admin/DataStatus'
import UserReportModal from './admin/UserReport'
import PricingTiers from './admin/PricingTiers'
import PremiumOnlyModal from './admin/premium-only'
import AerodromeSpritesModal from './admin/AerodromeSprites'
import RunwaysModal from './admin/Runways'
import Briefing from './briefing'
import Alerts from './alerts'
import SpaceWxModal from './SpaceWxModal'
// import OnlineUsersModal from './admin/OnlineUsers'
import ManageNotifications from './admin/ManageNotifications'
import LoginMessage from './LoginMessage'
import ProfileModal from './profile'
import RainRadarImagesModal from './met-imagery/image-loopers/RainRadarImageLooper'
import CloudImageryLooper from './met-imagery/image-loopers/CloudImageryLooper'
import LowCloudForecastImageryLooper from './met-imagery/image-loopers/LowCloudForecast'
import RainForecastImageryLooper from './met-imagery/image-loopers/RainForecast'
import GraforModal from './met-imagery/GRAFOR'
import SigwxModal from './met-imagery/SIGWX'
import SigmetModal from './met-imagery/SIGMET'
import MslModal from './met-imagery/MSL'
import PDFModal from './components/PDFModal'
import LegendModal from './admin/legend'
import WarningMessage from './alerts/warning-message'
import ResetMFAModal from './mfa'
import WhyMFAModal from './mfa/WhyMFAModal'
import MctEctDisclaimer from './components/aerodrome/components/MctEctDisclaimer'
import SpecialNoticeModal from './SpecialNotice'
import ContactUsModal from './ContactUsModal'

export default function Modals() {
    const clickedFeatures = useRecoilValue(clickedFeaturesState)
    const modalVisible = useRecoilValue(modalVisibilityState)
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const introTour = useRecoilValue(tourVisibilityState)
    const selectDefaultMapLocation = useRecoilValue(
        selectDefaultMapLocationState
    )
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)

    useEffect(() => {
        setModalVisibility({ id: 'pricingTiers', value: false })
    }, [dataMode, layerSelection, setModalVisibility])

    // useEffect(() => {
    //     console.log('Modals Visible: ', modalVisible)
    //     console.log('dataMode: ', dataMode)
    // }, [modalVisible, dataMode])

    return (
        <>
            <TermsModal />
            {!introTour && modalVisible.profile && <ProfileModal />}
            {!introTour &&
                !selectDefaultMapLocation && ( // intro tour wont start until modals below are closed
                    <>
                        <LoginMessage />
                        <Alerts mapDisabled={isBriefingOnly || mapDisabled} />
                        <AnnouncementsModal />
                        <FAQsModal />
                        {/* <AboutModal />  // Credits modal - disabled */}
                        <DeviceDetailsModal />
                        <DeviceWarningModal />
                        <SpecialNoticeModal />
                        <DeviceRequirementsModal />
                        {modalVisible.resetMfa && <ResetMFAModal />}
                        {modalVisible.whyMfa && <WhyMFAModal />}
                        {modalVisible.pdf && <PDFModal />}
                        {modalVisible.loginMessage && <ManageNotifications />}
                        {modalVisible.dataStatus && <DataStatusModal />}
                        {modalVisible.userReport && <UserReportModal />}
                        {modalVisible.aerodromeSprites && (
                            <AerodromeSpritesModal />
                        )}
                        {modalVisible.runways && <RunwaysModal />}
                        {modalVisible.pricingTiers && <PricingTiers />}
                        {dataMode === 'advs' &&
                            modalVisible.premiumOnly &&
                            !modalVisible.premiumOnlyForwarded && (
                                <PremiumOnlyModal />
                            )}
                        {dataMode === 'met' &&
                            layerSelection.met.cloudImagery && (
                                <CloudImageryLooper />
                            )}
                        {dataMode === 'met' &&
                            layerSelection.met.rainforecast && (
                                <RainForecastImageryLooper />
                            )}
                        {dataMode === 'met' &&
                            layerSelection.met.lowcloudforecast && (
                                <LowCloudForecastImageryLooper />
                            )}
                        {dataMode === 'met' &&
                            layerSelection.met.rainRadarImagery && (
                                <RainRadarImagesModal />
                            )}
                        {dataMode === 'met' &&
                            layerSelection.met.graforCharts && <GraforModal />}
                        {dataMode === 'met' &&
                            layerSelection.met.sigwxCharts && <SigwxModal />}
                        {dataMode === 'met' &&
                            layerSelection.met.sigmetCharts && <SigmetModal />}
                        {dataMode === 'met' && layerSelection.met.spacewx && (
                            <SpaceWxModal />
                        )}
                        {dataMode === 'met' && layerSelection.met.mslCharts && (
                            <MslModal />
                        )}
                        {/* <OnlineUsersModal /> // COMMENTED OUT FOR NOW DUE POSSIBLE RELATION TO EMAIL ADDRESSES BEING ACCESSIBLE TO PUBLIC */}
                        {dataMode === 'brief' && <Briefing />}
                        {clickedFeatures && clickedFeatures.length > 1 && (
                            <ClickedMultiple />
                        )}
                        {clickedFeatures && clickedFeatures.length === 1 && (
                            <SingleModal />
                        )}
                        {modalVisible.legend && <LegendModal />}
                        {modalVisible.contactus && <ContactUsModal />}
                        <WarningMessage />
                        <MctEctDisclaimer />
                    </>
                )}
        </>
    )
}

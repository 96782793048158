import React, { useRef, useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    Flex,
    Center,
    Button,
    Box,
    Text,
    VStack,
    useBreakpointValue,
    Portal,
    Tooltip,
    Spinner,
    Skeleton,
} from '@chakra-ui/react'
import { timeZoneState, compassVarState } from '../../../../globalState'
import {
    formatTimeAndTimezone,
    timeZoneOffsetUTC,
} from '../../../../util/dateFormatter'
import { isDesktop } from 'react-device-detect'
import { parseISO, formatISO, formatDistanceToNow } from 'date-fns'
import CompassRose from './components/CompassRose'
import MctEct from './components/MCT-ECT'
import { cloudCoverBoxColor, cloudCoverText } from './backgrounds/cloudCoverBg'

import { ReactComponent as SunriseIcon } from '../../../../icons/assets/sunrise.svg'
import { ReactComponent as SunsetIcon } from '../../../../icons/assets/sunset.svg'
import { ReactComponent as CautionIcon } from '../../../../icons/assets/caution-question.svg'

import AerodromesVisibility from './components/Visibility'
import AerodromesCloud from './components/Cloud'
import AerodromesWind from './components/Wind'
import AerodromesQnh from './components/Qnh'
import AerodromesTempAndDewpoint from './components/TempAndDewpoint'
import AerodromesPresentWeather from './components/PresentWeather'

export default function InfoContent(props) {
    const {
        data,
        MCT,
        ECT,
        usingUsnoData,
        showTwighlightTimes,
        metarCloud,
        metarActive,
        cloudCoverColor,
        timePeriod,
        metarOutOfDateIssueTime,
        infoTabContainerRef,
        aerodromeBackgroundsDisabled,
        infotabGraphicsDisabled,
        setMctEctDisclaimerModal,
        showFooter,
        fog,
        precipitationPresent,
    } = props
    const timeZone = useRecoilValue(timeZoneState)
    const hasRunway = data.runways
    const [compassRoseMagVar, setCompassRoseMagVar] =
        useRecoilState(compassVarState)

    // MCT/ECT Time Dial
    const daylightSavingNZ =
        MCT &&
        ECT &&
        timeZoneOffsetUTC(Date.now(), 'Pacific/Auckland') === '+13:00'

    const mctvar =
        MCT &&
        ((MCT.getHours() + (timeZone === 'UTC' && daylightSavingNZ && -1)) *
            60 +
            MCT.getMinutes()) /
            4

    const ectvar =
        ECT &&
        ((ECT.getHours() + (timeZone === 'UTC' && daylightSavingNZ && -1)) *
            60 +
            ECT.getMinutes()) /
            4
    const now = new Date()
    const nowvar =
        ((now.getHours() + (timeZone === 'UTC' && daylightSavingNZ && -1)) *
            60 +
            now.getMinutes()) /
        4
    const night = timePeriod === 'Night'

    // Is it Formatted METAR
    const latestMetar =
        data && data.metar && data.metar.length > 0 && data.metar[0].data
            ? data.metar[0].data
            : null

    const latestMetarObs =
        latestMetar &&
        latestMetar.observation &&
        latestMetar.observation.length > 0 &&
        latestMetar.observation[0]
    // Weather
    const cloud = latestMetarObs && latestMetar?.observation[0]?.cloud

    const cloudlayers =
        latestMetarObs &&
        latestMetar?.observation[0]?.cloud &&
        latestMetar?.observation[0]?.cloud?.layer
    const reversedCloudLayers = [] // reverse order so cloud layers stacked with heighest at top
    for (let i = cloudlayers && cloudlayers.length - 1; i >= 0; i--) {
        reversedCloudLayers.push(cloudlayers[i])
    }

    const noCloudDetected =
        latestMetarObs &&
        latestMetar?.observation[0]?.cloud &&
        latestMetar?.observation[0]?.cloud?.noCloudDetected

    const cloudAndVisibilityOK =
        latestMetarObs && latestMetar?.observation[0]?.cloudAndVisibilityOK

    const multipleCloudLayers =
        reversedCloudLayers && reversedCloudLayers.length > 1

    const verticalVisibility =
        latestMetarObs &&
        latestMetar?.observation[0] &&
        latestMetar?.observation[0]?.verticalVisibility

    const winddir =
        latestMetarObs && latestMetar?.observation[0]?.surfaceWind
            ? latestMetar?.observation[0]?.surfaceWind?.meanWindDirection
            : null
    const windvrb =
        latestMetarObs && latestMetar?.observation[0]?.surfaceWind
            ? latestMetar?.observation[0]?.surfaceWind?.variableWindDirection
            : null
    const windspeed =
        latestMetarObs && latestMetar?.observation[0]?.surfaceWind
            ? latestMetar?.observation[0]?.surfaceWind?.meanWindSpeed
            : null
    const windgust =
        latestMetarObs && latestMetar?.observation[0]?.surfaceWind
            ? latestMetar?.observation[0]?.surfaceWind?.windGustSpeed
            : null

    const temp =
        latestMetarObs &&
        latestMetar?.observation[0]?.airTemperature &&
        latestMetar?.observation[0]?.airTemperature?.value &&
        (latestMetar?.observation[0].airTemperature?.value === 0
            ? 0
            : latestMetar?.observation[0]?.airTemperature?.value
            ? latestMetar?.observation[0]?.airTemperature?.value
            : null)

    const dewpoint =
        latestMetarObs &&
        latestMetar?.observation[0]?.dewpointTemperature &&
        latestMetar?.observation[0]?.dewpointTemperature?.value &&
        (latestMetar?.observation[0]?.dewpointTemperature?.value === 0
            ? 0
            : latestMetar?.observation[0]?.dewpointTemperature?.value
            ? latestMetar?.observation[0]?.dewpointTemperature?.value
            : null)

    const horizontalVisibility =
        latestMetarObs &&
        latestMetar?.observation[0]?.horizontalVisibility &&
        latestMetar?.observation[0]?.horizontalVisibility?.value
            ? latestMetar?.observation[0]?.horizontalVisibility
            : null

    const qnh =
        latestMetarObs &&
        latestMetar?.observation[0]?.qnh &&
        latestMetar?.observation[0]?.qnh?.value
            ? latestMetar?.observation[0]?.qnh?.value
            : null

    const presentWeather =
        latestMetarObs && latestMetar?.observation[0]?.presentWeather
            ? latestMetar?.observation[0]?.presentWeather
            : null

    const magvar = data?.magvar

    const winddirDegreesMag = winddir?.value
        ? winddir.value <= magvar
            ? (winddir.value + -magvar + 360).toString().padStart(3, '0')
            : (winddir.value + -magvar).toString().padStart(3, '0')
        : null

    // Move PresentWeather box on Desktop if scrollbar appears (can occur when window size not large and typically when multiple cloud layers)
    const containerRef = useRef(null)
    const [overflow, setOverflow] = useState(false)
    useEffect(() => {
        if (infoTabContainerRef.current && containerRef.current) {
            const containerHeight =
                containerRef.current.getBoundingClientRect().height
            const infoTabContainerHeight =
                infoTabContainerRef.current.getBoundingClientRect().height - 70
            const scrollVisible = containerHeight > infoTabContainerHeight
            if (scrollVisible) {
                setOverflow(true)
            } else {
                setOverflow(false)
            }
        }
    }, [infoTabContainerRef, metarOutOfDateIssueTime, overflow, presentWeather])

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()
    const footerMobile = useRef()
    const footerDesktop = useRef()
    const footerOutOfDate = useRef()
    const presentWeatherPlacement = useBreakpointValue({
        base: { containerRef: mobile },
        md: { containerRef: infotabGraphicsDisabled ? mobile : desktop },
    })
    const footerPlacement = useBreakpointValue({
        base: { containerRef: footerMobile },
        md: {
            containerRef:
                metarOutOfDateIssueTime !== 'NONE'
                    ? footerOutOfDate
                    : !infotabGraphicsDisabled && overflow
                    ? footerDesktop
                    : footerMobile,
        },
    })

    const cloudCoverColorDetail = cloudCoverText(
        timePeriod === 'Night'
            ? 'Night'
            : timePeriod === 'Sunrise'
            ? 'Sunrise'
            : timePeriod === 'Sunset'
            ? 'Sunset'
            : metarCloud
            ? metarCloud
            : `rgba(70, 145, 195, 0.8)`
    )

    // T/M Switch BG
    const switchBG =
        metarActive && !aerodromeBackgroundsDisabled
            ? cloudCoverBoxColor(
                  timePeriod === 'Night'
                      ? 'Night'
                      : timePeriod === 'Sunrise'
                      ? 'Sunrise'
                      : timePeriod === 'Sunset'
                      ? 'Sunset'
                      : metarCloud
                      ? metarCloud
                      : '#eaeef2'
              )
            : '#eaeef2'
    const switchBGHover =
        metarActive && !aerodromeBackgroundsDisabled
            ? cloudCoverBoxColor(
                  timePeriod === 'Night'
                      ? 'Night'
                      : timePeriod === 'Sunrise'
                      ? 'Sunrise'
                      : timePeriod === 'Sunset'
                      ? 'Sunset'
                      : metarCloud
                      ? metarCloud
                      : 'gray.200'
              )
            : 'gray.200'

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    // console.log('latestMetar: ', latestMetar)
    // console.log(formatTimeAndTimezone(latestMetar.issuetime, timeZone))
    // console.log('metarOutOfDateIssueTime: ', metarOutOfDateIssueTime)

    return (
        <>
            {metarOutOfDateIssueTime !== 'NONE' && (
                <Box
                    pt={{
                        base: '0px',
                        md: '5px',
                    }}
                    px="5px"
                    width="100%"
                    ref={footerOutOfDate}
                ></Box>
            )}
            <Flex
                w="100%"
                flexDirection={{
                    base: 'column',
                    md: 'row-reverse',
                }}
                justifyContent="space-between"
                pt="5px"
                pb="5px"
                pl={{
                    base: '0',
                    md: metarActive ? '5px' : '0px',
                }}
                pr={{
                    base: '0',
                    md: metarActive ? '5px' : '0px',
                }}
                gap={{
                    base: infotabGraphicsDisabled ? '5px' : '15px',
                    md: '20px',
                }}
            >
                <Flex
                    justifyContent="space-between"
                    width="100%"
                    maxWidth={{ base: '100%', md: '290px' }}
                    direction="column"
                    px={{ base: '5px', md: '0px' }}
                >
                    <Flex
                        justifyContent={
                            !hasRunway
                                ? 'flex-end'
                                : metarActive
                                ? 'flex-start'
                                : 'space-around'
                        }
                        width="100%"
                        direction={{
                            base: 'column',
                            md: metarActive ? 'column' : 'row-reverse',
                        }}
                    >
                        {data && hasRunway && (
                            <Center
                                mt={{
                                    base: '5px',
                                    md: '5px',
                                }}
                                mb={{
                                    base: '10px',
                                    md: '10px',
                                }}
                            >
                                <Flex flexDirection="column" width="100%">
                                    <Flex
                                        mt={{
                                            base: '5px',
                                            md: '0px',
                                        }}
                                        width="max-content"
                                        justifyContent="center"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        borderRadius="100%"
                                        background={
                                            metarActive &&
                                            !aerodromeBackgroundsDisabled
                                                ? cloudCoverBoxColor(
                                                      timePeriod === 'Night'
                                                          ? 'Night'
                                                          : timePeriod ===
                                                            'Sunrise'
                                                          ? 'Sunrise'
                                                          : timePeriod ===
                                                            'Sunset'
                                                          ? 'Sunset'
                                                          : metarCloud
                                                          ? metarCloud
                                                          : '#FDFEFF'
                                                  )
                                                : '#FDFEFF'
                                        }
                                        filter={
                                            aerodromeBackgroundsDisabled
                                                ? 'none'
                                                : 'contrast(102%) saturate(102%)'
                                        }
                                        backdropFilter={
                                            aerodromeBackgroundsDisabled
                                                ? 'none'
                                                : 'blur(25px) contrast(102%) saturate(102%)'
                                        }
                                        boxShadow={
                                            aerodromeBackgroundsDisabled
                                                ? '0px 6px 20px -5px rgba(20, 30, 40, 0.14)'
                                                : 'none'
                                        }
                                    >
                                        <CompassRose
                                            winddir={
                                                winddir &&
                                                winddir.value
                                                    .toString()
                                                    .padStart(3, '0')
                                            }
                                            winddirDegreesMag={
                                                winddir && winddirDegreesMag
                                            }
                                            metarActive={metarActive}
                                            windstr={
                                                windspeed && windspeed.value
                                            }
                                            windgust={
                                                windgust && windgust.value
                                            }
                                            windvrb={windvrb && windvrb.value}
                                            magvar={magvar}
                                            compassRoseMagVar={
                                                compassRoseMagVar
                                            }
                                            runwayGeoJson={data.runways}
                                            timePeriod={timePeriod}
                                            aerodromeBackgroundsDisabled={
                                                aerodromeBackgroundsDisabled
                                            }
                                        />
                                    </Flex>
                                    <Flex
                                        flexDirection="row"
                                        width="100%"
                                        justifyContent="space-between"
                                    >
                                        <Flex>
                                            <Text
                                                mt={{
                                                    base: '-10px',
                                                    md: '-5px',
                                                }}
                                                pr="5px"
                                                opacity="0.6"
                                                textAlign="left"
                                                letterSpacing="0.5px"
                                                color={
                                                    metarActive &&
                                                    !aerodromeBackgroundsDisabled
                                                        ? cloudCoverColor
                                                        : '#969da3'
                                                }
                                                fontSize={{
                                                    base: '0.75rem',
                                                    xs: '0.8rem',
                                                    md: '0.8rem',
                                                }}
                                                fontWeight={400}
                                            >
                                                {compassRoseMagVar
                                                    ? 'Direction °M'
                                                    : 'Direction °T'}
                                            </Text>
                                        </Flex>

                                        <Flex justifyContent="end">
                                            <Button
                                                mt={{
                                                    base: '-15px',
                                                    md: '-10px',
                                                }}
                                                index={
                                                    compassRoseMagVar ? 1 : 0
                                                }
                                                background={
                                                    metarActive &&
                                                    !aerodromeBackgroundsDisabled
                                                        ? cloudCoverBoxColor(
                                                              timePeriod ===
                                                                  'Night'
                                                                  ? 'Night'
                                                                  : timePeriod ===
                                                                    'Sunset'
                                                                  ? 'Sunset'
                                                                  : metarCloud
                                                                  ? metarCloud
                                                                  : '#eef1f5'
                                                          )
                                                        : '#eef1f5'
                                                }
                                                filter={
                                                    aerodromeBackgroundsDisabled
                                                        ? 'none'
                                                        : 'contrast(102%) saturate(102%)'
                                                }
                                                backdropFilter={
                                                    aerodromeBackgroundsDisabled
                                                        ? 'none'
                                                        : 'blur(25px) contrast(102%) saturate(102%)'
                                                }
                                                boxShadow={
                                                    metarActive &&
                                                    !aerodromeBackgroundsDisabled
                                                        ? 'none'
                                                        : 'inset 0px 0px 3px -1px rgba(0,0,0,0.3)'
                                                }
                                                fontSize="11px"
                                                fontWeight="600"
                                                height="22px"
                                                width="72px"
                                                style={{
                                                    minWidth: '72px',
                                                }}
                                                justifyContent="initial"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                py="2px"
                                                borderRadius="20px"
                                                border="none"
                                                {...noFocus}
                                                onClick={() => {
                                                    setCompassRoseMagVar(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    background: switchBG,
                                                }}
                                                _active={{
                                                    background: switchBG,
                                                }}
                                                _hover={{
                                                    background: switchBGHover,
                                                }}
                                            >
                                                <Box
                                                    zIndex="1"
                                                    mt="0px"
                                                    ml="0px"
                                                    px="17px"
                                                    height="18px"
                                                    width="34px"
                                                    borderRadius="20px"
                                                    background={
                                                        metarActive &&
                                                        !aerodromeBackgroundsDisabled
                                                            ? 'rgba(255,255,255,0.15)'
                                                            : 'white'
                                                    }
                                                    boxShadow={
                                                        metarActive &&
                                                        !aerodromeBackgroundsDisabled
                                                            ? 'none'
                                                            : '0px 0px 3px -1px rgba(0,0,0,0.3)'
                                                    }
                                                    border="0px solid rgba(255,255,255,0.5)"
                                                    borderColor="#E5EAF1"
                                                    _hover={{
                                                        background:
                                                            metarActive &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? 'rgba(255,255,255,0.15)'
                                                                : 'white',
                                                    }}
                                                    transform={
                                                        compassRoseMagVar
                                                            ? 'translateX(100%)'
                                                            : 'translateX(0%)'
                                                    }
                                                    transition="transform 600ms"
                                                ></Box>
                                                <Flex
                                                    justifyContent="space-evenly"
                                                    marginLeft="-32px"
                                                >
                                                    <Text
                                                        zIndex="2"
                                                        paddingLeft="7px"
                                                        opacity={
                                                            compassRoseMagVar
                                                                ? '0.6'
                                                                : '1'
                                                        }
                                                        color={
                                                            metarActive &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? 'light.10'
                                                                : 'light.400'
                                                        }
                                                        _selected={{
                                                            opacity: '1',
                                                        }}
                                                        _active={{
                                                            opacity: '1',
                                                        }}
                                                        transition="opacity 600ms"
                                                    >
                                                        °T
                                                    </Text>
                                                    <Text
                                                        zIndex="2"
                                                        paddingLeft="22px"
                                                        opacity={
                                                            compassRoseMagVar
                                                                ? '1'
                                                                : '0.6'
                                                        }
                                                        color={
                                                            metarActive &&
                                                            !aerodromeBackgroundsDisabled
                                                                ? 'light.10'
                                                                : 'light.400'
                                                        }
                                                        _selected={{
                                                            opacity: '1',
                                                        }}
                                                        _active={{
                                                            opacity: '1',
                                                        }}
                                                        transition="opacity 600ms"
                                                    >
                                                        °M
                                                    </Text>
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Center>
                        )}
                        {MCT && ECT && (
                            <Flex
                                direction="column"
                                height={metarActive ? 'auto' : 'max-content'}
                                justifyContent={{
                                    base: 'space-evenly',
                                    md: metarActive
                                        ? 'space-between'
                                        : 'flex-start',
                                }}
                                mt={{
                                    base: winddir ? '2px' : '12px',
                                    md: winddir ? '5px' : '15px',
                                }}
                                mb={{
                                    base: '15px',
                                    md: '0px',
                                }}
                                pb={{
                                    base: '10px',
                                    md: '10px',
                                }}
                                width={metarActive ? '100%' : 'auto'}
                                background={
                                    metarActive && !aerodromeBackgroundsDisabled
                                        ? cloudCoverBoxColor(
                                              timePeriod === 'Night'
                                                  ? 'Night'
                                                  : timePeriod === 'Sunrise'
                                                  ? 'Sunrise'
                                                  : timePeriod === 'Sunset'
                                                  ? 'Sunset'
                                                  : metarCloud
                                                  ? metarCloud
                                                  : 'white'
                                          )
                                        : 'white'
                                }
                                border={
                                    metarActive
                                        ? 'none'
                                        : '1px solid rgb(229 235 242)'
                                }
                                filter={
                                    aerodromeBackgroundsDisabled
                                        ? 'none'
                                        : 'contrast(102%) saturate(102%)'
                                }
                                backdropFilter={
                                    aerodromeBackgroundsDisabled
                                        ? 'none'
                                        : 'blur(25px) contrast(102%) saturate(102%)'
                                }
                                boxShadow={
                                    aerodromeBackgroundsDisabled
                                        ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                                        : 'none'
                                }
                                borderRadius="20px"
                            >
                                <Flex
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    width="100%"
                                    pl="22px"
                                    pr="16px"
                                    pt="15px"
                                    pb="2px"
                                    zIndex="1"
                                >
                                    <Text
                                        color={
                                            metarActive &&
                                            !aerodromeBackgroundsDisabled
                                                ? cloudCoverColor
                                                : 'gray.500'
                                        }
                                        fontSize="0.7rem"
                                        opacity="0.6"
                                        letterSpacing="0.5px"
                                        fontWeight={500}
                                    >
                                        MCT / ECT
                                    </Text>
                                    <Tooltip
                                        display={isDesktop ? 'flex' : 'none'}
                                        isDisabled={isDesktop ? false : true}
                                        label={
                                            usingUsnoData ? (
                                                <Flex
                                                    direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    py="3px"
                                                    width="100%"
                                                >
                                                    <Text
                                                        width="100%"
                                                        textAlign="center"
                                                    >
                                                        MCT and ECT times
                                                        derived from the
                                                    </Text>
                                                    <Text
                                                        width="100%"
                                                        textAlign="center"
                                                        fontWeight="700"
                                                    >
                                                        Astronomical
                                                        Applications Department
                                                        of the U.S. Naval
                                                        Observatory (USNO)
                                                    </Text>
                                                    <Text
                                                        width="100%"
                                                        pt="5px"
                                                        pr="3px"
                                                        textAlign="right"
                                                        color="gray.400"
                                                        fontStyle="italic"
                                                    >
                                                        Click to learn more
                                                    </Text>
                                                </Flex>
                                            ) : (
                                                <Flex
                                                    direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    py="3px"
                                                    width="100%"
                                                >
                                                    <Text
                                                        width="100%"
                                                        textAlign="center"
                                                    >
                                                        MCT and ECT times
                                                        interpolated from the
                                                    </Text>
                                                    <Text
                                                        width="100%"
                                                        textAlign="center"
                                                        fontWeight="700"
                                                    >
                                                        AIP Gen 2.7 – Daylight
                                                        Tables
                                                    </Text>
                                                    <Text
                                                        width="100%"
                                                        pt="5px"
                                                        pr="3px"
                                                        textAlign="right"
                                                        color="gray.400"
                                                        fontStyle="italic"
                                                    >
                                                        Click to learn more
                                                    </Text>
                                                </Flex>
                                            )
                                        }
                                        hasArrow
                                        borderRadius="7px"
                                        minHeight="26px"
                                        width={
                                            usingUsnoData ? '290px' : '250px'
                                        }
                                        fontSize="0.7rem"
                                        alignItems="center"
                                        placement="left"
                                        m="5px"
                                        mb="6px"
                                        color="light.100"
                                        bg="light.10"
                                    >
                                        <Flex
                                            opacity={night ? '0.4' : '0.6'}
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                            fontWeight="300"
                                            height="44px"
                                            maxHeight="44px"
                                            width="44px"
                                            borderRadius="10px"
                                            mr={{ base: '-7px', md: '-10px' }}
                                            mb={{ base: '0px', md: '5px' }}
                                            _hover={
                                                showTwighlightTimes && {
                                                    opacity: '1',
                                                }
                                            }
                                            transition="opacity ease 250ms, font-weight ease 50ms"
                                            cursor="pointer"
                                            onClick={() => {
                                                setMctEctDisclaimerModal(true)
                                            }}
                                            color={
                                                metarActive &&
                                                !aerodromeBackgroundsDisabled
                                                    ? cloudCoverColor
                                                    : 'gray.500'
                                            }
                                        >
                                            {!showTwighlightTimes ? (
                                                <Spinner
                                                    color={'#ffffff'}
                                                    boxSize="18px"
                                                    opacity={
                                                        night ? '0.5' : '0.5'
                                                    }
                                                    mt="2px"
                                                    mr="2px"
                                                />
                                            ) : (
                                                <>
                                                    <CautionIcon
                                                        opacity="inherit"
                                                        width="20px"
                                                        height="20px"
                                                    />
                                                    <Flex
                                                        opacity={
                                                            showTwighlightTimes
                                                                ? night
                                                                    ? '0.7'
                                                                    : '1'
                                                                : '0'
                                                        }
                                                        visibility={
                                                            showTwighlightTimes
                                                                ? 'visible'
                                                                : 'hidden'
                                                        }
                                                        transition="all ease 250ms, font-weight ease 50ms"
                                                        width="100%"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        flexDirection="column"
                                                        fontSize="0.52rem"
                                                        textAlign="center"
                                                        whiteSpace="pre-line"
                                                        textShadow={
                                                            metarActive
                                                                ? '1px 2px 5px rgba(0,0,0,0.06)'
                                                                : 'none'
                                                        }
                                                    >
                                                        {usingUsnoData ? (
                                                            <Text>USNO</Text>
                                                        ) : (
                                                            <>
                                                                <Text>
                                                                    Daylight
                                                                </Text>
                                                                <Text mt="-1px">
                                                                    Tables
                                                                </Text>
                                                            </>
                                                        )}
                                                    </Flex>
                                                </>
                                            )}
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                                <Flex
                                    direction={{
                                        base: 'row',
                                        md: 'row',
                                    }}
                                    alignItems="flex-end"
                                    justifyContent={{
                                        base: 'space-around',
                                        md: 'space-between',
                                    }}
                                    mt={{
                                        base: '-50px',
                                        md: '-50px',
                                    }}
                                    mb="0px"
                                    pb={{ base: '10px', md: '5px' }}
                                    px={{
                                        base: '10px',
                                        md: '5px',
                                    }}
                                    minHeight={{
                                        base: '100px',
                                        md: '130px',
                                    }}
                                    width={metarActive ? '100%' : 'auto'}
                                >
                                    <Flex
                                        px={{
                                            base: '5px',
                                            md: '10px',
                                        }}
                                        direction="column"
                                        alignItems="center"
                                        // width="100%"
                                    >
                                        <SunriseIcon />
                                        {!showTwighlightTimes ? (
                                            <Skeleton
                                                startColor="#F7F8F9"
                                                endColor="#abbecc"
                                                borderRadius="20px"
                                                height="16px"
                                                mx="4px"
                                                mt="-2px"
                                                mb="4px"
                                                width={
                                                    timeZone === 'UTC'
                                                        ? '80px'
                                                        : '88px'
                                                }
                                                opacity={
                                                    night ? '0.07' : '0.15'
                                                }
                                            />
                                        ) : (
                                            <Text
                                                opacity={
                                                    showTwighlightTimes
                                                        ? '1'
                                                        : '0'
                                                }
                                                visibility={
                                                    showTwighlightTimes
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                                transition="all ease 250ms"
                                                mt="-6px"
                                                px="5px"
                                                color={
                                                    metarActive &&
                                                    !aerodromeBackgroundsDisabled
                                                        ? cloudCoverColor
                                                        : 'light.100'
                                                }
                                                fontSize="1rem"
                                                textAlign="center"
                                                fontWeight="600"
                                                whiteSpace="pre-line"
                                                textShadow={
                                                    metarActive
                                                        ? '1px 2px 5px rgba(0,0,0,0.06)'
                                                        : 'none'
                                                }
                                                minWidth={
                                                    timeZone === 'UTC'
                                                        ? '88px'
                                                        : '96px'
                                                }
                                            >
                                                {MCT &&
                                                    formatTimeAndTimezone(
                                                        formatISO(MCT),
                                                        timeZone
                                                    )}
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        mt={{
                                            base: '2px',
                                            md: '2px',
                                        }}
                                        mb={{
                                            base: '-10px',
                                            md: '25px',
                                        }}
                                        direction="column"
                                        alignItems="center"
                                        width={{
                                            base: '25%',
                                            sm: '10%',
                                            md: '10%',
                                        }}
                                    >
                                        <MctEct
                                            mctvar={mctvar}
                                            ectvar={ectvar}
                                            nowvar={nowvar}
                                            timeZone={timeZone}
                                            night={night}
                                            aerodromeBackgroundsDisabled={
                                                aerodromeBackgroundsDisabled
                                            }
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        alignItems="center"
                                        px={{
                                            base: '5px',
                                            md: '10px',
                                        }}
                                    >
                                        <SunsetIcon
                                            style={{
                                                minWidth: '24px',
                                            }}
                                        />
                                        {!showTwighlightTimes ? (
                                            <Skeleton
                                                startColor="#F7F8F9"
                                                endColor="#abbecc"
                                                borderRadius="20px"
                                                height="16px"
                                                mx="4px"
                                                mt="-2px"
                                                mb="4px"
                                                width={
                                                    timeZone === 'UTC'
                                                        ? '80px'
                                                        : '88px'
                                                }
                                                opacity={
                                                    night ? '0.07' : '0.15'
                                                }
                                            />
                                        ) : (
                                            <Text
                                                opacity={
                                                    showTwighlightTimes
                                                        ? '1'
                                                        : '0'
                                                }
                                                visibility={
                                                    showTwighlightTimes
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                                transition="all ease 250ms"
                                                px="5px"
                                                mt="-6px"
                                                color={
                                                    metarActive &&
                                                    !aerodromeBackgroundsDisabled
                                                        ? cloudCoverColor
                                                        : 'light.100'
                                                }
                                                fontSize="1rem"
                                                textAlign="center"
                                                fontWeight="600"
                                                whiteSpace="pre-line"
                                                textShadow={
                                                    metarActive
                                                        ? '1px 2px 5px rgba(0,0,0,0.06)'
                                                        : 'none'
                                                }
                                                minWidth={
                                                    timeZone === 'UTC'
                                                        ? '88px'
                                                        : '96px'
                                                }
                                            >
                                                {ECT &&
                                                    formatTimeAndTimezone(
                                                        formatISO(ECT),
                                                        timeZone
                                                    )}
                                            </Text>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                        <Box
                            mt={{
                                base: '0px',
                                md: !infotabGraphicsDisabled && '10px',
                            }}
                            width="100%"
                            ref={desktop}
                        ></Box>
                    </Flex>
                    <Box
                        mt={{
                            base: '0px',
                            md: overflow ? '15px' : '0px',
                        }}
                        width="100%"
                        ref={footerDesktop}
                    ></Box>
                </Flex>
                {data &&
                    data.metar &&
                    data.metar[0].data &&
                    latestMetar &&
                    !latestMetar.nonereceived &&
                    latestMetar.observation[0] && (
                        <Flex
                            px={{
                                base: '5px',
                                md: '0px',
                            }}
                            flexDirection="column"
                            width="100%"
                            height="100%"
                            maxWidth={{ base: '100%', md: '350px' }}
                        >
                            <Flex
                                flexDirection="column"
                                width="100%"
                                height="100%"
                                justifyContent={
                                    infotabGraphicsDisabled
                                        ? 'flex-start'
                                        : 'space-between'
                                }
                                background={
                                    infotabGraphicsDisabled &&
                                    (metarActive &&
                                    !aerodromeBackgroundsDisabled
                                        ? cloudCoverBoxColor(
                                              timePeriod === 'Night'
                                                  ? 'Night'
                                                  : timePeriod === 'Sunrise'
                                                  ? 'Sunrise'
                                                  : timePeriod === 'Sunset'
                                                  ? 'Sunset'
                                                  : metarCloud
                                                  ? metarCloud
                                                  : 'white'
                                          )
                                        : 'white')
                                }
                                boxShadow={
                                    infotabGraphicsDisabled &&
                                    aerodromeBackgroundsDisabled
                                        ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                                        : 'none'
                                }
                                filter={
                                    infotabGraphicsDisabled &&
                                    !aerodromeBackgroundsDisabled
                                        ? 'contrast(102%) saturate(102%)'
                                        : 'none'
                                }
                                backdropFilter={
                                    infotabGraphicsDisabled &&
                                    !aerodromeBackgroundsDisabled
                                        ? 'blur(25px) contrast(102%) saturate(102%)'
                                        : 'none'
                                }
                                borderRadius="20px"
                            >
                                <Flex
                                    borderTopRadius="20px"
                                    borderBottomRadius={
                                        metarOutOfDateIssueTime !== 'NONE' &&
                                        !infotabGraphicsDisabled
                                            ? '20px'
                                            : '0px'
                                    }
                                    pt={
                                        infotabGraphicsDisabled
                                            ? '20px'
                                            : metarOutOfDateIssueTime !==
                                                  'NONE' &&
                                              !aerodromeBackgroundsDisabled
                                            ? '15px'
                                            : '0px'
                                    }
                                    pb={{
                                        base: '15px',
                                        md: infotabGraphicsDisabled
                                            ? '15px'
                                            : '0px',
                                    }}
                                    mb={{
                                        base:
                                            metarOutOfDateIssueTime !==
                                                'NONE' &&
                                            !aerodromeBackgroundsDisabled &&
                                            !infotabGraphicsDisabled
                                                ? '20px'
                                                : '0px',
                                        md: '0px',
                                    }}
                                    px={
                                        infotabGraphicsDisabled ? '15px' : '1px'
                                    }
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection={{
                                        base: 'row',
                                        md: 'row',
                                    }}
                                    gap="4px"
                                    display={{
                                        base: 'flex',
                                        md: !infotabGraphicsDisabled && 'none',
                                    }}
                                    fontSize={
                                        metarOutOfDateIssueTime !== 'NONE'
                                            ? '0.75rem'
                                            : {
                                                  base: '0.65rem',
                                                  xs: '0.7rem',
                                                  smd: '0.7rem',
                                                  md: '0.65rem',
                                              }
                                    }
                                    background={{
                                        base:
                                            metarOutOfDateIssueTime !==
                                                'NONE' &&
                                            !aerodromeBackgroundsDisabled &&
                                            !infotabGraphicsDisabled
                                                ? 'rgba(255,255,255,0.2)'
                                                : 'none',
                                        md: 'none',
                                    }}
                                    backdropFilter={
                                        metarOutOfDateIssueTime !== 'NONE' &&
                                        !aerodromeBackgroundsDisabled &&
                                        !infotabGraphicsDisabled
                                            ? 'blur(25px) contrast(102%) saturate(102%)'
                                            : 'none'
                                    }
                                    opacity={
                                        metarOutOfDateIssueTime !== 'NONE'
                                            ? '1'
                                            : !infotabGraphicsDisabled && '0.6'
                                    }
                                    letterSpacing="0.8px"
                                    fontWeight={500}
                                >
                                    <Text
                                        pt="0px"
                                        opacity={
                                            infotabGraphicsDisabled && '0.8'
                                        }
                                        color={
                                            metarActive &&
                                            !aerodromeBackgroundsDisabled
                                                ? cloudCoverColor
                                                : 'gray.500'
                                        }
                                        whiteSpace={{
                                            base: 'pre-line',
                                            xs: 'pre',
                                        }}
                                    >
                                        LATEST CONDITIONS
                                    </Text>
                                    <Text
                                        textAlign="center"
                                        opacity={
                                            infotabGraphicsDisabled && '0.8'
                                        }
                                        color={
                                            metarOutOfDateIssueTime ===
                                            'WARNING'
                                                ? 'red.500'
                                                : metarOutOfDateIssueTime !==
                                                  'NONE'
                                                ? '#ffb06b'
                                                : aerodromeBackgroundsDisabled
                                                ? 'gray.500'
                                                : 'light.10'
                                        }
                                        fontWeight={{
                                            base:
                                                metarOutOfDateIssueTime !==
                                                'NONE'
                                                    ? '700'
                                                    : '500',
                                            md:
                                                metarOutOfDateIssueTime !==
                                                'NONE'
                                                    ? '500'
                                                    : '400',
                                        }}
                                        whiteSpace={{
                                            base: 'pre-line',
                                            xs: 'pre',
                                        }}
                                        textTransform="uppercase"
                                    >
                                        ISSUED{' '}
                                        {formatDistanceToNow(
                                            parseISO(latestMetar.issueTime),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </Text>
                                </Flex>
                                <VStack
                                    ref={containerRef}
                                    spacing={
                                        !infotabGraphicsDisabled && {
                                            base: '20px',
                                            md: '10px',
                                        }
                                    }
                                    direction={'column'}
                                    alignItems="start"
                                    px={infotabGraphicsDisabled && '5px'}
                                >
                                    {/* WIND */}
                                    {(windvrb || winddir) && windspeed && (
                                        <AerodromesWind
                                            latestMetar={latestMetar}
                                            metarActive={metarActive}
                                            multipleCloudLayers={
                                                multipleCloudLayers
                                            }
                                            timePeriod={timePeriod}
                                            aerodromeBackgroundsDisabled={
                                                aerodromeBackgroundsDisabled
                                            }
                                            cloudCoverBoxColor={
                                                cloudCoverBoxColor
                                            }
                                            metarCloud={metarCloud}
                                            compassRoseMagVar={
                                                compassRoseMagVar
                                            }
                                            magvar={magvar}
                                            windvrb={windvrb}
                                            winddirDegreesMag={
                                                winddirDegreesMag
                                            }
                                            winddir={winddir}
                                            windspeed={windspeed}
                                            windgust={windgust}
                                            infotabGraphicsDisabled={
                                                infotabGraphicsDisabled
                                            }
                                        />
                                    )}

                                    {/* VISIBILITY */}
                                    {horizontalVisibility && (
                                        <AerodromesVisibility
                                            metarActive={metarActive}
                                            multipleCloudLayers={
                                                multipleCloudLayers
                                            }
                                            timePeriod={timePeriod}
                                            aerodromeBackgroundsDisabled={
                                                aerodromeBackgroundsDisabled
                                            }
                                            cloudCoverBoxColor={
                                                cloudCoverBoxColor
                                            }
                                            metarCloud={metarCloud}
                                            cloudCoverColor={cloudCoverColor}
                                            horizontalVisibility={
                                                horizontalVisibility
                                            }
                                            infotabGraphicsDisabled={
                                                infotabGraphicsDisabled
                                            }
                                        />
                                    )}

                                    {/* CLOUD */}
                                    {((cloud &&
                                        (noCloudDetected ||
                                            cloudAndVisibilityOK ||
                                            cloudlayers)) ||
                                        verticalVisibility) && (
                                        <AerodromesCloud
                                            metarActive={metarActive}
                                            timePeriod={timePeriod}
                                            aerodromeBackgroundsDisabled={
                                                aerodromeBackgroundsDisabled
                                            }
                                            cloudCoverBoxColor={
                                                cloudCoverBoxColor
                                            }
                                            metarCloud={metarCloud}
                                            noCloudDetected={noCloudDetected}
                                            cloudAndVisibilityOK={
                                                cloudAndVisibilityOK
                                            }
                                            verticalVisibility={
                                                verticalVisibility
                                            }
                                            reversedCloudLayers={
                                                reversedCloudLayers
                                            }
                                            infotabGraphicsDisabled={
                                                infotabGraphicsDisabled
                                            }
                                        />
                                    )}

                                    {/* TEMPERATURE AND DEWPOINT */}
                                    <AerodromesTempAndDewpoint
                                        metarActive={metarActive}
                                        timePeriod={timePeriod}
                                        aerodromeBackgroundsDisabled={
                                            aerodromeBackgroundsDisabled
                                        }
                                        cloudCoverBoxColor={cloudCoverBoxColor}
                                        metarCloud={metarCloud}
                                        temp={temp}
                                        dewpoint={dewpoint}
                                        infotabGraphicsDisabled={
                                            infotabGraphicsDisabled
                                        }
                                    />

                                    {/* QNH */}
                                    <AerodromesQnh
                                        metarActive={metarActive}
                                        timePeriod={timePeriod}
                                        aerodromeBackgroundsDisabled={
                                            aerodromeBackgroundsDisabled
                                        }
                                        cloudCoverBoxColor={cloudCoverBoxColor}
                                        metarCloud={metarCloud}
                                        qnh={qnh}
                                        presentWeather={presentWeather}
                                        infotabGraphicsDisabled={
                                            infotabGraphicsDisabled
                                        }
                                    />

                                    {/* PRESENT WEATHER */}
                                    {presentWeather &&
                                        presentWeather.filter(
                                            (feat) => !feat.startsWith('/')
                                        ) && (
                                            <>
                                                <Portal
                                                    {...presentWeatherPlacement}
                                                >
                                                    <AerodromesPresentWeather
                                                        metarActive={
                                                            metarActive
                                                        }
                                                        timePeriod={timePeriod}
                                                        aerodromeBackgroundsDisabled={
                                                            aerodromeBackgroundsDisabled
                                                        }
                                                        cloudCoverBoxColor={
                                                            cloudCoverBoxColor
                                                        }
                                                        metarCloud={metarCloud}
                                                        presentWeather={
                                                            presentWeather
                                                        }
                                                        infotabGraphicsDisabled={
                                                            infotabGraphicsDisabled
                                                        }
                                                        fog={fog}
                                                        precipitationPresent={
                                                            precipitationPresent
                                                        }
                                                    />
                                                </Portal>
                                            </>
                                        )}
                                </VStack>
                                <Box
                                    px={infotabGraphicsDisabled && '5px'}
                                    mt={{
                                        base:
                                            !presentWeather ||
                                            infotabGraphicsDisabled
                                                ? '0px'
                                                : '20px',
                                        md:
                                            infotabGraphicsDisabled || overflow
                                                ? '0px'
                                                : '10px',
                                    }}
                                    width="100%"
                                    ref={mobile}
                                ></Box>
                            </Flex>
                        </Flex>
                    )}
            </Flex>
            <Portal {...footerPlacement}>
                <Flex
                    opacity={showFooter ? '1' : '0'}
                    transition="opacity ease-in 250ms"
                    pt={{
                        base: infotabGraphicsDisabled ? '10px' : '20px',
                        md: metarOutOfDateIssueTime !== 'NONE' ? '9px' : '0px',
                    }}
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection={{
                        base: 'row',
                        md:
                            metarOutOfDateIssueTime === 'NONE' && overflow
                                ? 'column'
                                : 'row',
                    }}
                    background={{
                        base: 'none',
                        md:
                            metarOutOfDateIssueTime !== 'NONE' &&
                            timePeriod === 'Night'
                                ? 'rgba(255,255,255,0.15)'
                                : metarOutOfDateIssueTime !== 'NONE' &&
                                  timePeriod !== 'Night'
                                ? 'rgba(255,255,255,0.4)'
                                : 'none',
                    }}
                    backdropFilter={{
                        base: 'none',
                        md:
                            metarOutOfDateIssueTime !== 'NONE'
                                ? 'blur(25px) contrast(102%) saturate(102%)'
                                : 'none',
                    }}
                    borderRadius="20px"
                    gap="4px"
                    pb={{
                        base: '20px',
                        md: metarOutOfDateIssueTime !== 'NONE' ? '10px' : '0px',
                    }}
                >
                    <Text
                        textAlign="center"
                        color={{
                            base: aerodromeBackgroundsDisabled
                                ? 'gray.400'
                                : latestMetar && timePeriod === 'Night'
                                ? 'rgba(255,255,255,0.5)'
                                : cloudCoverColorDetail,
                            md: aerodromeBackgroundsDisabled
                                ? 'gray.400'
                                : latestMetar && timePeriod === 'Night'
                                ? 'rgba(255,255,255,0.5)'
                                : cloudCoverColorDetail,
                        }}
                        fontSize={
                            metarOutOfDateIssueTime !== 'NONE'
                                ? '0.8rem'
                                : {
                                      base: '0.7rem',
                                      xs: '0.75rem',
                                      smd: '0.7rem',
                                      md: '0.75rem',
                                  }
                        }
                        lineHeight="1.4"
                        mb="-2px"
                        fontWeight={{
                            base: '400',
                            md:
                                metarOutOfDateIssueTime !== 'NONE'
                                    ? '500'
                                    : '400',
                        }}
                        whiteSpace="pre-line"
                    >
                        Weather information from latest METAR
                    </Text>
                    {!infotabGraphicsDisabled && (
                        <Text
                            display={{ base: 'none', md: 'block' }}
                            textAlign="center"
                            color={
                                metarOutOfDateIssueTime === 'WARNING'
                                    ? 'red.500'
                                    : metarOutOfDateIssueTime !== 'NONE'
                                    ? '#ffb06b'
                                    : aerodromeBackgroundsDisabled
                                    ? 'gray.400'
                                    : latestMetar && timePeriod === 'Night'
                                    ? 'rgba(255,255,255,0.5)'
                                    : cloudCoverColorDetail
                            }
                            fontSize={
                                metarOutOfDateIssueTime !== 'NONE'
                                    ? '0.8rem'
                                    : {
                                          base: '0.7rem',
                                          xs: '0.75rem',
                                          smd: '0.7rem',
                                          md: '0.75rem',
                                      }
                            }
                            lineHeight="1.4"
                            mb="-2px"
                            fontWeight={{
                                base:
                                    metarOutOfDateIssueTime !== 'NONE'
                                        ? '400'
                                        : '300',
                                md:
                                    metarOutOfDateIssueTime !== 'NONE'
                                        ? '600'
                                        : '400',
                            }}
                            whiteSpace="pre-line"
                        >
                            issued{' '}
                            {formatDistanceToNow(
                                parseISO(latestMetar.issueTime),
                                {
                                    addSuffix: true,
                                }
                            )}
                        </Text>
                    )}
                </Flex>
            </Portal>
            <Box
                mt={{
                    base: '10px',
                    md:
                        metarOutOfDateIssueTime !== 'NONE'
                            ? '5px'
                            : overflow
                            ? '0px'
                            : '10px',
                }}
                width="100%"
                ref={footerMobile}
            ></Box>
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import {
    Input,
    InputGroup,
    InputRightElement,
    Flex,
    Text,
    Icon,
    Button,
    Divider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    Spinner,
    LightMode,
} from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { AiOutlineSearch } from 'react-icons/ai'

import { useTier, useAdminRole } from '../../../auth/hooks'
import returnUsableLayers from '../../../config/utils/returnUsableLayers'
import returnLayersFromQuery from '../../../config/utils/returnLayersFromQuery'
import returnModesFromQuery from '../../../config/utils/returnModesFromQuery'
import isValidMode from '../../../config/utils/isValidMode'
import isValidLayer from '../../../config/utils/isValidLayer'
import returnLayerLabel from '../../../config/utils/returnLayerLabel'

import useDebounce from './util/useDebounce'

import {
    contrastState,
    clickedFeaturesState,
    dataModeState,
    layerSelectionSearchHandler,
    searchFocusState,
    mfaVerifiedState,
    simulateFreeUserState,
    fullscreenModalVisibleState,
    mapDisabledState,
    wipMenuItemsState,
} from '../../../globalState'

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import './search.css'

import { ReactComponent as ADIFR } from './icons/aerodromeifr.svg'
import { ReactComponent as ADVFR } from './icons/ad-vfr.svg'
import { ReactComponent as HPIFR } from './icons/hp-ifr.svg'
import { ReactComponent as HPVFR } from './icons/hp-vfr.svg'
import { ReactComponent as WD } from './icons/ad-water.svg'
import { ReactComponent as HPNONPUB } from './icons/hp-nonpub.svg'
import { ReactComponent as ADNONPUB } from './icons/ad-nonpub.svg'
import { ReactComponent as POIIcon } from './icons/POI.svg'
import { ReactComponent as LayerIcon } from './icons/layer.svg'
import { ReactComponent as NDBIcon } from '../../icons/aero/ndb.svg'
import { ReactComponent as NDBDMEIcon } from '../../icons/aero/ndbdme.svg'
import { ReactComponent as DMEIcon } from '../../icons/aero/dme.svg'
import { ReactComponent as VORIcon } from '../../icons/aero/vor.svg'
import { ReactComponent as VORDMEIcon } from '../../icons/aero/vordme.svg'
import { ReactComponent as Camera } from '../../icons/met/camera.svg'
import { ReactComponent as AWSIcon } from '../../icons/met/aws.svg'
import { ReactComponent as CloseIcon } from '../../../icons/assets/close.svg'
import PremiumBadge from '../PremiumBadge'
import AdvsIcon from '../../../icons/shaded-icons/AdvsIcon'
import AeroIcon from '../../../icons/shaded-icons/AeroIcon'
import MetIcon from '../../../icons/shaded-icons/MetIcon'

import { ReactComponent as NavAidIcon } from '../../../icons/assets/navaid.svg'
import WaypointIcaoIcon from '../../../icons/shaded-icons/WaypointIcaoIcon'
import { ReactComponent as VisualReportingPointIcon } from './icons/vrp.svg'

function useSearch({ search, coords }) {
    const { getAccessTokenSilently } = useAuth0()
    const { lng, lat } = coords
    const fetchSearchData = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                searchQuery: search,
                lat: lat,
                long: lng,
            }),
        })
        return res.json()
    }

    // see https://react-query.tanstack.com/guides/important-defaults
    return useQuery(
        ['searchData', { search }],
        async () => {
            const data = await fetchSearchData()
            return data
        },
        {
            refetchOnWindowFocus: false,
        },
        {
            enabled: false,
        }
    )
}

export default function Search() {
    const userTier = useTier()
    const [query, setQuery] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearch = useDebounce(searchQuery, 500)
    const [layerData, setLayerData] = useState(null)
    const [queryLayers, setQueryLayers] = useState([])
    const [queryModes, setQueryModes] = useState([])
    const setClickedFeatures = useSetRecoilState(clickedFeaturesState)
    const setMode = useSetRecoilState(dataModeState)
    const layerHandler = useSetRecoilState(layerSelectionSearchHandler)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [expandSearch, setExpandSearch] = useState(false) // this wont work as intended if in globalstate for some reason, so searchFocus included aswell
    const setSearchFocus = useSetRecoilState(searchFocusState)
    const contrastHandler = useRecoilValue(contrastState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const fullscreenModalVisible = useRecoilValue(fullscreenModalVisibleState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const wipMenuItems = useRecoilValue(wipMenuItemsState)
    const isAdmin = useAdminRole()
    const showWIP = wipMenuItems && isAdmin
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    const { data: searchData, status: searchStatus } =
        useSearch(debouncedSearch)

    useEffect(() => {
        if (query.length >= 2) {
            setQueryModes(returnModesFromQuery(query))
            setSearchQuery(query)
        }
    }, [query])

    useEffect(() => {
        if (isOpen) {
            setExpandSearch(true)
            setSearchFocus(true)
        } else {
            setExpandSearch(false)
            setSearchFocus(false)
        }
    }, [isOpen, setExpandSearch, setSearchFocus])

    useEffect(() => {
        if (query.length >= 2 && layerData) {
            setQueryLayers(returnLayersFromQuery(query, layerData))
        } else if (query.length === 0) {
            setQuery('')
            setSearchQuery('')
            setQueryLayers([])
            setQueryModes([])
        }
    }, [query, layerData, isOpen])

    function reset() {
        setQuery('')
        setSearchQuery('')
        setQueryLayers([])
        setQueryModes([])
    }

    useEffect(() => {
        if (fullscreenModalVisible) {
            onClose()
            setExpandSearch(false)
            setSearchFocus(false)
            reset()
        }
    }, [fullscreenModalVisible, onClose, setExpandSearch, setSearchFocus])

    useEffect(() => {
        !layerData &&
            setLayerData(returnUsableLayers(userTier, showWIP, mapDisabled))
    }, [layerData, userTier, showWIP, mapDisabled])

    function modeIcon(itm) {
        if (itm.mode === 'aero') {
            return AeroIcon
        } else if (itm.mode === 'advs') {
            return AdvsIcon
        } else if (itm.mode === 'met') {
            return MetIcon
        }
    }

    const convertType = (type) => {
        switch (type) {
            case 'AD':
                return 'AERODROME'
            case 'HP':
                return 'HELIPORT'
            case 'WD':
                return 'WATERDROME'
            default:
                return 'UNKNOWN'
        }
    }

    function symbol(itm) {
        if (itm.layer === 'aerodromes') {
            return itm.properties.flightrule === 'IFR' &&
                itm.properties.type === 'AD'
                ? ADIFR
                : itm.properties.flightrule === 'IFR' &&
                  itm.properties.type === 'HP'
                ? HPIFR
                : itm.properties.flightrule === 'VFR' &&
                  itm.properties.type === 'AD'
                ? ADVFR
                : itm.properties.flightrule === 'VFR' &&
                  itm.properties.type === 'HP'
                ? HPVFR
                : itm.properties.type === 'WD'
                ? WD
                : itm.properties.type === 'HP'
                ? HPNONPUB
                : ADNONPUB
        } else if (itm.layer === 'navaids') {
            return itm.properties.type === 'NDB'
                ? NDBIcon
                : itm.properties.type === 'NDB_DME'
                ? NDBDMEIcon
                : itm.properties.type === 'DME'
                ? DMEIcon
                : itm.properties.type === 'VOR'
                ? VORIcon
                : itm.properties.type === 'VOR_DME'
                ? VORDMEIcon
                : NavAidIcon
        } else if (itm.layer === 'icaoWaypoints') {
            return WaypointIcaoIcon
        } else if (itm.layer === 'vrp') {
            return VisualReportingPointIcon
        } else if (itm.layer === 'aws') {
            return AWSIcon
        } else if (itm.layer === 'webcams') {
            return Camera
        } else if (itm.type === 'layer') {
            return LayerIcon
        } else if (itm.type === 'mode') {
            return modeIcon(itm)
        } else {
            return POIIcon
        }
    }

    function processString(str) {
        const maxLength = 50
        return str.length > maxLength ? str.slice(0, maxLength) + ' ...' : str
    }

    function handleItemClick(itm) {
        if (itm.type === 'Feature' || itm.layer === 'aerodromes') {
            setClickedFeatures([itm])
        } else if (itm.type === 'mode') {
            setMode(itm.mode)
        } else if (itm.type === 'layer') {
            const { mode, id } = itm
            setMode(mode)
            layerHandler({ mode, layer: id })
        } else if (
            !itm.type &&
            isValidMode(itm.mode) &&
            isValidLayer(itm.layer)
        ) {
            const { mode, layer } = itm
            setMode(mode)
            layerHandler({ mode, layer })
            setClickedFeatures([itm])
        }
        onClose()
    }

    const SearchItem = ({ item }) => {
        const label =
            item.name || item.location || item.place_name || item.label
        const icon = symbol(item)
        return (
            <Flex
                w="100%"
                py="5px"
                pl={{ base: '10px', md: '10px' }}
                pr={{ base: '8px', md: '8px' }}
                onClick={() => {
                    if (
                        (isPremium &&
                            item.layer !== 'aws' &&
                            item.layer !== 'webcams') ||
                        !isPremium
                    ) {
                        handleItemClick(item)
                    }
                }}
                opacity={
                    isPremium &&
                    (item.layer === 'aws' || item.layer === 'webcams')
                        ? '0.4'
                        : '1'
                }
                justifyContent={'space-between'}
                alignItems={'center'}
                borderRadius="5px"
                _hover={{
                    cursor:
                        isPremium &&
                        (item.layer === 'aws' || item.layer === 'webcams')
                            ? 'not-allowed'
                            : 'pointer',
                    backgroundColor: 'gray.100',
                }}
            >
                <Flex alignItems={'center'}>
                    <Text
                        lineHeight={{ base: '1.3', md: '1.4' }}
                        fontSize={{ base: '0.8rem', md: '0.85rem' }}
                    >
                        {processString(
                            item.type === 'layer'
                                ? returnLayerLabel(label)
                                : item.layer === 'aerodromes'
                                ? label +
                                  ' ' +
                                  convertType(item.properties.type)
                                : item.layer === 'navaids'
                                ? label +
                                  ' ' +
                                  item.properties.type.replaceAll('_', '/')
                                : label
                        )}
                    </Text>
                    {item.type === 'layer' && (
                        <Text
                            fontSize={{ base: '0.8rem', md: '0.85rem' }}
                            color="gray.400"
                            pl="5px"
                            mb="-1px"
                            textTransform="uppercase"
                            letterSpacing="0.5px"
                        >
                            ({item.mode})
                        </Text>
                    )}
                </Flex>
                <Icon as={icon} width="20px" height="20px" boxSize={5} />
            </Flex>
        )
    }

    const SearchCollection = ({ label, items }) => {
        return (
            <Flex
                direction="column"
                w="100%"
                px={{ base: '5px', md: '15px' }}
                pt="5px"
                pb="10px"
            >
                <Flex alignItems={'center'} justifyContent="flex-start">
                    <Text
                        fontWeight={'bold'}
                        py={'5px'}
                        pl={{ base: '10px', md: '10px' }}
                        pr={{ base: '8px', md: '8px' }}
                        textTransform="capitalize"
                        fontSize={{ base: '0.8rem', md: '0.85rem' }}
                    >
                        {returnLayerLabel(label) || 'Collection'}
                    </Text>
                    {isPremium && (label === 'aws' || label === 'webcams') && (
                        <PremiumBadge />
                    )}
                </Flex>
                <Divider mb="2px" />
                {items.map((i) => (
                    <SearchItem item={i} />
                ))}
            </Flex>
        )
    }

    const NoItemsFound = () => {
        return (
            <Flex
                w="100%"
                py="12px"
                pl={{ base: '15px', md: '20px' }}
                pr={{ base: '13px', md: '18px' }}
                justifyContent={'space-between'}
                alignItems={'center'}
                borderRadius="5px"
                _hover={{ cursor: 'pointer', backgroundColor: 'gray.100' }}
            >
                <Flex alignItems={'center'}>
                    <Text
                        lineHeight={{ base: '1.3', md: '1.4' }}
                        fontSize={{ base: '0.8rem', md: '0.85rem' }}
                    >
                        No items found
                    </Text>
                </Flex>
            </Flex>
        )
    }

    const results =
        (queryModes && !!queryModes.length) ||
        (queryLayers && !!queryLayers.length) ||
        (query !== '' &&
            !!searchData &&
            !!searchData.mapData &&
            Object.keys(searchData.mapData) &&
            Object.keys(searchData.mapData).length)

    return (
        <>
            {!isOpen && (
                <Button
                    variant="outline"
                    pos={'absolute'}
                    top={'15px'}
                    left={{ base: '70px', md: '65px' }}
                    overflow={'hidden'}
                    borderRadius={'20px'}
                    borderColor={'transparent'}
                    fontSize="15px"
                    fontWeight="400"
                    color="white"
                    textShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
                    className={
                        'standard' // All other layers
                    }
                    boxShadow={'0px 5px 35px -7px rgba(0, 0, 0, 0.06)'}
                    background={
                        contrastHandler === 'high'
                            ? 'rgba(255,255,255,0.35)' // If High Contrast Layer
                            : contrastHandler === 'medium'
                            ? 'rgba(255,255,255,0.25)' // If Medium Contrast Layer
                            : 'rgba(255,255,255,0.18)' // All other layers
                    }
                    backdropFilter="blur(2px)"
                    h="40px"
                    w={{ base: 'calc(100vw - 140px)', md: '120px' }}
                    _hover={{
                        opacity: 1,
                        border: '1px solid',
                        borderColor: 'rgba(255,255,255,0.1)',
                        boxShadow: '0px 5px 35px -7px rgba(0, 0, 0, 0.15)',
                        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
                        background:
                            contrastHandler === 'high'
                                ? 'rgba(255,255,255,0.45)' // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? 'rgba(255,255,255,0.35)' // If Medium Contrast Layer
                                : 'rgba(255,255,255,0.28)', // All other layers
                    }}
                    _focus={{
                        opacity: 1,
                        border: '1px solid',
                        borderColor: 'rgba(255,255,255,0.1)',
                        boxShadow: '0px 5px 35px -7px rgba(0, 0, 0, 0.15)',
                        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
                        background:
                            contrastHandler === 'high'
                                ? 'rgba(255,255,255,0.45)' // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? 'rgba(255,255,255,0.35)' // If Medium Contrast Layer
                                : 'rgba(255,255,255,0.28)', // All other layers
                    }}
                    _active={{
                        opacity: 1,
                        border: '1px solid',
                        borderColor: 'rgba(255,255,255,0.1)',
                        boxShadow: '0px 5px 35px -7px rgba(0, 0, 0, 0.15)',
                        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
                        background:
                            contrastHandler === 'high'
                                ? 'rgba(255,255,255,0.45)' // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? 'rgba(255,255,255,0.35)' // If Medium Contrast Layer
                                : 'rgba(255,255,255,0.28)', // All other layers
                    }}
                    onClick={onOpen}
                >
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Text pl="3px" pr="15px">
                            Search
                        </Text>
                        <AiOutlineSearch fontSize="1.3rem" />
                    </Flex>
                </Button>
            )}
            <LightMode>
                <Modal
                    size="lg"
                    isOpen={isOpen}
                    onClose={onClose}
                    closeOnOverlayClick={true}
                >
                    <ModalOverlay />
                    <ModalContent
                        borderRadius={{ base: '20px', md: '20px' }}
                        borderBottomRadius={
                            results ? '15px !important' : '20px'
                        }
                        ml={{ base: '70px', md: '15px' }}
                        mr={{ base: '70px', md: 'auto' }}
                        p={0}
                        mt={{ base: '15px', md: '15px' }}
                        width={{
                            base: '100%',
                            md: expandSearch ? '512px' : '120px',
                        }}
                        transition="width ease 400ms, opacity ease 400ms"
                    >
                        <ModalBody p={0}>
                            <InputGroup
                                size="sm"
                                color={'light.100'}
                                overflow={'hidden'}
                                fontSize="0.85rem"
                                h="40px"
                                w={'100%'}
                                alignItems="center"
                                boxShadow="0px 0px 15px -1px rgba(0,0,0,0.15)"
                            >
                                <Input
                                    py="2"
                                    px="5"
                                    height="40px"
                                    placeholder="Search"
                                    _focus={{ border: 'none' }}
                                    border={'none'}
                                    background="none"
                                    _hover={{ background: 'light.20' }}
                                    value={query}
                                    _placeholder={{
                                        opacity: 1,
                                        color: 'gray.300',
                                    }}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                                <InputRightElement
                                    fontSize="1.3rem"
                                    opacity={
                                        searchStatus === 'loading' ? '1' : '0.5'
                                    }
                                    children={
                                        searchStatus === 'loading' ? (
                                            <Spinner
                                                width="1.2rem"
                                                height="1.2rem"
                                                color="light.200"
                                            />
                                        ) : results ? (
                                            <CloseIcon />
                                        ) : (
                                            <AiOutlineSearch />
                                        )
                                    }
                                    onClick={() => {
                                        if (isOpen) {
                                            reset()
                                        }
                                    }}
                                    top="5px"
                                    right={{ base: '10px', md: '5px' }}
                                    _hover={{
                                        fontSize: '1.6rem',
                                        cursor: 'pointer',
                                        opacity: '1',
                                    }}
                                    transition="all ease 200ms"
                                />
                            </InputGroup>
                            <Flex
                                borderTop="1px solid"
                                borderColor="#F1F4F7"
                                className="results"
                                direction={'column'}
                                w="100%"
                                maxH={{ base: '45vh', md: '50vh' }}
                                overflow="scroll"
                                overflowX="hidden"
                                transition={'height .3s'}
                            >
                                {queryModes && !!queryModes.length && (
                                    <SearchCollection
                                        label={'Modes'}
                                        items={queryModes}
                                    />
                                )}
                                {queryLayers && !!queryLayers.length && (
                                    <SearchCollection
                                        label={'Layers'}
                                        items={queryLayers}
                                    />
                                )}
                                {query !== '' &&
                                    !!searchData &&
                                    !!searchData?.mapData &&
                                    Object.keys(searchData.mapData).map((d) => (
                                        <SearchCollection
                                            label={d}
                                            items={searchData.mapData[d]}
                                        />
                                    ))}
                                {query.length > 1 &&
                                    searchData &&
                                    !Object.keys(searchData.mapData).length && (
                                        <NoItemsFound />
                                    )}
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}

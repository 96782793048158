import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import useUserRoles, { useTier, useUserMetaData } from '../../../auth/hooks'
import {
    Box,
    Modal,
    Flex,
    Text,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalHeader,
    ModalFooter,
    useDisclosure,
    LightMode,
    useBreakpointValue,
    Tabs,
    TabPanels,
    TabPanel,
    Portal,
    Spinner,
    Badge,
    Tab,
    TabList,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    Divider,
    Icon,
} from '@chakra-ui/react'
import { formatISO } from 'date-fns'
import { formatDateAndTimezone } from '../../../util/dateFormatter'
import {
    setModalVisibilityState,
    userProfileState,
    simulateFreeUserState,
    timeZoneState,
    decodedTextDefaultState,
    selectDefaultMapLocationState,
    mfaHandlerState,
    mfaVerifiedState,
    isBriefingOnlyState,
    mapDisabledState,
    mapDisabledUpdatedState,
} from '../../../globalState'
import useConfirm from '../alerts/warning-message/components/useConfirm'
import { ManageSubscriptionMessage } from '../alerts/warning-message/components/WarningMessages'
import { MfaPreviouslyVerified, MfaActive } from '../../functions/MfaHandler'
import { ReactComponent as ProfileIcon } from '../../../icons/assets/profile.svg'
import { ReactComponent as ProfileButtonIcon } from '../../../icons/assets/profile-button.svg'
import { ReactComponent as SettingsIcon } from '../../../icons/assets/settings.svg'
import { ReactComponent as LogOutIcon } from '../../../icons/assets/log-out.svg'
import { ReactComponent as SubscriptionIcon } from '../../../icons/assets/subscription.svg'
import { ReactComponent as Question } from '../../../icons/assets/question.svg'
import { RiStarLine } from 'react-icons/ri'
import ProfileTab from './components/Profile'
import SettingsTab from './components/Settings'
import SetMapFavourite from './components/SetMapFavourite'
// import { ReactComponent as FavouriteIcon } from '../../../icons/assets/favourite.svg'

export default function ProfileModal() {
    const timeZone = useRecoilValue(timeZoneState)
    const { getAccessTokenSilently, logout } = useAuth0()
    const userMetaData = useUserMetaData()
    const [profileData, setProfileData] = useRecoilState(userProfileState)
    const [subscriptionData, setSubscriptionData] = useState(null)
    const [savedBadge, setSavedBadge] = useState(false)
    const [selectDefaultMapLocation, setSelectDefaultMapLocation] =
        useRecoilState(selectDefaultMapLocationState)
    const [isBriefingOnly, setIsBriefingOnly] =
        useRecoilState(isBriefingOnlyState)
    const [mapDisabled, setMapDisabled] = useRecoilState(mapDisabledState)
    const [mapDisabledUpdated, setMapDisabledUpdated] = useRecoilState(
        mapDisabledUpdatedState
    )
    const mfaState = useRecoilValue(mfaHandlerState)
    const userRoles = useUserRoles()
    const userTier = useTier()
    const { isConfirmed } = useConfirm() // alert message handler
    const advancedUser = userRoles.some((role) =>
        [
            'Admin',
            'Dev',
            'Aeropath',
            'MetService Aviation',
            'MetService Service Desk',
            'MetService',
            'BetaTester',
        ].includes(role)
    )
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const plusSubscriptionOnly = Boolean(userTier === 2)
    const mfaExpired = userTier > 1 && !mfaVerified
    const [updatingAuth0, setUpdatingAuth0] = useState(false)
    const [settingsTab, setSettingsTab] = useState(false)
    const previouslyVerified = MfaPreviouslyVerified()
    const activeMfa = MfaActive()

    const premiumMfaBypassed = userTier > 1 && mfaState?.state === 'BYPASSED'
    const disableSettings = (!mfaVerified || !activeMfa) && !premiumMfaBypassed

    const infoButtonSize = useBreakpointValue({
        base: '1.4rem',
        md: '1.3rem',
    })
    const popoverPlacement = useBreakpointValue({
        base: 'top',
        xs: 'top-end',
    })
    const initialFocusRef = useRef()

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'profile', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const { onClose } = useDisclosure()

    function onCloseHandler() {
        setMapDisabledUpdated(false)
        setShow(false)
        onClose()
        setSettingsTab(false)
    }

    const [tabIndex, setTabIndex] = useState(
        mapDisabledUpdated || settingsTab ? 1 : 0
    )

    // Subscription Information
    const getSubscription = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()

        fetch(`${window.location.origin}/getsubscription`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return null
                }
            })
            .then((data) => setSubscriptionData(data))
    }, [setSubscriptionData, getAccessTokenSilently])

    // get subscription data on load (PLUS plans only)
    useEffect(() => {
        if (userTier === 2) {
            getSubscription()
        }
    }, [userTier, getSubscription])

    const mounted = useRef(false)
    const startTimer = useRef(null)
    const endTimer = useRef(null)

    const updateAuth0Profile = useCallback(
        async (profileData) => {
            const accessToken = await getAccessTokenSilently()
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    user_metadata: {
                        name: `${
                            profileData.firstName !== ''
                                ? profileData.firstName
                                : null
                        } ${
                            profileData.lastName !== ''
                                ? profileData.lastName
                                : null
                        }`,
                        given_name:
                            profileData.firstName !== ''
                                ? profileData.firstName
                                : null,
                        family_name:
                            profileData.lastName !== ''
                                ? profileData.lastName
                                : null,
                        caa_id:
                            profileData.caaId !== '' ? profileData.caaId : null,
                        organization:
                            profileData.organization !== ''
                                ? profileData.organization
                                : null,
                        profile_settings: {
                            disable_map:
                                profileData?.profileSettings &&
                                profileData?.profileSettings?.disable_map !== ''
                                    ? profileData?.profileSettings?.disable_map
                                    : false,
                            disable_mapzoom:
                                profileData?.profileSettings &&
                                profileData?.profileSettings
                                    ?.disable_mapzoom !== ''
                                    ? profileData?.profileSettings
                                          .disable_mapzoom
                                    : false,
                            disable_mapzoom_drawer:
                                profileData?.profileSettings &&
                                profileData?.profileSettings
                                    ?.disable_mapzoom_drawer !== ''
                                    ? profileData?.profileSettings
                                          .disable_mapzoom_drawer
                                    : false,
                            disable_infotab_backgrounds:
                                profileData?.profileSettings &&
                                profileData?.profileSettings
                                    ?.disable_infotab_backgrounds !== ''
                                    ? profileData?.profileSettings
                                          .disable_infotab_backgrounds
                                    : false,
                            disable_infotab_graphics:
                                profileData?.profileSettings &&
                                profileData?.profileSettings
                                    ?.disable_infotab_graphics !== ''
                                    ? profileData?.profileSettings
                                          .disable_infotab_graphics
                                    : false,
                            decode_default:
                                profileData?.profileSettings &&
                                profileData?.profileSettings?.decode_default !==
                                    ''
                                    ? profileData?.profileSettings
                                          ?.decode_default
                                    : false,
                            brief_mode_only:
                                profileData?.profileSettings &&
                                profileData?.profileSettings
                                    ?.brief_mode_only !== ''
                                    ? profileData?.profileSettings
                                          .brief_mode_only
                                    : false,
                            mapcenter:
                                profileData?.profileSettings &&
                                profileData?.profileSettings?.mapcenter !== ''
                                    ? profileData?.profileSettings?.mapcenter
                                    : null,
                            mapzoom:
                                profileData?.profileSettings &&
                                profileData?.profileSettings?.mapzoom !== ''
                                    ? profileData?.profileSettings?.mapzoom
                                    : null,
                            mapzoom_mobile:
                                profileData?.profileSettings &&
                                profileData?.profileSettings?.mapzoom_mobile !==
                                    ''
                                    ? profileData?.profileSettings
                                          ?.mapzoom_mobile
                                    : null,
                        },
                    },
                }),
            }

            fetch(
                `${window.location.origin}/user/updateuserdata`,
                requestOptions
            ).then((res) => {
                if (!res.ok) {
                    console.log(res)
                    console.log('Error updating Auth0 Metadata')
                }
                console.log('Updating Auth0')
                setUpdatingAuth0(false)
                startTimer.current = setTimeout(() => {
                    setSavedBadge(true)
                }, 0)
                endTimer.current = setTimeout(() => {
                    setSavedBadge(false)
                }, 5000)
                return
            })
        },
        [getAccessTokenSilently, setUpdatingAuth0, setSavedBadge]
    )

    const previousProfileData = useRef(profileData)
    function inputsUpdated(newProfileData) {
        // Compare firstName, lastName, caaId or organisation input fields with the previous state
        return (
            newProfileData.firstName !==
                previousProfileData.current.firstName ||
            newProfileData.lastName !== previousProfileData.current.lastName ||
            newProfileData.caaId !== previousProfileData.current.caaId ||
            newProfileData.organization !==
                previousProfileData.current.organization
        )
    }

    // Function to fire the updateAuth0Profile callback (triggered if profileData has changed)
    useEffect(() => {
        if (mounted.current) {
            // If the input fields have changed, then set the timeout function (so it doesn't immediately fire the updateAuth0Profile after every key stroke)
            if (inputsUpdated(profileData)) {
                setUpdatingAuth0(true)
                const timeout = setTimeout(
                    () => updateAuth0Profile(profileData),
                    2500
                )
                return () => clearTimeout(timeout)
            } else {
                // Otherwise, if no input changes, but there are toggle changes (in Settings), then updateAuth0Profile immediately
                updateAuth0Profile(profileData)
            }
        } else {
            mounted.current = true
        }
        // Update the previousProfileData ref whenever profileData changes
        previousProfileData.current = profileData
    }, [setUpdatingAuth0, profileData, updateAuth0Profile])

    function onResetHandler() {
        setSelectDefaultMapLocation(false)
        setProfileData((prevState) => ({
            ...prevState,
            profileSettings: {
                ...prevState.profileSettings,
                mapcenter: null,
                mapzoom: null,
                mapzoom_mobile: null,
            },
        }))
    }

    const enableDecodeDefault = isPremium
        ? false
        : (profileData?.profileSettings?.decode_default !== undefined
              ? profileData?.profileSettings?.decode_default
              : userMetaData?.profile_settings?.decode_default) || false

    const setDecodedTextDefault = useSetRecoilState(decodedTextDefaultState)

    useEffect(() => {
        if (!isPremium && enableDecodeDefault) {
            setDecodedTextDefault(true)
        } else if (isPremium || !enableDecodeDefault) {
            setDecodedTextDefault(false)
            if (!advancedUser) {
                // dont change to false if an admin etc (i.e. just testing isPremium)
                setProfileData((prevState) => {
                    const profileSettings = prevState.profileSettings || {}
                    const decode_default = false
                    return {
                        ...prevState,
                        profileSettings: {
                            ...profileSettings,
                            decode_default,
                        },
                    }
                })
            }
        }
    }, [
        isPremium,
        enableDecodeDefault,
        setDecodedTextDefault,
        advancedUser,
        setProfileData,
    ])

    //PORTAL REFERENCES
    const desktopNav = useRef()
    const mobileNav = useRef()

    const profileMenu = useBreakpointValue({
        base: { containerRef: mobileNav, width: '100%', minWidth: '100%' },
        md: { containerRef: desktopNav, width: '100%', minWidth: '100%' },
    })

    const iconSizeProfile = useBreakpointValue({
        base: { width: '35px', height: '35px' },
        md: { width: '90px', height: '90px' },
    })
    const iconSizeTab = useBreakpointValue({
        base: { width: '22px', height: '22px' },
        md: { width: '20px', height: '20px' },
    })
    const tabLayout = useBreakpointValue({
        base: 'horizontal',
        md: 'vertical',
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none' },
    }

    const menuButtonStyle = {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        opacity: {
            base: '0.9',
            md: '0.7',
        },
        fontWeight: '400',
        alignContent: 'center',
        borderTop: {
            base: '1px solid #F5F8FB',
            md: 'none',
        },
        borderLeft: {
            base: '1px solid #F5F8FB',
            md: 'none',
        },
        borderRight: {
            base: '1px solid #F5F8FB',
            md: 'none',
        },
        borderBottom: 'none',
        color: {
            base: 'light.100',
            md: 'light.10',
        },
        height: {
            base: '34px',
            md: '34px',
        },
        minHeight: {
            base: '34px',
            md: '34px',
        },
        mb: {
            base: '-1px',
            md: '15px',
        },
        py: '6px',
        pl: { base: '0px', md: '12px' },
        pr: { base: '0px', md: '18px' },
        borderRadius: {
            base: '0',
            md: '20px',
        },
        borderTopLeftRadius: {
            base: '15px',
            md: '20px',
        },
        borderTopRightRadius: {
            base: '15px',
            md: '20px',
        },
        transition: 'color 0s',
        width: {
            base: '100%',
            md: 'auto',
        },
        bg: { base: 'none', md: 'rgba(255,255,255,0.1)' },
        _hover: {
            opacity: '1',
            color: {
                base: 'light.200',
                md: 'light.10',
            },
            bg: { base: 'none', md: 'rgba(255,255,255,0.15)' },
        },
        _active: {
            opacity: '1',
            color: {
                base: 'light.200',
                md: 'light.10',
            },
            bg: { base: 'light.10', md: 'rgba(255,255,255,0.25)' },
        },
        _focus: {
            opacity: '1',
            color: {
                base: 'light.200',
                md: 'light.10',
            },
            bg: { base: 'light.10', md: 'rgba(255,255,255,0.25)' },
        },
        _selected: {
            opacity: '1',
            color: {
                base: 'light.200',
                md: 'light.10',
            },
            bg: {
                base: 'white !important',
                md: 'rgba(255,255,255,0.25) !important',
            },
            borderTop: {
                base: '1px solid #eaeaea',
                md: 'none',
            },
            borderBottom: {
                base: '2px solid #ffffff',
                md: 'none',
            },
            borderLeft: {
                base: '1px solid #eaeaea',
                md: 'none',
            },
            borderRight: {
                base: '1px solid #eaeaea',
                md: 'none',
            },
            pt: {
                base: '8px',
                md: '6px',
            },
        },
    }
    const purchaseButtonStyle = {
        cursor: 'pointer',
        display: 'flex',
        fontSize: '0.8rem',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: '500',
        alignContent: 'center',
        border: '1px solid',
        borderColor: 'brand.premium',
        color: {
            base: 'light.10',
            md: 'light.10',
        },
        height: {
            base: '34px',
            md: '32px',
        },
        minHeight: {
            base: '34px',
            md: '32px',
        },
        py: '4px',
        pl: { base: '0px', md: '12px' },
        pr: { base: '0px', md: '18px' },
        borderRadius: {
            base: '20px',
            md: '20px',
        },
        transition: 'all ease 200ms',
        width: '190px',
        minWidth: {
            base: 'unset',
            md: '190px',
        },
        bg: 'brand.premium',
        _hover: {
            opacity: '1',
            color: {
                base: 'light.10',
                md: 'light.10',
            },
            bg: 'brand.premiumHover',
            borderColor: 'brand.premiumHover',
        },
        _active: {
            opacity: '1',
            color: {
                base: 'light.10',
                md: 'light.10',
            },
            bg: 'brand.premiumHover',
            borderColor: 'brand.premiumHover',
        },
        _focus: {
            opacity: '1',
            color: {
                base: 'light.10',
                md: 'light.10',
            },
            bg: 'brand.premiumHover',
            borderColor: 'brand.premiumHover',
        },
        _selected: {
            opacity: '1',
            color: {
                base: 'light.10',
                md: 'light.10',
            },
            bg: 'light.200',
        },
    }

    // useEffect(() => {
    //     // console.log('user: ', user)
    //     // console.log('profileData', profileData)
    // }, [profileData])

    return (
        <>
            {!selectDefaultMapLocation && (
                <LightMode>
                    <Modal
                        variant="profile"
                        size={'6xl'}
                        isCentered
                        onClose={onCloseHandler}
                        isOpen={true}
                        motionPreset="slideInBottom"
                        scrollBehavior="inside"
                        closeOnOverlayClick={updatingAuth0 ? false : true}
                        trapFocus={false}
                    >
                        <ModalOverlay />
                        <ModalContent fontFamily="'Poppins', 'Open Sans', sans-serif">
                            <ModalCloseButton
                                {...noFocus}
                                size="lg"
                                opacity="0.6"
                                _hover={{
                                    opacity: '1',
                                }}
                                color={{
                                    base: 'light.10',
                                    md: 'light.100',
                                }}
                                position={{
                                    base: 'absolute',
                                    md: 'absolute',
                                }}
                                top={{
                                    base: '14px',
                                    md: '10px',
                                }}
                                right={{
                                    base: '10px',
                                    md: '10px',
                                }}
                                zIndex="100"
                                isDisabled={updatingAuth0}
                            />
                            <ModalHeader // Tab Title
                                flexDirection="row"
                                textAlign="center"
                                alignItems="center"
                                justifyContent="space-between"
                                color="white"
                                letterSpacing="0.5px"
                                borderTop={'1px solid #eaeaea'}
                                borderBottom="1px solid #eaeaea"
                                width="100%"
                                height={{
                                    base: '160px',
                                    md: '60px',
                                }}
                                minHeight={{
                                    base: '160px',
                                    md: '60px',
                                }}
                                position="relative"
                                bg={{
                                    base: 'light.10',
                                    md: 'light.600',
                                }}
                                pt={{
                                    base: '69px',
                                    md: '10px',
                                }}
                                pb={{
                                    base: '0px',
                                    md: '10px',
                                }}
                                paddingLeft={{
                                    base: '0px',
                                    md: '270px',
                                }}
                                paddingRight={{
                                    base: '0px',
                                    md: '20px',
                                }}
                                zIndex="19"
                                right={{
                                    base: 'unset',
                                    md: '0px',
                                }}
                            >
                                <Flex //Subscription and Password buttons for mobile view
                                    display={{
                                        base: 'flex',
                                        md: 'none',
                                    }}
                                    width="100%"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    borderBottom="1px solid #eaeaea"
                                    zIndex="100"
                                    height={{
                                        base: '43px',
                                        md: '0px',
                                    }}
                                >
                                    <Box
                                        ref={mobileNav}
                                        width="100%"
                                        zIndex="20"
                                        position="relative"
                                    ></Box>
                                </Flex>
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    height={{
                                        base: '50px',
                                        md: '40px',
                                    }}
                                >
                                    <Text
                                        color="light.100"
                                        fontSize="1rem"
                                        fontWeight="600"
                                        lineHeight={{
                                            base: '1.3',
                                            md: '2.5',
                                        }}
                                        width="100%"
                                        textAlign={{
                                            base: 'center',
                                            md: 'left',
                                        }}
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    >
                                        {tabIndex === 0
                                            ? 'PROFILE'
                                            : tabIndex === 1
                                            ? 'SETTINGS'
                                            : tabIndex === 2
                                            ? 'MANAGE BRIEFING FAVOURITES'
                                            : ''}
                                    </Text>
                                </Flex>

                                <Flex
                                    position={{
                                        base: 'absolute',
                                        md: 'absolute',
                                    }}
                                    top={{
                                        base: '115px',
                                        md: '10px',
                                    }}
                                    mr={{
                                        base: '0px',
                                        md: '40px',
                                    }}
                                    px={{
                                        base: '20px',
                                        md: '0px',
                                    }}
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    minHeight={{
                                        base: '40px',
                                        md: '40px',
                                    }}
                                    width={{
                                        base: '100%',
                                        md: 'calc(100% - 340px)',
                                    }}
                                    zIndex="10"
                                >
                                    {((tabIndex === 0 &&
                                        !disableSettings &&
                                        updatingAuth0) ||
                                        (tabIndex > 0 && updatingAuth0)) && (
                                        <Spinner
                                            position="absolute"
                                            color={'light.200'}
                                            thickness="2px"
                                            speed="0.45s"
                                            boxSize="30px"
                                        />
                                    )}
                                    <Flex
                                        visibility={
                                            disableSettings && tabIndex === 0
                                                ? 'hidden'
                                                : savedBadge
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        opacity={
                                            disableSettings && tabIndex === 0
                                                ? '0'
                                                : savedBadge
                                                ? '1'
                                                : '0'
                                        }
                                        transition="all ease-in-out 300ms"
                                        position="absolute"
                                        transform={{
                                            base: 'translateX(calc(50% - 1px))',
                                            md: 'unset',
                                        }}
                                        right={{
                                            base: '50%',
                                            md: 'unset',
                                        }}
                                        py={{
                                            base: '4px',
                                            md: '5px',
                                        }}
                                        px="15px"
                                        fontSize="0.8rem"
                                        color="light.200"
                                        borderRadius="20px"
                                        border="1px solid rgba(6, 208, 150, 0.2)"
                                        background="rgb(229, 250, 244)"
                                    >
                                        {tabIndex === 0
                                            ? 'Profile Saved'
                                            : tabIndex === 1
                                            ? 'Settings Saved'
                                            : tabIndex === 2
                                            ? 'Favourites Saved'
                                            : 'Autosaved'}
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                            <ModalBody
                                bg="white"
                                p="0px"
                                pb={{
                                    base: '30px',
                                    md: '0px',
                                }}
                            >
                                <Tabs
                                    defaultIndex={
                                        mapDisabledUpdated || settingsTab
                                            ? 1
                                            : 0
                                    }
                                    isLazy={true}
                                    onChange={(index) => {
                                        setTabIndex(index)
                                        setSavedBadge(false)
                                    }}
                                    h="100%"
                                    w="100%"
                                    orientation={tabLayout}
                                    isFitted
                                    variant="soft-rounded"
                                    align={{
                                        base: 'center',
                                        md: 'initial',
                                    }}
                                >
                                    <Flex
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems={{
                                            base: 'center',
                                            md: 'center',
                                        }}
                                        backgroundColor="dark.10"
                                        background="linear-gradient(315deg, rgb(93, 163, 198) 0%, rgb(9, 60, 98) 100%)"
                                        w={{ base: '100%', md: '250px' }}
                                        zIndex="30"
                                        position={{
                                            base: 'absolute',
                                            md: 'absolute',
                                        }}
                                        top="0px"
                                        bottom={{ base: 'unset', md: '0px' }}
                                        left={{ base: 'unset', md: '0px' }}
                                    >
                                        <Box
                                            py={{ base: '14px', md: '25px' }}
                                            px="20px"
                                            minHeight="70px"
                                            width={{
                                                base: '100%',
                                                md: '100%',
                                            }}
                                        >
                                            <Flex
                                                justifyContent={{
                                                    base: 'left',
                                                    md: 'center',
                                                }}
                                                flexDirection={{
                                                    base: 'row',
                                                    md: 'column',
                                                }}
                                            >
                                                <Flex
                                                    justifyContent={{
                                                        base: 'center',
                                                        md: 'center',
                                                    }}
                                                    flexDirection="column"
                                                    ml={{
                                                        base: '0px',
                                                        md: 'auto',
                                                    }}
                                                    mr={{
                                                        base: '15px',
                                                        md: 'auto',
                                                    }}
                                                    mt={{
                                                        base: '0px',
                                                        md: '-15px',
                                                    }}
                                                    pt={{
                                                        base: '2px',
                                                        md: '0px',
                                                    }}
                                                    pb={{
                                                        base: '0px',
                                                        md: '20px',
                                                    }}
                                                >
                                                    <ProfileIcon
                                                        color="white"
                                                        opacity="0.7"
                                                        {...iconSizeProfile}
                                                    />
                                                </Flex>
                                                <Flex
                                                    justifyContent={{
                                                        base: 'left',
                                                        md: 'center',
                                                    }}
                                                    flexDirection="column"
                                                >
                                                    <Text
                                                        color="white"
                                                        fontSize={{
                                                            base: '0.9rem',
                                                            md: '1.1rem',
                                                        }}
                                                        fontWeight="500"
                                                        lineHeight="1.6"
                                                        width="100%"
                                                        textAlign={{
                                                            base: 'left',
                                                            md: 'center',
                                                        }}
                                                        mr={{
                                                            base: '35px',
                                                            md: '0px',
                                                        }}
                                                    >
                                                        {profileData.firstName}{' '}
                                                        {profileData.lastName}
                                                    </Text>
                                                    <Text
                                                        color="white"
                                                        fontSize={{
                                                            base: '11px',
                                                            md: '0.7rem',
                                                        }}
                                                        fontWeight="200"
                                                        lineHeight="1.6"
                                                        width="100%"
                                                        textAlign={{
                                                            base: 'left',
                                                            md: 'center',
                                                        }}
                                                        mt={{
                                                            base: '0px',
                                                            md: '5px',
                                                        }}
                                                        mr={{
                                                            base: '35px',
                                                            md: '0px',
                                                        }}
                                                    >
                                                        {profileData.email}
                                                    </Text>
                                                </Flex>
                                            </Flex>

                                            <Portal
                                                {...profileMenu}
                                                style={{
                                                    minWidth: '100% !important',
                                                }}
                                            >
                                                <TabList
                                                    alignItems="center"
                                                    pt={{
                                                        base: '10px',
                                                        md: '0',
                                                    }}
                                                    px={{
                                                        base: '20px',
                                                        md: '0',
                                                    }}
                                                    bg={{
                                                        base: 'light.30',
                                                        md: 'none',
                                                    }}
                                                    borderBottom={{
                                                        base: 'none',
                                                        md: 'none',
                                                    }}
                                                    boxShadow={{
                                                        base: 'inset 0px 10px 15px -5px rgba(0,0,0,0.07)',
                                                        md: 'none',
                                                    }}
                                                >
                                                    <Tab
                                                        {...noFocus}
                                                        {...menuButtonStyle}
                                                        fontSize="0.85rem"
                                                        minWidth={{
                                                            base: 'unset',
                                                            md: '160px',
                                                        }}
                                                    >
                                                        <ProfileButtonIcon
                                                            color={{
                                                                base: 'light.100',
                                                                md: 'light.10',
                                                            }}
                                                            {...iconSizeTab}
                                                        />
                                                        <Text
                                                            ml={{
                                                                base: '0px',
                                                                md: '5px',
                                                            }}
                                                            display={{
                                                                base: 'none',
                                                                md: 'initial',
                                                            }}
                                                        >
                                                            Profile
                                                        </Text>
                                                    </Tab>
                                                    <Tab
                                                        {...noFocus}
                                                        {...menuButtonStyle}
                                                        fontSize="0.85rem"
                                                        minWidth={{
                                                            base: 'unset',
                                                            md: '160px',
                                                        }}
                                                    >
                                                        <SettingsIcon
                                                            color={{
                                                                base: 'light.100',
                                                                md: 'light.10',
                                                            }}
                                                            {...iconSizeTab}
                                                        />
                                                        <Text
                                                            ml={{
                                                                base: '0px',
                                                                md: '7px',
                                                            }}
                                                            display={{
                                                                base: 'none',
                                                                md: 'initial',
                                                            }}
                                                        >
                                                            Settings
                                                        </Text>
                                                    </Tab>

                                                    <Flex
                                                        {...noFocus}
                                                        onClick={() =>
                                                            logout({
                                                                returnTo: `${window.location.origin}`,
                                                            })
                                                        }
                                                        display={{
                                                            base: 'none',
                                                            md: 'flex',
                                                        }}
                                                        color="white"
                                                        fontSize={{
                                                            base: '0.7rem',
                                                            md: '0.75rem',
                                                        }}
                                                        opacity="0.6"
                                                        fontWeight="200"
                                                        lineHeight="1.6"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        gap="5px"
                                                        minWidth={{
                                                            base: 'unset',
                                                            md: '160px',
                                                        }}
                                                        textAlign={{
                                                            base: 'left',
                                                            md: 'center',
                                                        }}
                                                        mt={{
                                                            base: '5px',
                                                            md: '10px',
                                                        }}
                                                        mr={{
                                                            base: '35px',
                                                            md: '0px',
                                                        }}
                                                        _hover={{
                                                            opacity: '0.9',
                                                            cursor: 'pointer',
                                                        }}
                                                        transition="opacity ease 150ms"
                                                    >
                                                        <LogOutIcon
                                                            width="18px"
                                                            height="18px"
                                                        />
                                                        <Box
                                                            fontWeight={'400'}
                                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                            pr="8px"
                                                        >
                                                            Log Out
                                                        </Box>
                                                    </Flex>

                                                    {/* *** Left for now in case we build this out further later on *** */}
                                                    {/* <Tab
                                                        {...menuButtonStyle}
                                                        fontSize="0.85rem"
                                                        minWidth={{
                                                            base: 'unset',
                                                            md: '160px',
                                                        }}
                                                    >
                                                        <FavouriteIcon
                                                            color={{
                                                                base: 'light.100',
                                                                md: 'light.10',
                                                            }}
                                                            {...iconSizeTab}
                                                        />
                                                        <Text
                                                            ml={{
                                                                base: '0px',
                                                                md: '7px',
                                                            }}
                                                            display={{
                                                                base: 'none',
                                                                md: 'initial',
                                                            }}
                                                        >
                                                            Briefing Favourites
                                                        </Text>
                                                    </Tab> */}
                                                </TabList>
                                            </Portal>
                                        </Box>

                                        <Box
                                            width="100%"
                                            ref={desktopNav}
                                        ></Box>
                                    </Flex>
                                    <TabPanels //Content
                                        paddingLeft={{
                                            base: '0px',
                                            md: '250px',
                                        }}
                                        width="100%"
                                        height="100%"
                                        minHeight={{
                                            base: 'calc(100% - 270px)',
                                            md: 'calc(100% - 270px)',
                                            lg: '100%',
                                        }}
                                        color="light.100"
                                        zIndex="1"
                                    >
                                        <TabPanel // PROFILE TAB (#1)
                                            w="100%"
                                            height="100%"
                                            p="0px"
                                        >
                                            <ProfileTab
                                                profileData={profileData}
                                                setProfileData={setProfileData}
                                                mfaTimeToExpire={mfaState.state}
                                                daysRemaining={mfaState.days}
                                                setShowResetMfaModal={
                                                    setShowResetMfaModal
                                                }
                                                previouslyVerified={
                                                    previouslyVerified
                                                }
                                                userTier={userTier}
                                                activeMfa={activeMfa}
                                                isPremium={isPremium}
                                                mfaExpired={mfaExpired}
                                                disableSettings={
                                                    disableSettings
                                                }
                                            />
                                        </TabPanel>

                                        <TabPanel // MANAGE SETTINGS TAB
                                            w="100%"
                                            height="100%"
                                            p="0px"
                                        >
                                            <SettingsTab
                                                // userRoles={userRoles}
                                                profileData={profileData}
                                                getAccessTokenSilently={
                                                    getAccessTokenSilently
                                                }
                                                setSelectDefaultMapLocation={
                                                    setSelectDefaultMapLocation
                                                }
                                                setShowResetMfaModal={
                                                    setShowResetMfaModal
                                                }
                                                setSettingsTab={setSettingsTab}
                                                onResetHandler={onResetHandler}
                                                setProfileData={setProfileData}
                                                previouslyVerified={
                                                    previouslyVerified
                                                }
                                                isPremium={isPremium}
                                                mfaExpired={mfaExpired}
                                                userTier={userTier}
                                                activeMfa={activeMfa}
                                                plusSubscriptionOnly={
                                                    plusSubscriptionOnly
                                                }
                                                disableSettings={
                                                    disableSettings
                                                }
                                                mfaTimeToExpire={mfaState.state}
                                                isBriefingOnly={isBriefingOnly}
                                                setIsBriefingOnly={
                                                    setIsBriefingOnly
                                                }
                                                mapDisabled={mapDisabled}
                                                setMapDisabled={setMapDisabled}
                                                setMapDisabledUpdated={
                                                    setMapDisabledUpdated
                                                }
                                                onCloseHandler={onCloseHandler}
                                            />
                                        </TabPanel>

                                        {/* *** Left for now in case we build this out further later on *** */}
                                        {/* 
                                                {!isPremium &&
                                                 userRoles.some((role) => ['Plus', 'Professional'].includes(role))  && (
                                                    <TabPanel // MANAGE BRIEFING FAVOURITES TAB
                                                w="100%"
                                                p="0px"
                                                >
                                                
                                            </TabPanel>
                                                )} */}
                                    </TabPanels>
                                </Tabs>
                            </ModalBody>
                            <ModalFooter
                                // SUBSCRIPTION STUFF
                                width="100%"
                                flexDirection={{
                                    base: 'column',
                                    lg:
                                        isPremium &&
                                        mfaExpired &&
                                        subscriptionData
                                            ? 'column'
                                            : 'row',
                                }}
                                gap="5px"
                                justifyContent={{
                                    base: 'center',
                                    lg: 'space-between',
                                }}
                                alignItems="center"
                                fontSize="0.8rem"
                                fontWeight="300"
                                paddingLeft={{
                                    base: '0px',
                                    md: '270px',
                                }}
                                bg={{
                                    base: 'light.10',
                                    md: 'light.600',
                                }}
                                paddingRight={{
                                    base: '0px',
                                    md: '20px',
                                }}
                                pb={{
                                    base: isPremium ? '6' : '4',
                                    md: '4',
                                }}
                                minHeight={{
                                    base:
                                        isPremium ||
                                        subscriptionData?.cancel_at_period_end
                                            ? '105px'
                                            : subscriptionData
                                            ? '95px'
                                            : '60px',
                                    smd:
                                        isPremium ||
                                        subscriptionData?.cancel_at_period_end
                                            ? '105px'
                                            : subscriptionData
                                            ? '75px'
                                            : '60px',
                                    lg: '60px',
                                }}
                                borderTop="1px solid #eaeaea"
                                color="light.400"
                                transition="all ease-in 400ms"
                                zIndex="2"
                            >
                                <Flex
                                    // width="100%"
                                    flexDirection={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                    alignItems="center"
                                    justifyContent={{
                                        base: 'center',
                                        md: 'flex-start',
                                    }}
                                    my={{
                                        base: isPremium ? '3px' : '0px',
                                        md: '0px',
                                    }}
                                    gap={{
                                        base: '5px',
                                        md: '0px',
                                    }}
                                >
                                    <Flex
                                        width="auto"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Text
                                            color="light.400"
                                            fontSize="0.8rem"
                                            fontWeight="500"
                                            mr={{
                                                base: '8px',
                                                md: '8px',
                                            }}
                                        >
                                            {!isPremium && userTier > 1
                                                ? 'Subscription:'
                                                : 'ACCOUNT:'}
                                        </Text>

                                        {(!isPremium ||
                                            (isPremium && mfaExpired)) &&
                                        userTier === 2 ? (
                                            <Badge
                                                fontWeight="600"
                                                letterSpacing="0.5px"
                                                colorScheme={'blue'}
                                                color="brand.plus"
                                                borderRadius="10px"
                                                px="8px"
                                                fontSize="0.75rem"
                                                height="18px"
                                            >
                                                PLUS
                                            </Badge>
                                        ) : (!isPremium ||
                                              (isPremium && mfaExpired)) &&
                                          advancedUser &&
                                          userTier === 3 ? (
                                            <Badge
                                                fontWeight="600"
                                                letterSpacing="0.5px"
                                                bg="#D3F5FB"
                                                color="brand.premium"
                                                borderRadius="10px"
                                                fontSize="0.75rem"
                                                height="18px"
                                                px="8px"
                                            >
                                                {userRoles.includes('Admin')
                                                    ? 'Admin'
                                                    : userRoles.includes('Dev')
                                                    ? 'Dev'
                                                    : userRoles.includes(
                                                          'Aeropath'
                                                      )
                                                    ? 'Aeropath'
                                                    : userRoles.includes(
                                                          'MetService Aviation'
                                                      )
                                                    ? 'MetService Aviation'
                                                    : userRoles.includes(
                                                          'MetService Service Desk'
                                                      )
                                                    ? 'MetService Service Desk'
                                                    : userRoles.includes(
                                                          'MetService'
                                                      )
                                                    ? 'MetService'
                                                    : userRoles.includes(
                                                          'BetaTester'
                                                      )
                                                    ? 'Beta Tester'
                                                    : ''}
                                            </Badge>
                                        ) : (!isPremium ||
                                              (isPremium && mfaExpired)) &&
                                          !advancedUser &&
                                          userTier === 3 ? (
                                            <Badge
                                                fontWeight="600"
                                                letterSpacing="0.5px"
                                                colorScheme={'purple'}
                                                color="brand.pro"
                                                borderRadius="10px"
                                                px="8px"
                                                fontSize="0.75rem"
                                                height="18px"
                                            >
                                                PRO
                                            </Badge>
                                        ) : (
                                            <Badge
                                                bg="brand.free"
                                                fontWeight="600"
                                                letterSpacing="0.5px"
                                                color="light.400"
                                                borderRadius="10px"
                                                px="8px"
                                                fontSize="0.75rem"
                                                height="18px"
                                            >
                                                FREE
                                            </Badge>
                                        )}
                                    </Flex>
                                    {isPremium &&
                                        !mfaExpired &&
                                        !subscriptionData && (
                                            <Text
                                                color="gray.400"
                                                fontSize={{
                                                    base: '0.75rem',
                                                    sm: '0.8rem',
                                                }}
                                                fontWeight="400"
                                                minWidth={{
                                                    base: '100%',
                                                    md: 'auto',
                                                }}
                                                ml="8px"
                                                px={{
                                                    base: '10px',
                                                    md: '0px',
                                                }}
                                                textAlign={{
                                                    base: 'center',
                                                    md: 'left',
                                                }}
                                            >
                                                You do not have an active
                                                premium subscription
                                            </Text>
                                        )}
                                    {userTier > 1 &&
                                        isPremium &&
                                        mfaExpired && (
                                            <Text
                                                color="warning.100"
                                                fontSize={{
                                                    base: '0.75rem',
                                                    sm: '0.8rem',
                                                }}
                                                fontWeight="400"
                                                minWidth={{
                                                    base: '100%',
                                                    md: 'auto',
                                                }}
                                                ml="8px"
                                                px={{
                                                    base: '10px',
                                                    md: '0px',
                                                }}
                                                textAlign={{
                                                    base: 'center',
                                                    md: 'left',
                                                }}
                                            >
                                                Your premium subscription
                                                services are temporarily
                                                unavailable until you reset your
                                                MFA
                                            </Text>
                                        )}
                                </Flex>
                                <Flex
                                    width={subscriptionData ? '100%' : 'auto'}
                                    justifyContent={{
                                        base: 'center',
                                        lg: 'flex-end',
                                    }}
                                    alignItems="center"
                                >
                                    {subscriptionData ? (
                                        <Flex
                                            flexDirection={{
                                                base: 'column',
                                                smd: subscriptionData?.cancel_at_period_end
                                                    ? 'column'
                                                    : 'row',
                                                md: 'row',
                                            }}
                                            justifyContent={{
                                                base: 'center',
                                                lg: 'flex-end',
                                            }}
                                            alignItems="center"
                                            width={{
                                                base: 'auto',
                                                lg: '100%',
                                            }}
                                            gap={{
                                                base: '2px',
                                                smd: subscriptionData?.cancel_at_period_end
                                                    ? '2px'
                                                    : '5px',
                                                md: '5px',
                                            }}
                                            mt={{
                                                base: '-3px',
                                                md: '0px',
                                            }}
                                        >
                                            <Flex
                                                justifyContent={{
                                                    base: 'center',
                                                    lg: 'flex-end',
                                                }}
                                                flexDirection={{
                                                    base: 'column',
                                                    smd: subscriptionData?.cancel_at_period_end
                                                        ? 'column'
                                                        : 'row',
                                                    md: 'row',
                                                }}
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text
                                                    ml={{
                                                        base: '0',
                                                        md: '0',
                                                        ml: '5px',
                                                        lg: '15px',
                                                    }}
                                                    mr="5px"
                                                >
                                                    {subscriptionData?.cancel_at_period_end
                                                        ? 'Your subscription has been cancelled and will end on: '
                                                        : 'Your subscription will automatically renew on: '}
                                                </Text>
                                                <Text fontWeight="500">
                                                    {subscriptionData?.current_period_end &&
                                                        formatDateAndTimezone(
                                                            formatISO(
                                                                new Date(
                                                                    subscriptionData.current_period_end *
                                                                        1000
                                                                )
                                                            ),
                                                            timeZone
                                                        )}
                                                </Text>
                                            </Flex>
                                            <Popover
                                                placement={popoverPlacement}
                                            >
                                                <PopoverTrigger>
                                                    <Flex
                                                        mt={{
                                                            base: '0px',
                                                            md: '-1px',
                                                        }}
                                                        width="30px"
                                                        height="28px"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        borderRadius="10px"
                                                        color="gray.400"
                                                        opacity="0.8"
                                                        cursor="pointer"
                                                    >
                                                        <Icon
                                                            as={Question}
                                                            width={
                                                                infoButtonSize
                                                            }
                                                            height={
                                                                infoButtonSize
                                                            }
                                                        />
                                                    </Flex>
                                                </PopoverTrigger>
                                                <PopoverContent
                                                    color="white"
                                                    bg="gray.600"
                                                    px="5px"
                                                    py="3px"
                                                    borderColor="gray.600"
                                                    borderRadius="15px"
                                                    fontSize="0.8rem"
                                                    alignItems="center"
                                                    textAlign="center"
                                                    lineHeight="1.4"
                                                    fontFamily="Open Sans"
                                                    minWidth={{
                                                        base: '380px',
                                                        smd: '562px',
                                                    }}
                                                >
                                                    <PopoverArrow bg="gray.600" />
                                                    <PopoverBody>
                                                        <Text>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '600',
                                                                    color: '#6dc8ff',
                                                                }}
                                                            >
                                                                PLUS
                                                            </span>{' '}
                                                            subscription plans{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                automatically
                                                                renew
                                                            </span>{' '}
                                                            at the end of the
                                                            monthly subscription
                                                            billing cycle unless
                                                            cancelled
                                                            beforehand.
                                                        </Text>
                                                        <Divider
                                                            my="10px"
                                                            opacity="0.4"
                                                        />
                                                        <Text
                                                            fontSize={{
                                                                base: '0.75rem',
                                                                sm: '0.8rem',
                                                            }}
                                                        >
                                                            Cancelling your{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                PLUS
                                                            </span>{' '}
                                                            subscription plan
                                                            will revert your
                                                            account to the{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                FREE
                                                            </span>{' '}
                                                            version at the end
                                                            of the current
                                                            subscription month
                                                            you've already paid
                                                            for. This allows
                                                            continued access to
                                                            most of PreFlight's
                                                            services for free
                                                            thereafter.
                                                        </Text>
                                                        <Text
                                                            pt="10px"
                                                            fontSize={{
                                                                base: '0.75rem',
                                                                sm: '0.8rem',
                                                            }}
                                                        >
                                                            Please note that
                                                            refunds are not
                                                            provided for the
                                                            remaining portion of
                                                            the cancelled month
                                                            should you choose to
                                                            cancel before the
                                                            end of your current
                                                            month billing cycle.
                                                        </Text>
                                                        <Text
                                                            pt="5px"
                                                            fontSize={{
                                                                base: '0.75rem',
                                                                sm: '0.8rem',
                                                            }}
                                                        >
                                                            You can easily
                                                            upgrade back to a{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                PLUS
                                                            </span>{' '}
                                                            plan again at any
                                                            time on this account
                                                            if you wish.
                                                        </Text>
                                                        <Divider
                                                            my="10px"
                                                            opacity="0.4"
                                                        />
                                                        <Text>
                                                            Click{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                Manage
                                                                Subscription
                                                            </span>{' '}
                                                            to update your
                                                            payment details or
                                                            to cancel your{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '600',
                                                                }}
                                                            >
                                                                PLUS
                                                            </span>{' '}
                                                            subscription.
                                                        </Text>
                                                    </PopoverBody>
                                                    <PopoverFooter
                                                        pt="3px"
                                                        pb="12px"
                                                        border="0"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Button
                                                            ref={
                                                                initialFocusRef
                                                            }
                                                            fontSize="0.8rem"
                                                            borderRadius="25px"
                                                            color="white"
                                                            px="25px"
                                                            gap="7px"
                                                            bg="rgba(255,255,255,0.1)"
                                                            cursor="pointer"
                                                            _hover={{
                                                                opacity: '1',
                                                                bg: 'rgba(255,255,255,0.3)',
                                                                textShadow:
                                                                    '1px 3px 15px rgba(0, 0, 0, 0.3)',
                                                            }}
                                                            _active={{
                                                                opacity: '1',
                                                                bg: 'rgba(255,255,255,0.2)',
                                                            }}
                                                            _focus={{
                                                                opacity: '1',
                                                                bg: 'rgba(255,255,255,0.2)',
                                                            }}
                                                            zIndex="1"
                                                            height="32px"
                                                            transition="all linear 200ms"
                                                            onClick={async () => {
                                                                const accessToken =
                                                                    await getAccessTokenSilently()
                                                                const showSubscriptionWarning =
                                                                    await isConfirmed(
                                                                        // Show subscription warning message
                                                                        <ManageSubscriptionMessage
                                                                            userTier={
                                                                                userTier
                                                                            }
                                                                            userRoles={
                                                                                userRoles
                                                                            }
                                                                            isPremium={
                                                                                isPremium
                                                                            }
                                                                        />
                                                                    )
                                                                let triggerFetch = false

                                                                if (
                                                                    plusSubscriptionOnly ||
                                                                    (!plusSubscriptionOnly &&
                                                                        showSubscriptionWarning)
                                                                ) {
                                                                    if (
                                                                        mfaExpired ||
                                                                        disableSettings
                                                                    ) {
                                                                        setShowResetMfaModal(
                                                                            true
                                                                        )
                                                                    } else {
                                                                        triggerFetch = true
                                                                    }

                                                                    if (
                                                                        triggerFetch
                                                                    ) {
                                                                        fetch(
                                                                            `${window.location.origin}/managesubscription`,
                                                                            {
                                                                                headers:
                                                                                    {
                                                                                        email: profileData.email,
                                                                                        authorization: `Bearer ${accessToken}`,
                                                                                    },
                                                                            }
                                                                        )
                                                                            .then(
                                                                                (
                                                                                    res
                                                                                ) =>
                                                                                    res.json()
                                                                            )
                                                                            .then(
                                                                                (
                                                                                    session
                                                                                ) =>
                                                                                    window.location.replace(
                                                                                        session.url
                                                                                    )
                                                                            )
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <SubscriptionIcon
                                                                width="20px"
                                                                height="20px"
                                                            />
                                                            <Text
                                                                fontWeight="500"
                                                                letterSpacing="0.5px"
                                                            >
                                                                Manage
                                                                Subscription
                                                            </Text>
                                                        </Button>
                                                    </PopoverFooter>
                                                </PopoverContent>
                                            </Popover>
                                        </Flex>
                                    ) : (
                                        isPremium &&
                                        !mfaExpired &&
                                        !userRoles.includes('Plus') &&
                                        !userRoles.includes('Professional') && (
                                            <Button
                                                {...noFocus}
                                                {...purchaseButtonStyle}
                                                onClick={async () => {
                                                    setShowPricingTiersModal(
                                                        true
                                                    )
                                                    setShow(false)
                                                }}
                                            >
                                                <RiStarLine
                                                    color="#FFFFFF"
                                                    fontSize="1rem"
                                                />
                                                <Text ml="5px">
                                                    Upgrade to Premium
                                                </Text>
                                            </Button>
                                        )
                                    )}
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </LightMode>
            )}
            <SetMapFavourite
                profileData={profileData}
                getAccessTokenSilently={getAccessTokenSilently}
                selectDefaultMapLocation={selectDefaultMapLocation}
                setSelectDefaultMapLocation={setSelectDefaultMapLocation}
                setSettingsTab={setSettingsTab}
                setProfileData={setProfileData}
                isBriefingOnly={isBriefingOnly}
                mapDisabled={mapDisabled}
            />
        </>
    )
}

import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useUserMetaData, useTier } from '../../auth/hooks'
import {
    Box,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Icon,
    Text,
    CloseButton,
    useColorMode,
    useColorModeValue,
    useDisclosure,
    Divider,
    Link,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    Portal,
    IconButton,
    keyframes,
    useBreakpointValue,
    Badge,
    Tooltip,
} from '@chakra-ui/react'
import { MfaActive } from '../functions/MfaHandler'
import { isMobile, isIOS, isDesktop } from 'react-device-detect'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { motion } from 'framer-motion'
import { FiMoon, FiSun } from 'react-icons/fi'
import { useAdminRole, useMetAdminRole } from '../../auth/hooks'
import { FiMenu } from 'react-icons/fi'
// import { HiOutlineSpeakerphone } from 'react-icons/hi'
import PremiumBadge from '../controls/PremiumBadge'
import Profile from './components/Profile'
import EffectiveDate from './components/EffectiveDate'
import {
    settingsMenuOpenState,
    timeZoneState,
    toggleMapBoundsState,
    limitMapBoundsState,
    wipMenuItemsState,
    showOnlineUsersButtonState,
    setModalVisibilityState,
    pdfViewerContentState,
    fullscreenModalVisibleState,
    contrastState,
    tourVisibilityState,
    showErrorTogglesState,
    briefingFullscreenChartsState,
    simulateFreeUserState,
    oldDeviceState,
    loginMessagesState,
    bypassLandingPageState,
    mfaHandlerState,
    mfaVerifiedState,
    userProfileState,
    briefingStandardState,
    mapDisabledState,
    isBriefingOnlyState,
    daylighTableSourceState,
} from '../../globalState'
import { formatISO, addDays, formatDistanceToNow } from 'date-fns'
import formatDateTime, { timeZoneAbbreviation } from '../../util/dateFormatter'
import VersionNumber, {
    VersionDate,
} from '../../theme/components/VersionNumber'

import { GoCheck } from 'react-icons/go'
import { ReactComponent as GoBackIcon } from '../../icons/assets/go-back.svg'
import { ReactComponent as AeropathLogo } from './assets/aeropath.svg'
import { ReactComponent as MetserviceLogo } from './assets/metservice.svg'
import { ReactComponent as AipIcon } from '../../icons/assets/aip.svg'
import { ReactComponent as FlightplanIcon } from '../../icons/assets/flightplan.svg'
import { ReactComponent as FeedbackIcon } from '../../icons/assets/feedback.svg'
// import { ReactComponent as PDFIcon } from '../../icons/assets/pdf.svg'
import { ReactComponent as AdminIcon } from '../../icons/assets/admin.svg'
import { ReactComponent as OriginatorsIcon } from '../../icons/assets/originators.svg'
import { ReactComponent as ServiceMessagesIcon } from '../../icons/assets/service-messages.svg'
import { ReactComponent as InfoIcon } from '../../icons/assets/info-largecircle.svg'
import { ReactComponent as OtherIcon } from '../../icons/assets/other.svg'
import { ReactComponent as NewTabIcon } from '../../icons/assets/new-tab.svg'
import { ReactComponent as LogOutIcon } from '../../icons/assets/log-out.svg'

import PreFlight from '../../icons/menu-icons/PreFlightIcon'

export default function Nav() {
    const [showOnlineUsersButton, setShowOnlineUsersButton] = useRecoilState(
        showOnlineUsersButtonState
    )
    const [simulateFreeUser, setSimulateFreeUser] = useRecoilState(
        simulateFreeUserState
    )
    const navigate = useNavigate()
    const setPdfContent = useSetRecoilState(pdfViewerContentState)
    const { logout } = useAuth0()

    const [timeZone, setTimeZone] = useRecoilState(timeZoneState)
    const [profileData, setProfileData] = useRecoilState(userProfileState)
    const userMetaData = useUserMetaData()
    const briefingStandard = useRecoilValue(briefingStandardState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)

    // MFA reset dates - ADMIN ONLY USE
    // const mfaVerifiedDate = 1712009089155 // test use only (change the number to suit, and comment out the useMemo when testing)
    const mfaVerifiedDate = useMemo(() => {
        if (
            profileData &&
            userMetaData &&
            profileData.mfaLastVerified &&
            userMetaData.mfa_last_verified
        ) {
            return profileData.mfaLastVerified > userMetaData.mfa_last_verified
                ? profileData.mfaLastVerified
                : userMetaData.mfa_last_verified
        }
        return null
    }, [profileData, userMetaData])

    const lastVerifiedDate = useMemo(() => {
        return (
            mfaVerifiedDate &&
            formatDateTime(formatISO(mfaVerifiedDate), timeZone)
        )
    }, [mfaVerifiedDate, timeZone])

    const expiryDate = useMemo(() => {
        return (
            mfaVerifiedDate &&
            formatDateTime(formatISO(addDays(mfaVerifiedDate, 14)), timeZone)
        )
    }, [mfaVerifiedDate, timeZone])

    const expiryDateTimeSince = useMemo(() => {
        return (
            mfaVerifiedDate &&
            formatDistanceToNow(mfaVerifiedDate, { addSuffix: true })
        )
    }, [mfaVerifiedDate])

    const expiryDateTimeRemaining = useMemo(() => {
        return (
            mfaVerifiedDate &&
            formatDistanceToNow(addDays(mfaVerifiedDate, 14), {
                addSuffix: true,
            })
        )
    }, [mfaVerifiedDate])

    const mobileNumber = userMetaData && userMetaData.mobile_number

    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const mfaExpired = userTier > 1 && !mfaVerified
    const [showErrorToggles, setShowErrorToggles] = useRecoilState(
        showErrorTogglesState
    )
    const setBypassLandingPage = useSetRecoilState(bypassLandingPageState)
    const messages = useRecoilValue(loginMessagesState)

    const sidebar = useDisclosure()
    const [settingsMenuOpen, setSettingsMenuOpen] = useRecoilState(
        settingsMenuOpenState
    )
    const toggleMapBounds = useSetRecoilState(toggleMapBoundsState)
    const limitMapBounds = useRecoilValue(limitMapBoundsState)
    const [wipMenuItems, setWipMenuItems] = useRecoilState(wipMenuItemsState)
    const [daylighTableSource, setDaylighTableSource] = useRecoilState(
        daylighTableSourceState
    )

    const setShowUserIntro = useSetRecoilState(tourVisibilityState)

    const [openMenu, setOpenMenu] = useRecoilState(settingsMenuOpenState)
    const contrastHandler = useRecoilValue(contrastState)
    const fullscreenModalOpen = useRecoilValue(fullscreenModalVisibleState)
    const [headerBG, setHeaderBG] = useState(false)
    const briefingChartsFullscreen = useRecoilValue(
        briefingFullscreenChartsState
    )
    const isOldDevice = useRecoilValue(oldDeviceState)
    const mfaState = useRecoilValue(mfaHandlerState)
    const checkMfaActive = useCallback(() => MfaActive(), [])
    const activeMfa = checkMfaActive()

    const [showMfaBadge, setShowMfaBadge] = useState(false)

    useEffect(() => {
        const updateShowMfaBadge =
            !activeMfa &&
            (!isPremium || (isPremium && mfaExpired)) &&
            mfaState &&
            (mfaState.state === 'EXPIRED' ||
                mfaState.state === 'WARNING' ||
                mfaState.state === 'CAUTION')
        if (updateShowMfaBadge) {
            setShowMfaBadge(true)
        } else {
            setShowMfaBadge(false)
        }
    }, [isPremium, mfaExpired, mfaState, activeMfa])

    // *** Service Messages ***
    const hasServicesMessages = messages && Boolean(messages?.length)
    // Extracting the "id" and "starttime" values and append into a new array
    const messageIdsArray =
        messages &&
        messages.map((item) => `${item.id.toString()}-${item.starttime}`)
    const metaDataMessageIdsArray =
        profileData && profileData.readMessages
            ? profileData.readMessages.map((item) => item)
            : userMetaData && userMetaData.read_messages
            ? userMetaData.read_messages.map((item) => item)
            : []
    // // Finding the common "id" values between the two arrays
    const newMessages = messageIdsArray.filter(
        (id) => !metaDataMessageIdsArray.includes(id)
    )
    const readMessages = Boolean(
        !newMessages ||
            newMessages?.length === 0 ||
            (profileData?.allMessagesRead ?? false)
    )

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setLoginMessageModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'loginMessage', value })
        },
        [setModalVisibility]
    )

    const setViewLoginMessageModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'viewLoginMessage', value })
        },
        [setModalVisibility]
    )

    const setShowFAQs = useCallback(
        (value) => {
            setModalVisibility({ id: 'faqs', value })
        },
        [setModalVisibility]
    )

    const setShowDeviceDetails = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceDetails', value })
        },
        [setModalVisibility]
    )

    const setShowDeviceWarning = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceWarning', value })
        },
        [setModalVisibility]
    )

    const setShowDeviceRequirements = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceRequirements', value })
        },
        [setModalVisibility]
    )

    const setShowAnnouncements = useCallback(
        (value) => {
            setModalVisibility({ id: 'announcements', value })
        },
        [setModalVisibility]
    )

    const setShowWhyMfa = useCallback(
        (value) => {
            setModalVisibility({ id: 'whyMfa', value })
        },
        [setModalVisibility]
    )

    // const setShowCredits = useCallback(
    //     (value) => {
    //         setModalVisibility({ id: 'credits', value })
    //     },
    //     [setModalVisibility]
    // )

    const setShowSpecialNotice = useCallback(
        (value) => {
            setModalVisibility({ id: 'specialNotice', value })
        },
        [setModalVisibility]
    )

    const setShowTerms = useCallback(
        (value) => {
            setModalVisibility({ id: 'terms', value })
        },
        [setModalVisibility]
    )

    const setShowDataStatus = useCallback(
        (value) => {
            setModalVisibility({ id: 'dataStatus', value })
        },
        [setModalVisibility]
    )

    const setUserReportModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'userReport', value })
        },
        [setModalVisibility]
    )

    const setShowAerodromeSprites = useCallback(
        (value) => {
            setModalVisibility({ id: 'aerodromeSprites', value })
        },
        [setModalVisibility]
    )

    const setShowRunways = useCallback(
        (value) => {
            setModalVisibility({ id: 'runways', value })
        },
        [setModalVisibility]
    )

    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    function handlePDFState(value) {
        // console.log('clicked')
        setModalVisibility({ id: 'pdf', value: true })
        setPdfContent(value)
        setSettingsMenuOpen(false)
    }

    useEffect(() => {
        settingsMenuOpen ? sidebar.onOpen() : sidebar.onClose()
    }, [settingsMenuOpen, sidebar])

    const timer = useRef(null)

    useEffect(() => {
        // used to begin header background transition effect (to fix)
        if (settingsMenuOpen) {
            timer.current = setTimeout(() => {
                setHeaderBG(true)
            }, 600)
        } else {
            setHeaderBG(false)
        }
    }, [settingsMenuOpen, headerBG, setHeaderBG])

    useEffect(() => {
        if (isPremium) {
            setTimeZone('UTC')
        }
    }, [isPremium, setTimeZone])

    const handleChangeTimeZone = () => {
        const newZone =
            timeZone === 'UTC'
                ? Intl.DateTimeFormat().resolvedOptions().timeZone
                : 'UTC'
        setTimeZone(newZone)
    }

    const { colorMode, toggleColorMode } = useColorMode()
    const menuText = useColorModeValue('light.100', 'dark.100')
    const MenuHover = useColorModeValue('light.20', 'dark.20')
    const divider = useColorModeValue('light.100', 'dark.100')
    const logo = useColorModeValue('brand.200', 'dark.200')
    const collapseButtonClosed = useColorModeValue('light.10', 'dark.10')
    const badgeCentre = useColorModeValue('light.10', 'rgb(99, 116, 127)')
    const collapseButtonHover = useColorModeValue('light.20', 'dark.20')
    const collapseButtonMobileHigh = useColorModeValue('light.10', 'dark.304')
    const collapseButtonMobileMedium = useColorModeValue('light.10', 'dark.303')
    const collapseButtonMobileStandard = useColorModeValue(
        'light.10',
        'dark.301'
    )
    const collapseButtonColor = useColorModeValue('brand.strong', 'dark.80')
    const collapseButtonHoverMobile = useColorModeValue('light.20', 'dark.302')
    const hamburgerIconBoxShadow = useColorModeValue(
        '0px 4px 12px -5px rgba(0, 0, 0, 0.3)',
        '0px 2px 20px -8px rgba(255,255,255,0.3)'
    )
    const scrollTheme = useColorModeValue('os-theme-dark', 'os-theme-light')
    const scrollBarHide = useBreakpointValue({
        base: 'scroll',
        md: 'leave',
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const animationKeyframesInner = keyframes`
        0% { transform: scale(0) rotate(-10deg); border-radius: 100%; } 
        100% { transform: scale(1) rotate(1deg); border-radius: 100%; }`
    const animationInner = `${animationKeyframesInner} 0.7s cubic-bezier(.08,.84,.37,.92)`
    const animationOuter = `${animationKeyframesInner} 0.8s cubic-bezier(.31,.89,.58,1.01)`

    const SidebarContent = (props) => (
        <Flex
            className="settingsScrollVertical"
            as="nav"
            pos="fixed"
            top="0"
            left="0"
            zIndex="sticky"
            h="full"
            pb="10"
            overflowX="hidden"
            overflowY="auto"
            bg={useColorModeValue('white', 'dark.10')}
            borderColor={useColorModeValue('inherit', 'gray.700')}
            borderRightWidth="1px"
            w="60"
            flexDirection="column"
            justifyContent="space-between"
            {...props}
        >
            <OverlayScrollbarsComponent
                options={{
                    className: scrollTheme,
                    scrollbars: {
                        visibility: 'auto',
                        autoHide: scrollBarHide,
                        autoHideDelay: 800,
                        dragScrolling: true,
                        clickScrolling: true,
                        touchSupport: true,
                    },
                    overflow: {
                        x: 'hidden',
                        y: 'scroll',
                    },
                }}
                style={{
                    height: '100%',
                    minHeight: '100%',
                    overflowX: 'hidden !important',
                }}
                defer
            >
                <Flex
                    height="100%"
                    minHeight="100vh"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <div id="sidebar-header">
                        <Box // Header Inner Ring
                            as={motion.div}
                            animation={animationInner}
                            position="absolute"
                            opacity={useColorModeValue('1', '1')}
                            bg={useColorModeValue('light.40', 'dark.30')}
                            backgroundImage={useColorModeValue(
                                'linear-gradient(345deg, #FCFEFF 10%, #F6F8FA 50%)',
                                'linear-gradient(345deg, #263542 10%, #1d2a36 50%)'
                            )}
                            width={{
                                base: !headerBG ? '0px' : '640px',
                                lg: !headerBG ? '0px' : '640px',
                            }}
                            height={{
                                base: !headerBG ? '0px' : '106px',
                                lg: !headerBG ? '0px' : '106px',
                                xl: !headerBG ? '0px' : '101px',
                            }}
                            transform="rotate(1deg)"
                            borderRadius="100%"
                            left="-116%"
                            top={{
                                base: '-4%',
                                lg: '-4%',
                                xl: '-3%',
                            }}
                            zIndex="-1"
                            boxShadow={useColorModeValue(
                                '9px 2px 30px -12px rgba(5, 25, 45, 0.15)',
                                'none'
                            )}
                        ></Box>
                        <Box // Header Outer Ring
                            as={motion.div}
                            animation={animationOuter}
                            position="absolute"
                            opacity={useColorModeValue('1', '0.7')}
                            bg={useColorModeValue('#F0F4F8', 'dark.20')}
                            width={{
                                base: !headerBG ? '0px' : '730px',
                                lg: !headerBG ? '0px' : '730px',
                            }}
                            height={{
                                base: !headerBG ? '0px' : '118px',
                                lg: !headerBG ? '0px' : '118px',
                                xl: !headerBG ? '0px' : '113px',
                            }}
                            transform="rotate(1deg)"
                            borderRadius="100%"
                            left="-116%"
                            top={{
                                base: '-5%',
                                lg: '-5%',
                                xl: '-4%',
                            }}
                            zIndex="-2"
                        ></Box>
                        <Flex
                            pl="20px"
                            pr="10px"
                            py="0"
                            align="center"
                            justifyContent="space-between"
                            alignItems="center"
                            mx="auto"
                        >
                            <Flex
                                h={{ base: '70px', md: '70px' }}
                                alignItems="center"
                                justifyContent="flex-start"
                            >
                                <PreFlight
                                    width="60px"
                                    height="60px"
                                    fill={useColorModeValue(
                                        '#CED9DF',
                                        'dark.80'
                                    )}
                                    py="0"
                                    mr="7px"
                                    color={logo}
                                />
                                <Flex>
                                    <Text
                                        fontSize="2xl"
                                        color={useColorModeValue(
                                            'brand.200',
                                            'dark.80'
                                        )}
                                        fontWeight="light"
                                        letterSpacing="1px"
                                        alignSelf="center"
                                    >
                                        Pre
                                    </Text>
                                    <Text
                                        fontSize="2xl"
                                        ml="0"
                                        color={useColorModeValue(
                                            'brand.200',
                                            'dark.80'
                                        )}
                                        fontWeight="semibold"
                                        letterSpacing="1px"
                                        alignSelf="center"
                                    >
                                        Flight
                                    </Text>
                                    {JSON.parse(
                                        process.env.REACT_APP_DEV_ENV.toLowerCase()
                                    ) && (
                                        <Text
                                            fontSize="0.7rem"
                                            ml="5px"
                                            mb="11px"
                                            color="gray.400"
                                            fontWeight="semibold"
                                            letterSpacing="1px"
                                            alignSelf="center"
                                        >
                                            DEV
                                        </Text>
                                    )}
                                </Flex>
                            </Flex>
                            <CloseButton
                                {...noFocus}
                                fontSize={{ base: '1.1rem', md: '0.9rem' }}
                                opacity="0.6"
                                onClick={() => {
                                    setSettingsMenuOpen(false)
                                }}
                                color={useColorModeValue(
                                    'light.100',
                                    'dark.80'
                                )}
                                _hover={{ opacity: '1' }}
                            />
                        </Flex>

                        <Profile
                            mfaTimeToExpire={mfaState.state}
                            showMfaBadge={showMfaBadge}
                        />
                        <Divider
                            height="0px !important"
                            borderColor={divider}
                            opacity={useColorModeValue('0.15', '0.2')}
                        />
                    </div>
                    <div id="sidebar-content" style={{ marginBottom: 'auto' }}>
                        <MainNav />
                    </div>

                    <div id="sidebar-footer">
                        {/* SERVICE MESSAGES */}
                        <Divider
                            height="0px !important"
                            borderColor={divider}
                            opacity={useColorModeValue('0.15', '0.2')}
                        />
                        <Flex
                            {...noFocus}
                            onClick={() => {
                                if (hasServicesMessages) {
                                    setViewLoginMessageModal(true)
                                    setSettingsMenuOpen(false)
                                }
                            }}
                            pl={6}
                            pr="16px"
                            py="12px"
                            _hover={{
                                color: hasServicesMessages,
                                cursor: hasServicesMessages
                                    ? 'pointer'
                                    : 'default',
                                background: hasServicesMessages && MenuHover,
                            }}
                            opacity={hasServicesMessages ? '1' : '0.3'}
                            color={menuText}
                            alignItems="center"
                        >
                            <Flex width="22px" minWidth="22px" height="22px">
                                <ServiceMessagesIcon
                                    width="22px"
                                    height="22px"
                                />
                            </Flex>
                            <Flex
                                justifyContent="space-between"
                                width="100%"
                                alignItems="center"
                            >
                                <Box
                                    fontSize={{
                                        base: '14px',
                                        md: '0.85rem',
                                    }}
                                    fontWeight={'400'}
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    flex="1"
                                    textAlign="left"
                                    paddingLeft="14px"
                                >
                                    Service Messages
                                </Box>

                                <Tooltip
                                    label={
                                        !hasServicesMessages
                                            ? 'No messages'
                                            : readMessages
                                            ? 'All current messages read'
                                            : messages?.length >
                                              newMessages?.length
                                            ? `${
                                                  newMessages?.length +
                                                  ' new and ' +
                                                  (messages?.length -
                                                      newMessages?.length) +
                                                  ' read messages'
                                              }`
                                            : newMessages?.length === 1
                                            ? '1 new message'
                                            : `${newMessages?.length} new messages`
                                    }
                                    hasArrow
                                    arrowSize={10}
                                    borderRadius="7px"
                                    height="24px"
                                    fontSize="0.75rem"
                                    alignItems="center"
                                    display={isDesktop ? 'flex' : 'none'}
                                    placement="top-end"
                                    m="5px"
                                    offset={[20, 10]}
                                    color="light.10"
                                    background={'gray.500'}
                                >
                                    {hasServicesMessages ? (
                                        <Badge
                                            marginTop="0px"
                                            bg={
                                                readMessages
                                                    ? 'light.200'
                                                    : 'light.200'
                                            }
                                            border="1.8px solid"
                                            borderColor={'light.200'}
                                            variant="solid"
                                            color={
                                                readMessages
                                                    ? 'light.10'
                                                    : 'light.10'
                                            }
                                            borderRadius="10"
                                            fontSize="0.75rem"
                                            fontWeight="700"
                                            height="20px"
                                            minWidth="20px"
                                            padding="0px"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {messages?.length}
                                        </Badge>
                                    ) : (
                                        <Badge
                                            marginTop="0px"
                                            bg="transparent"
                                            variant="solid"
                                            color={menuText}
                                            opacity="0.7"
                                            borderRadius="10"
                                            px="4px"
                                            pb="0px"
                                            pt="1px"
                                            fontSize="0.7rem"
                                            fontWeight="400"
                                            height="18px"
                                            minWidth="18px"
                                        >
                                            None
                                        </Badge>
                                    )}
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <SideBarFooterContent />
                    </div>
                </Flex>
            </OverlayScrollbarsComponent>
        </Flex>
    )

    const NavItem = (props) => {
        const { icon, children, ...rest } = props
        const hoverColor = useColorModeValue('light.200', 'dark.200')

        return (
            <Flex
                align="center"
                px="4"
                pl="4"
                py="3"
                cursor="pointer"
                color={useColorModeValue('light.100', 'dark.100')}
                _hover={{
                    bg: useColorModeValue('light.20', 'dark.20'),
                    color: useColorModeValue('light.200', 'dark.200'),
                }}
                role="group"
                fontWeight="semibold"
                transition=".15s ease"
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="2"
                        boxSize="4"
                        _groupHover={{
                            color: hoverColor,
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        )
    }

    function MainNav() {
        const isAdmin = useAdminRole()
        const isMetAdmin = useMetAdminRole()
        const noFocus = {
            _focus: { boxShadow: 'none' },
            _focusVisible: { boxShadow: 'none !important' },
        }

        const menuText = useColorModeValue('light.100', 'dark.100')
        const menuTextExpanded = useColorModeValue('light.80', 'dark.80')
        const MenuHover = useColorModeValue('light.20', 'dark.20')
        const subMenu = useColorModeValue('light.30', 'dark.30')
        const subMenuHover = useColorModeValue('light.40', 'dark.40')
        const subMenuTextHover = useColorModeValue('light.200', 'dark.200')
        const dividerColor = useColorModeValue('light.100', 'dark.100')
        const divider = useColorModeValue('0.07', '0.1')

        const toggleButtonColorOff = useColorModeValue('gray.200', 'dark.40')
        const toggleButtonColorOffHover = useColorModeValue(
            'gray.300',
            'light.41'
        )
        const toggleButtonColorOn = useColorModeValue('light.200', 'dark.40')
        const toggleButtonColorOnHover = useColorModeValue(
            'light.201',
            'light.41'
        )
        const toggleIndicatorOff = useColorModeValue('light.10', 'dark.42')
        const toggleIndicatorOn = useColorModeValue('light.10', 'dark.100')

        // TOGGLE SWITCH
        const toggleSwitch = {
            color: '#a8b2c0',
            variant: 'outline',
            fontSize: '10px',
            fontWeight: '600',
            height: '20px',
            width: '42px',
            style: {
                minWidth: '42px',
            },
            justifyContent: 'initial',
            paddingLeft: '2px',
            paddingRight: '2px',
            py: '2px',
            borderRadius: '20px',
            border: 'none',
            boxShadow: 'inset 0px 0px 3px -1px rgba(0,0,0,0.4)',
            _focus: {
                boxShadow: 'inset 0px 0px 3px -1px rgba(0,0,0,0.4)',
            },
            _active: {
                background: 'light.200',
                boxShadow: 'inset 0px 0px 3px -1px rgba(0,0,0,0.4)',
            },
        }

        const toggleCheck = {
            mt: '0px',
            pl: '2px',
            mr: '-18px',
            color: useColorModeValue('light.10', 'dark.200'),
            _selected: {
                opacity: '0.7',
            },
            _active: {
                opacity: '0.7',
            },
            transition: 'opacity 300ms',
        }

        const toggleIndicator = {
            mt: '0px',
            ml: '0px',
            mr: '0px',
            height: '16px',
            width: '18px',
            borderRadius: '20px',
            boxShadow: '0px 0px 3px -1px rgba(0,0,0,0.3)',
            transition: 'transform 300ms',
        }

        const toggleOnIcon = {
            zIndex: '1',
            mt: '1px',
            ml: '-1px',
            color: 'light.200',
            _selected: {
                opacity: '1',
            },
            _active: {
                opacity: '1',
            },
            transition: 'opacity all 300ms',
        }

        return (
            <Flex
                pt="10px"
                pb="10px"
                direction="column"
                as="nav"
                fontSize="0.8rem"
                color={menuText}
                aria-label="Main Navigation"

                // bg={admin.isOpen && menuActive}
                // color={admin.isOpen && menuText}
            >
                <Accordion
                    // defaultIndex={[5]}
                    borderBottom="none"
                    allowToggle
                >
                    {(isAdmin || isMetAdmin) && (
                        <>
                            <AccordionItem
                                // borderColor="rgba(150, 150, 150, 0.2)"
                                borderStyle="none"
                            >
                                {({ isExpanded }) => (
                                    <>
                                        <h2>
                                            <AccordionButton
                                                {...noFocus}
                                                pl={6}
                                                pr={4}
                                                py="11px"
                                                _hover={{
                                                    color: menuTextExpanded,
                                                    background: MenuHover,
                                                }}
                                                color={
                                                    isExpanded
                                                        ? menuTextExpanded
                                                        : menuText
                                                }
                                                background={
                                                    isExpanded && MenuHover
                                                }
                                            >
                                                <AdminIcon
                                                    width="22px"
                                                    height="22px"
                                                />
                                                <Box
                                                    fontSize={{
                                                        base: '14px',
                                                        md: '0.85rem',
                                                    }}
                                                    fontWeight={
                                                        isExpanded
                                                            ? '500'
                                                            : '400'
                                                    }
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    flex="1"
                                                    textAlign="left"
                                                    paddingLeft="14px"
                                                >
                                                    Admin
                                                </Box>
                                                {isExpanded ? (
                                                    <AccordionIcon transform="rotate(0deg)" />
                                                ) : (
                                                    <AccordionIcon transform="rotate(-90deg)" />
                                                )}
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            background={subMenu}
                                            boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                            pt={2}
                                            pb={2}
                                            px={0}
                                        >
                                            <Box
                                                _hover={{
                                                    color: subMenuTextHover,
                                                }}
                                                onClick={() => {
                                                    setShowDataStatus(true)
                                                    setSettingsMenuOpen(false)
                                                }}
                                            >
                                                <NavItem
                                                    fontWeight="400"
                                                    pl="60px"
                                                    py="2"
                                                    _hover={{
                                                        color: subMenuTextHover,
                                                        background:
                                                            subMenuHover,
                                                    }}
                                                >
                                                    Data Status
                                                </NavItem>
                                            </Box>
                                            <Box
                                                _hover={{
                                                    color: subMenuTextHover,
                                                }}
                                                onClick={() => {
                                                    setLoginMessageModal(true)
                                                    setSettingsMenuOpen(false)
                                                }}
                                            >
                                                <NavItem
                                                    fontWeight="400"
                                                    pl="60px"
                                                    py="2"
                                                    _hover={{
                                                        color: subMenuTextHover,
                                                        background:
                                                            subMenuHover,
                                                    }}
                                                >
                                                    Manage Login Messages
                                                </NavItem>
                                            </Box>
                                            <Box
                                                _hover={{
                                                    color: subMenuTextHover,
                                                }}
                                                onClick={() => {
                                                    setUserReportModal(true)
                                                    setSettingsMenuOpen(false)
                                                }}
                                            >
                                                <NavItem
                                                    fontWeight="400"
                                                    pl="60px"
                                                    py="2"
                                                    _hover={{
                                                        color: subMenuTextHover,
                                                        background:
                                                            subMenuHover,
                                                    }}
                                                >
                                                    Get User Report
                                                </NavItem>
                                            </Box>
                                            {isAdmin && (
                                                <>
                                                    <Divider
                                                        height="0px !important"
                                                        borderColor={
                                                            dividerColor
                                                        }
                                                        opacity={divider}
                                                        my="6px"
                                                    />
                                                    <Text
                                                        pb={1}
                                                        pl="24px"
                                                        fontWeight="300"
                                                        fontSize="11px"
                                                        opacity="0.6"
                                                        color="light.300"
                                                    >
                                                        Dev Admin Only
                                                    </Text>

                                                    <Box
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setShowDeviceDetails(
                                                                true
                                                            )
                                                            setSettingsMenuOpen(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        <NavItem
                                                            fontWeight="400"
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                        >
                                                            Device Details
                                                        </NavItem>
                                                    </Box>

                                                    <Box
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setShowDeviceWarning(
                                                                true
                                                            )
                                                            setSettingsMenuOpen(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        <NavItem
                                                            fontWeight="400"
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                        >
                                                            Simulate Old Device
                                                            Warning
                                                        </NavItem>
                                                    </Box>

                                                    <Box
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setShowAerodromeSprites(
                                                                true
                                                            )
                                                            setSettingsMenuOpen(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        <NavItem
                                                            fontWeight="400"
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                        >
                                                            Dynamic Aerodrome
                                                            Sprites
                                                        </NavItem>
                                                    </Box>
                                                    <Box
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setShowRunways(true)
                                                            setSettingsMenuOpen(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        <NavItem
                                                            fontWeight="400"
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                        >
                                                            Runways
                                                        </NavItem>
                                                    </Box>

                                                    <Accordion // MFA
                                                        // defaultIndex={[5]}
                                                        borderBottom="none"
                                                        allowToggle
                                                    >
                                                        <AccordionItem
                                                            // borderColor="rgba(150, 150, 150, 0.2)"
                                                            borderStyle="none"
                                                        >
                                                            {({
                                                                isExpanded,
                                                            }) => (
                                                                <>
                                                                    <h2>
                                                                        <AccordionButton
                                                                            {...noFocus}
                                                                            pl={
                                                                                6
                                                                            }
                                                                            pr={
                                                                                4
                                                                            }
                                                                            py="7px"
                                                                            _hover={{
                                                                                color: subMenuTextHover,
                                                                                background:
                                                                                    subMenuHover,
                                                                            }}
                                                                            color={
                                                                                isExpanded
                                                                                    ? subMenuTextHover
                                                                                    : menuText
                                                                            }
                                                                            background={
                                                                                isExpanded &&
                                                                                subMenuHover
                                                                            }
                                                                        >
                                                                            <Box
                                                                                fontSize={{
                                                                                    base: '14px',
                                                                                    md: '0.85rem',
                                                                                }}
                                                                                fontWeight={
                                                                                    isExpanded
                                                                                        ? '500'
                                                                                        : '400'
                                                                                }
                                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                                flex="1"
                                                                                textAlign="left"
                                                                                paddingLeft="9"
                                                                            >
                                                                                MFA
                                                                                Resets
                                                                            </Box>
                                                                            {isExpanded ? (
                                                                                <AccordionIcon transform="rotate(0deg)" />
                                                                            ) : (
                                                                                <AccordionIcon transform="rotate(-90deg)" />
                                                                            )}
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel
                                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                        background={
                                                                            subMenu
                                                                        }
                                                                        boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                                                        pt={2}
                                                                        pb={2}
                                                                        px={0}
                                                                    >
                                                                        <Flex
                                                                            flexDirection="column"
                                                                            pt="2"
                                                                            pb="1"
                                                                            cursor="default"
                                                                            gap="3px"
                                                                        >
                                                                            <Flex pl="60px">
                                                                                <Text
                                                                                    height="24px"
                                                                                    fontWeight="500"
                                                                                    fontSize="0.8rem"
                                                                                    color="gray.400"
                                                                                    minWidth="60px"
                                                                                >
                                                                                    Mobile:
                                                                                </Text>
                                                                                <Text
                                                                                    width="100%"
                                                                                    height="24px"
                                                                                    pl="5px"
                                                                                    fontWeight="300"
                                                                                    fontSize="0.8rem"
                                                                                    color="gray.500"
                                                                                    letterSpacing="0.5px"
                                                                                >
                                                                                    {
                                                                                        mobileNumber
                                                                                    }
                                                                                </Text>
                                                                            </Flex>
                                                                            <Flex pl="60px">
                                                                                <Text
                                                                                    height="24px"
                                                                                    fontWeight="500"
                                                                                    fontSize="0.8rem"
                                                                                    color="gray.400"
                                                                                    minWidth="60px"
                                                                                >
                                                                                    Verified:
                                                                                </Text>
                                                                                <Flex flexDirection="column">
                                                                                    <Text
                                                                                        width="100%"
                                                                                        height="20px"
                                                                                        pl="5px"
                                                                                        fontWeight="300"
                                                                                        fontSize="0.8rem"
                                                                                        color={
                                                                                            mfaExpired
                                                                                                ? 'red.500'
                                                                                                : 'gray.500'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            lastVerifiedDate
                                                                                        }
                                                                                    </Text>
                                                                                    <Text
                                                                                        width="100%"
                                                                                        height="24px"
                                                                                        pl="5px"
                                                                                        fontWeight="300"
                                                                                        fontSize="0.75rem"
                                                                                        color={
                                                                                            mfaExpired
                                                                                                ? 'red.400'
                                                                                                : 'gray.400'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            expiryDateTimeSince
                                                                                        }
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Flex>
                                                                            <Flex pl="60px">
                                                                                <Text
                                                                                    height="24px"
                                                                                    fontWeight="500"
                                                                                    fontSize="0.8rem"
                                                                                    color="gray.400"
                                                                                    minWidth="60px"
                                                                                >
                                                                                    {mfaExpired
                                                                                        ? 'Expired:'
                                                                                        : 'Expires:'}
                                                                                </Text>
                                                                                <Flex flexDirection="column">
                                                                                    <Text
                                                                                        width="100%"
                                                                                        height="20px"
                                                                                        pl="5px"
                                                                                        fontWeight="300"
                                                                                        fontSize="0.8rem"
                                                                                        color={
                                                                                            mfaExpired
                                                                                                ? 'red.500'
                                                                                                : 'gray.500'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            expiryDate
                                                                                        }
                                                                                    </Text>
                                                                                    <Text
                                                                                        width="100%"
                                                                                        height="24px"
                                                                                        pl="5px"
                                                                                        fontWeight="300"
                                                                                        fontSize="0.75rem"
                                                                                        color={
                                                                                            mfaExpired
                                                                                                ? 'red.400'
                                                                                                : 'gray.400'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            expiryDateTimeRemaining
                                                                                        }
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Flex>
                                                                        </Flex>
                                                                        <Divider
                                                                            height="0px !important"
                                                                            borderColor={
                                                                                dividerColor
                                                                            }
                                                                            opacity={
                                                                                divider
                                                                            }
                                                                            my="6px"
                                                                        />
                                                                        <Text
                                                                            pb={
                                                                                1
                                                                            }
                                                                            pl="24px"
                                                                            fontWeight="300"
                                                                            fontSize="11px"
                                                                            opacity="0.6"
                                                                            color="light.300"
                                                                        >
                                                                            Used
                                                                            For
                                                                            Testing
                                                                        </Text>
                                                                        <Link
                                                                            {...noFocus}
                                                                            _hover={{
                                                                                color: subMenuTextHover,
                                                                            }}
                                                                            onClick={() => {
                                                                                setShowResetMfaModal(
                                                                                    true
                                                                                )
                                                                                setSettingsMenuOpen(
                                                                                    false
                                                                                )
                                                                            }}
                                                                        >
                                                                            <NavItem
                                                                                fontWeight="400"
                                                                                pl="60px"
                                                                                py="2"
                                                                                _hover={{
                                                                                    color: subMenuTextHover,
                                                                                    background:
                                                                                        subMenuHover,
                                                                                }}
                                                                            >
                                                                                <Flex
                                                                                    justifyContent="space-between"
                                                                                    alignItems="center"
                                                                                    width="100%"
                                                                                >
                                                                                    <Text>
                                                                                        Open
                                                                                        MFA
                                                                                        Modal
                                                                                    </Text>
                                                                                </Flex>
                                                                            </NavItem>
                                                                        </Link>

                                                                        <Link
                                                                            {...noFocus}
                                                                            _hover={{
                                                                                color: subMenuTextHover,
                                                                            }}
                                                                            onClick={() => {
                                                                                setProfileData(
                                                                                    (
                                                                                        prevState
                                                                                    ) => {
                                                                                        const profile =
                                                                                            prevState ||
                                                                                            {}
                                                                                        const mfa_last_verified =
                                                                                            profileData.mfaLastVerified !==
                                                                                            undefined
                                                                                                ? !profileData.mfaLastVerified
                                                                                                : true
                                                                                        return {
                                                                                            ...prevState,

                                                                                            ...profile,
                                                                                            mfa_last_verified,
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            <NavItem
                                                                                fontWeight="400"
                                                                                pl="60px"
                                                                                py="2"
                                                                                _hover={{
                                                                                    color: subMenuTextHover,
                                                                                    background:
                                                                                        subMenuHover,
                                                                                }}
                                                                            >
                                                                                <Flex
                                                                                    justifyContent="space-between"
                                                                                    alignItems="center"
                                                                                    width="100%"
                                                                                >
                                                                                    <Text>
                                                                                        Clear
                                                                                        Last
                                                                                        Verified
                                                                                        Date
                                                                                        Only
                                                                                    </Text>
                                                                                </Flex>
                                                                            </NavItem>
                                                                        </Link>

                                                                        <Link
                                                                            {...noFocus}
                                                                            _hover={{
                                                                                color: subMenuTextHover,
                                                                            }}
                                                                            onClick={() => {
                                                                                setProfileData(
                                                                                    (
                                                                                        prevState
                                                                                    ) => {
                                                                                        const profile =
                                                                                            prevState ||
                                                                                            {}

                                                                                        const mobile_number =
                                                                                            profileData.mobileNumber !==
                                                                                            undefined
                                                                                                ? !profileData.mobileNumber
                                                                                                : true
                                                                                        const mfa_last_verified =
                                                                                            profileData.mfaLastVerified !==
                                                                                            undefined
                                                                                                ? !profileData.mfaLastVerified
                                                                                                : true
                                                                                        return {
                                                                                            ...prevState,
                                                                                            ...profile,
                                                                                            mobile_number,
                                                                                            mfa_last_verified,
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            <NavItem
                                                                                fontWeight="400"
                                                                                pl="60px"
                                                                                py="2"
                                                                                _hover={{
                                                                                    color: subMenuTextHover,
                                                                                    background:
                                                                                        subMenuHover,
                                                                                }}
                                                                            >
                                                                                <Flex
                                                                                    justifyContent="space-between"
                                                                                    alignItems="center"
                                                                                    width="100%"
                                                                                >
                                                                                    <Text>
                                                                                        Clear
                                                                                        Last
                                                                                        Verified
                                                                                        Date
                                                                                        and
                                                                                        Mobile
                                                                                    </Text>
                                                                                </Flex>
                                                                            </NavItem>
                                                                        </Link>
                                                                    </AccordionPanel>
                                                                </>
                                                            )}
                                                        </AccordionItem>
                                                    </Accordion>

                                                    <Flex
                                                        fontWeight="400"
                                                        cursor="pointer"
                                                        justifyContent="space-between"
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() =>
                                                            setShowErrorToggles(
                                                                (prevState) =>
                                                                    !prevState
                                                            )
                                                        }
                                                    >
                                                        <Flex
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            Error Toggles
                                                            <Box my="auto">
                                                                <Button
                                                                    marginTop="-3px"
                                                                    marginRight="20px"
                                                                    {...toggleSwitch}
                                                                    background={
                                                                        showErrorToggles
                                                                            ? toggleButtonColorOn
                                                                            : toggleButtonColorOff
                                                                    }
                                                                    _hover={{
                                                                        background:
                                                                            showErrorToggles
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            showErrorToggles
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            showErrorToggles
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        {...toggleCheck}
                                                                        opacity={
                                                                            showErrorToggles
                                                                                ? '0.6'
                                                                                : '0'
                                                                        }
                                                                    >
                                                                        <GoCheck fontSize="1rem" />
                                                                    </Box>
                                                                    <Box
                                                                        {...toggleIndicator}
                                                                        background={
                                                                            showErrorToggles
                                                                                ? toggleIndicatorOn
                                                                                : toggleIndicatorOff
                                                                        }
                                                                        transform={
                                                                            showErrorToggles
                                                                                ? 'translateX(110%)'
                                                                                : 'translateX(0%)'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            {...toggleOnIcon}
                                                                            opacity={
                                                                                showErrorToggles
                                                                                    ? '1'
                                                                                    : '0.4'
                                                                            }
                                                                        ></Box>
                                                                    </Box>
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        fontWeight="400"
                                                        cursor="pointer"
                                                        justifyContent="space-between"
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setSimulateFreeUser(
                                                                (prevState) =>
                                                                    !prevState
                                                            )

                                                            setSettingsMenuOpen(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        <Flex
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            Simulate Free User
                                                            <Box my="auto">
                                                                <Button
                                                                    marginTop="-3px"
                                                                    marginRight="20px"
                                                                    {...toggleSwitch}
                                                                    background={
                                                                        simulateFreeUser
                                                                            ? toggleButtonColorOn
                                                                            : toggleButtonColorOff
                                                                    }
                                                                    _hover={{
                                                                        background:
                                                                            simulateFreeUser
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            simulateFreeUser
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            simulateFreeUser
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        {...toggleCheck}
                                                                        opacity={
                                                                            simulateFreeUser
                                                                                ? '0.6'
                                                                                : '0'
                                                                        }
                                                                    >
                                                                        <GoCheck fontSize="1rem" />
                                                                    </Box>
                                                                    <Box
                                                                        {...toggleIndicator}
                                                                        background={
                                                                            simulateFreeUser
                                                                                ? toggleIndicatorOn
                                                                                : toggleIndicatorOff
                                                                        }
                                                                        transform={
                                                                            simulateFreeUser
                                                                                ? 'translateX(110%)'
                                                                                : 'translateX(0%)'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            {...toggleOnIcon}
                                                                            opacity={
                                                                                simulateFreeUser
                                                                                    ? '1'
                                                                                    : '0.4'
                                                                            }
                                                                        ></Box>
                                                                    </Box>
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        fontWeight="400"
                                                        cursor="pointer"
                                                        justifyContent="space-between"
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={
                                                            toggleMapBounds
                                                        }
                                                    >
                                                        <Flex
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            Disable Map Bounds
                                                            <Box my="auto">
                                                                <Button
                                                                    marginTop="-3px"
                                                                    marginRight="20px"
                                                                    {...toggleSwitch}
                                                                    background={
                                                                        !limitMapBounds
                                                                            ? toggleButtonColorOn
                                                                            : toggleButtonColorOff
                                                                    }
                                                                    _hover={{
                                                                        background:
                                                                            !limitMapBounds
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            !limitMapBounds
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            !limitMapBounds
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        {...toggleCheck}
                                                                        opacity={
                                                                            !limitMapBounds
                                                                                ? '0.6'
                                                                                : '0'
                                                                        }
                                                                    >
                                                                        <GoCheck fontSize="1rem" />
                                                                    </Box>
                                                                    <Box
                                                                        {...toggleIndicator}
                                                                        background={
                                                                            !limitMapBounds
                                                                                ? toggleIndicatorOn
                                                                                : toggleIndicatorOff
                                                                        }
                                                                        transform={
                                                                            !limitMapBounds
                                                                                ? 'translateX(110%)'
                                                                                : 'translateX(0%)'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            {...toggleOnIcon}
                                                                            opacity={
                                                                                !limitMapBounds
                                                                                    ? '1'
                                                                                    : '0.4'
                                                                            }
                                                                        ></Box>
                                                                    </Box>
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        fontWeight="400"
                                                        cursor="pointer"
                                                        justifyContent="space-between"
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setDaylighTableSource(
                                                                (prevState) =>
                                                                    !prevState
                                                            )
                                                        }}
                                                    >
                                                        <Flex
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            Disable USNO Data
                                                            Source
                                                            <Box my="auto">
                                                                <Button
                                                                    marginTop="-3px"
                                                                    marginRight="20px"
                                                                    {...toggleSwitch}
                                                                    background={
                                                                        daylighTableSource
                                                                            ? toggleButtonColorOn
                                                                            : toggleButtonColorOff
                                                                    }
                                                                    _hover={{
                                                                        background:
                                                                            daylighTableSource
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            daylighTableSource
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            daylighTableSource
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        {...toggleCheck}
                                                                        opacity={
                                                                            daylighTableSource
                                                                                ? '0.6'
                                                                                : '0'
                                                                        }
                                                                    >
                                                                        <GoCheck fontSize="1rem" />
                                                                    </Box>
                                                                    <Box
                                                                        {...toggleIndicator}
                                                                        background={
                                                                            daylighTableSource
                                                                                ? toggleIndicatorOn
                                                                                : toggleIndicatorOff
                                                                        }
                                                                        transform={
                                                                            daylighTableSource
                                                                                ? 'translateX(110%)'
                                                                                : 'translateX(0%)'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            {...toggleOnIcon}
                                                                            opacity={
                                                                                daylighTableSource
                                                                                    ? '1'
                                                                                    : '0.4'
                                                                            }
                                                                        ></Box>
                                                                    </Box>
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        fontWeight="400"
                                                        cursor="pointer"
                                                        justifyContent="space-between"
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        onClick={() => {
                                                            setWipMenuItems(
                                                                (prevState) =>
                                                                    !prevState
                                                            )
                                                        }}
                                                    >
                                                        <Flex
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            WIP Menu Items
                                                            <Box my="auto">
                                                                <Button
                                                                    marginTop="-3px"
                                                                    marginRight="20px"
                                                                    {...toggleSwitch}
                                                                    background={
                                                                        wipMenuItems
                                                                            ? toggleButtonColorOn
                                                                            : toggleButtonColorOff
                                                                    }
                                                                    _hover={{
                                                                        background:
                                                                            wipMenuItems
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            wipMenuItems
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            wipMenuItems
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        {...toggleCheck}
                                                                        opacity={
                                                                            wipMenuItems
                                                                                ? '0.6'
                                                                                : '0'
                                                                        }
                                                                    >
                                                                        <GoCheck fontSize="1rem" />
                                                                    </Box>
                                                                    <Box
                                                                        {...toggleIndicator}
                                                                        background={
                                                                            wipMenuItems
                                                                                ? toggleIndicatorOn
                                                                                : toggleIndicatorOff
                                                                        }
                                                                        transform={
                                                                            wipMenuItems
                                                                                ? 'translateX(110%)'
                                                                                : 'translateX(0%)'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            {...toggleOnIcon}
                                                                            opacity={
                                                                                wipMenuItems
                                                                                    ? '1'
                                                                                    : '0.4'
                                                                            }
                                                                        ></Box>
                                                                    </Box>
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        _hover={{
                                                            color: subMenuTextHover,
                                                        }}
                                                        fontWeight="400"
                                                        cursor="pointer"
                                                        onClick={() => {
                                                            setShowOnlineUsersButton(
                                                                (prevState) =>
                                                                    !prevState
                                                            )
                                                        }}
                                                    >
                                                        <Flex
                                                            pl="60px"
                                                            py="2"
                                                            _hover={{
                                                                color: subMenuTextHover,
                                                                background:
                                                                    subMenuHover,
                                                            }}
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            <Text>
                                                                Online Users
                                                                Button
                                                            </Text>
                                                            <Box my="auto">
                                                                <Button
                                                                    marginTop="-3px"
                                                                    marginRight="20px"
                                                                    {...toggleSwitch}
                                                                    background={
                                                                        showOnlineUsersButton
                                                                            ? toggleButtonColorOn
                                                                            : toggleButtonColorOff
                                                                    }
                                                                    _hover={{
                                                                        background:
                                                                            showOnlineUsersButton
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            showOnlineUsersButton
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            showOnlineUsersButton
                                                                                ? toggleButtonColorOnHover
                                                                                : toggleButtonColorOffHover,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        {...toggleCheck}
                                                                        opacity={
                                                                            showOnlineUsersButton
                                                                                ? '0.6'
                                                                                : '0'
                                                                        }
                                                                    >
                                                                        <GoCheck fontSize="1rem" />
                                                                    </Box>
                                                                    <Box
                                                                        {...toggleIndicator}
                                                                        background={
                                                                            showOnlineUsersButton
                                                                                ? toggleIndicatorOn
                                                                                : toggleIndicatorOff
                                                                        }
                                                                        transform={
                                                                            showOnlineUsersButton
                                                                                ? 'translateX(110%)'
                                                                                : 'translateX(0%)'
                                                                        }
                                                                    >
                                                                        <Box
                                                                            {...toggleOnIcon}
                                                                            opacity={
                                                                                showOnlineUsersButton
                                                                                    ? '1'
                                                                                    : '0.4'
                                                                            }
                                                                        ></Box>
                                                                    </Box>
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                </>
                                            )}
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        </>
                    )}

                    <AccordionItem
                        // borderColor="rgba(150, 150, 150, 0.2)"
                        borderStyle="none"
                    >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton
                                        {...noFocus}
                                        pl={6}
                                        pr={4}
                                        py="11px"
                                        _hover={{
                                            color: menuTextExpanded,
                                            background: MenuHover,
                                        }}
                                        color={
                                            isExpanded
                                                ? menuTextExpanded
                                                : menuText
                                        }
                                        background={isExpanded && MenuHover}
                                    >
                                        <AipIcon width="22px" height="22px" />
                                        <Box
                                            fontSize={{
                                                base: '14px',
                                                md: '0.85rem',
                                            }}
                                            fontWeight={
                                                isExpanded ? '500' : '400'
                                            }
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            flex="1"
                                            textAlign="left"
                                            paddingLeft="14px"
                                        >
                                            <Flex
                                                width="100%"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                AIP
                                                {/* <Badge
                                                    opacity={
                                                        isExpanded ? '0' : '1'
                                                    }
                                                    visibility={
                                                        isExpanded
                                                            ? 'hidden'
                                                            : 'visible'
                                                    }
                                                    transition="all ease 200ms, opacity ease 300ms, visibility ease 300ms"
                                                    mr="11px"
                                                    variant="solid"
                                                    background="warning.100"
                                                    color="white"
                                                    borderRadius="xl"
                                                    height="20px"
                                                    maxHeight="20px"
                                                    width="20px"
                                                    minWidth="20px"
                                                    textAlign="center"
                                                    fontSize={
                                                        isDesktop
                                                            ? '0rem'
                                                            : '0.8rem'
                                                    }
                                                    fontWeight="600"
                                                    letterSpacing="0.5px"
                                                    justifyContent="center"
                                                    overflow="hidden"
                                                    alignItems="center"
                                                    display="flex"
                                                    paddingStart="3px"
                                                    paddingEnd={
                                                        isDesktop
                                                            ? '0px'
                                                            : '3px'
                                                    }
                                                    gap={
                                                        isDesktop
                                                            ? '2px'
                                                            : '0px'
                                                    }
                                                    _hover={
                                                        isDesktop && {
                                                            width: '58px',
                                                            fontSize: '0.75rem',
                                                            paddingEnd: '4px',
                                                        }
                                                    }
                                                >
                                                    <HiOutlineSpeakerphone
                                                        fontSize="14px"
                                                        color="white"
                                                    />
                                                    <Text
                                                        display={
                                                            isDesktop
                                                                ? 'block'
                                                                : 'none'
                                                        }
                                                    >
                                                        NEW
                                                    </Text>
                                                </Badge> */}
                                            </Flex>
                                        </Box>
                                        {isExpanded ? (
                                            <AccordionIcon transform="rotate(0deg)" />
                                        ) : (
                                            <AccordionIcon transform="rotate(-90deg)" />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    background={subMenu}
                                    boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                    pt={2}
                                    pb={2}
                                    px={0}
                                >
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => handlePDFState('sup')}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>AIP Supplements</Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    {/* <Link // *** SAVED FOR FUTURE USE FOR ANOTHER TEMP SUP ETC. ***
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() =>
                                            handlePDFState('sup_dawn')
                                        }
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="3"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Flex
                                                    my="-4px"
                                                    width="100%"
                                                    justifyContent="center"
                                                    alignItems="flex-start"
                                                    flexDirection="column"
                                                >
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <Text>
                                                            Additional AIP
                                                            Supplement
                                                        </Text>
                                                        <Badge
                                                            variant="solid"
                                                            background="warning.100"
                                                            color="white"
                                                            paddingStart="6px"
                                                            paddingEnd="6px"
                                                            borderRadius="xl"
                                                            maxHeight="18px"
                                                            minWidth="38px"
                                                            textAlign="center"
                                                            fontSize="0.7rem"
                                                            fontWeight="600"
                                                            letterSpacing="0.5px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            display="flex"
                                                        >
                                                            NEW
                                                        </Badge> 
                                                    </Flex>
                                                    <Text
                                                        opacity="0.5"
                                                        fontSize="0.75rem"
                                                    >
                                                        – Dawn Aerospace RPAS
                                                        Flight Testing
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </NavItem>
                                    </Link> */}

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => handlePDFState('aic')}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Aeronautical Information
                                                    Circulars
                                                </Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() =>
                                            handlePDFState(
                                                'abreviationsanddefinitions'
                                            )
                                        }
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Abbreviations and
                                                    Definitions
                                                </Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() =>
                                            handlePDFState('daylighttables')
                                        }
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Daylight Tables</Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Divider
                                        height="0px !important"
                                        borderColor={dividerColor}
                                        opacity={divider}
                                        my="6px"
                                    />

                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="http://www.aip.net.nz"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            target="_blank"
                                            href="http://www.aip.net.nz"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>AIP Online</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="http://shop.aeropath.aero"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>AIP Shop</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="http://www.aip.net.nz/disclaimer?BackURL=NavWalk.aspx?section=ANR"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Air Navigation Register
                                                </Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>

                    <AccordionItem
                        // borderColor="rgba(150, 150, 150, 0.2)"
                        borderStyle="none"
                    >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton
                                        {...noFocus}
                                        pl={6}
                                        pr={4}
                                        py="11px"
                                        _hover={{
                                            color: menuTextExpanded,
                                            background: MenuHover,
                                        }}
                                        color={
                                            isExpanded
                                                ? menuTextExpanded
                                                : menuText
                                        }
                                        background={isExpanded && MenuHover}
                                    >
                                        <FlightplanIcon
                                            width="22px"
                                            height="22px"
                                        />
                                        <Box
                                            fontSize={{
                                                base: '14px',
                                                md: '0.85rem',
                                            }}
                                            fontWeight={
                                                isExpanded ? '500' : '400'
                                            }
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            flex="1"
                                            textAlign="left"
                                            paddingLeft="14px"
                                        >
                                            Flight Plans
                                        </Box>
                                        {isExpanded ? (
                                            <AccordionIcon transform="rotate(0deg)" />
                                        ) : (
                                            <AccordionIcon transform="rotate(-90deg)" />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    background={subMenu}
                                    boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                    pt={2}
                                    pb={2}
                                    px={0}
                                >
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/flight_plans/vfr.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>VFR Flight Plans</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/flight_plans/vfr_local_flight_index.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Local Flights</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/tms/vfr.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>VFR TMS</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Divider
                                        height="0px !important"
                                        borderColor={dividerColor}
                                        opacity={divider}
                                        my="6px"
                                    />

                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/flight_plans/ifr_domestic.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            target="_blank"
                                            href="http://www.aip.net.nz"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>IFR Domestic</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/flight_plans/ifr_international.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    International or Special
                                                    Events
                                                </Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/tms/ifr.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>IFR TMS</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Divider
                                        height="0px !important"
                                        borderColor={dividerColor}
                                        opacity={divider}
                                        my="6px"
                                    />
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/flight_plans/general_info.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>General Information</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>

                    <AccordionItem
                        // borderColor="rgba(150, 150, 150, 0.2)"
                        borderStyle="none"
                    >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton
                                        {...noFocus}
                                        pl={6}
                                        pr={4}
                                        py="11px"
                                        _hover={{
                                            color: menuTextExpanded,
                                            background: MenuHover,
                                        }}
                                        color={
                                            isExpanded
                                                ? menuTextExpanded
                                                : menuText
                                        }
                                        background={isExpanded && MenuHover}
                                    >
                                        <OriginatorsIcon
                                            width="22px"
                                            height="22px"
                                        />
                                        <Box
                                            fontSize={{
                                                base: '14px',
                                                md: '0.85rem',
                                            }}
                                            fontWeight={
                                                isExpanded ? '500' : '400'
                                            }
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            flex="1"
                                            textAlign="left"
                                            paddingLeft="14px"
                                        >
                                            Originators
                                        </Box>
                                        {isExpanded ? (
                                            <AccordionIcon transform="rotate(0deg)" />
                                        ) : (
                                            <AccordionIcon transform="rotate(-90deg)" />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    background={subMenu}
                                    boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                    pt={2}
                                    pb={2}
                                    px={0}
                                >
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/other/notam_issue.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Issue NOTAM</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/script/other/notam_rpas_issue.asp"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Issue RPAS NOTAM</Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://ifis.airways.co.nz/filebase/shtml/publications/pdfs/NOTAM_Guidelines_for_Operators_&_Orginators_2021.pdf"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    NOTAM Submission Guidelines
                                                </Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Divider
                                        height="0px !important"
                                        borderColor={dividerColor}
                                        opacity={divider}
                                        my="6px"
                                    />
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://originators.aeropath.co.nz/"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Originators Online / AIP
                                                    Change
                                                </Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() =>
                                            handlePDFState('airaccycle2024')
                                        }
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    AIRAC Cycle Dates - 2024
                                                </Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>

                    <AccordionItem
                        // borderColor="rgba(150, 150, 150, 0.2)"
                        borderStyle="none"
                    >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton
                                        {...noFocus}
                                        pl={6}
                                        pr={4}
                                        py="11px"
                                        _hover={{
                                            color: menuTextExpanded,
                                            background: MenuHover,
                                        }}
                                        color={
                                            isExpanded
                                                ? menuTextExpanded
                                                : menuText
                                        }
                                        background={isExpanded && MenuHover}
                                    >
                                        <OtherIcon width="22px" height="22px" />
                                        <Box
                                            fontSize={{
                                                base: '14px',
                                                md: '0.85rem',
                                            }}
                                            fontWeight={
                                                isExpanded ? '500' : '400'
                                            }
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            flex="1"
                                            textAlign="left"
                                            paddingLeft="14px"
                                        >
                                            Other
                                        </Box>
                                        {isExpanded ? (
                                            <AccordionIcon transform="rotate(0deg)" />
                                        ) : (
                                            <AccordionIcon transform="rotate(-90deg)" />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    background={subMenu}
                                    boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                    pt={2}
                                    pb={2}
                                    px={0}
                                >
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            window
                                                .open(
                                                    'https://www.ifis.airways.co.nz/script/other/gps_raim.asp',
                                                    '_blank'
                                                )
                                                .focus()
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    GPS RAIM Predictions
                                                </Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>

                    <AccordionItem
                        // borderColor="rgba(150, 150, 150, 0.2)"
                        borderStyle="none"
                    >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton
                                        {...noFocus}
                                        pl={6}
                                        pr={4}
                                        py="10px"
                                        _hover={{
                                            color: menuTextExpanded,
                                            background: MenuHover,
                                        }}
                                        color={
                                            isExpanded
                                                ? menuTextExpanded
                                                : menuText
                                        }
                                        background={isExpanded && MenuHover}
                                    >
                                        <InfoIcon width="22px" height="22px" />
                                        <Box
                                            fontSize={{
                                                base: '14px',
                                                md: '0.85rem',
                                            }}
                                            fontWeight={
                                                isExpanded ? '500' : '400'
                                            }
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            flex="1"
                                            textAlign="left"
                                            paddingLeft="14px"
                                        >
                                            Help
                                        </Box>
                                        {isExpanded ? (
                                            <AccordionIcon transform="rotate(0deg)" />
                                        ) : (
                                            <AccordionIcon transform="rotate(-90deg)" />
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    background={subMenu}
                                    boxShadow="inset 0px 2px 15px -7px rgba(0, 0, 0, 0.35)"
                                    pt={2}
                                    pb={2}
                                    px={0}
                                >
                                    <Text
                                        pb={1}
                                        pl="24px"
                                        fontWeight="300"
                                        fontSize="11px"
                                        opacity="0.6"
                                        color="light.300"
                                    >
                                        Useful Met Information
                                    </Text>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() =>
                                            handlePDFState('weathercard')
                                        }
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Weather Card</Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => handlePDFState('grafor')}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>GRAFOR User Guide</Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() =>
                                            handlePDFState('gnzsigwx')
                                        }
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Graphical NZ SIGWX User
                                                    Guide
                                                </Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => handlePDFState('vfrmet')}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>VFR MET GAP Booklet</Text>
                                                {/* <PDFIcon
                                                    width="22px"
                                                    height="22px"
                                                /> */}
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        target="_blank"
                                        _hover={{ color: subMenuTextHover }}
                                        href="https://about.metservice.com/our-company/learning-centre/meteorology-for-cpl-pilots/"
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Meteorology for PPL, CPL &
                                                    ATPL
                                                </Text>
                                                <NewTabIcon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Divider
                                        height="0px !important"
                                        borderColor={dividerColor}
                                        opacity={divider}
                                        my="6px"
                                    />
                                    <Text
                                        pb={1}
                                        pl="24px"
                                        fontWeight="300"
                                        fontSize="11px"
                                        opacity="0.6"
                                        color="light.300"
                                    >
                                        About PreFlight
                                    </Text>

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowAnnouncements(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    What's New in PreFlight
                                                </Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowUserIntro(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    PreFlight Introduction Tour
                                                </Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowFAQs(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Frequently Asked Questions
                                                </Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowSpecialNotice(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            color="warning.100"
                                            _hover={{
                                                color: 'warning.200',
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text fontWeight="600">
                                                    Notice for Apple iOS Users
                                                </Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowDeviceRequirements(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Device Requirements</Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowWhyMfa(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    About Multi-factor
                                                    Authentication
                                                </Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Link
                                        {...noFocus}
                                        _hover={{
                                            color: subMenuTextHover,
                                        }}
                                        onClick={() => {
                                            setShowPricingTiers(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Subscription Options
                                                </Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    {/* <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setShowCredits(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Credits</Text>
                                            </Flex>
                                        </NavItem>
                                    </Link> */}

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            window.Userback.open()
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>Contact Us</Text>
                                            </Flex>
                                        </NavItem>
                                    </Link>

                                    <Divider
                                        height="0px !important"
                                        borderColor={dividerColor}
                                        opacity={divider}
                                        my="6px"
                                    />

                                    <Link
                                        {...noFocus}
                                        _hover={{ color: subMenuTextHover }}
                                        onClick={() => {
                                            setBypassLandingPage(false)
                                            setSettingsMenuOpen(false)
                                            document.body.classList.remove(
                                                'app'
                                            )
                                            document.body.classList.add(
                                                'landing-page'
                                            )
                                            navigate('/')
                                        }}
                                    >
                                        <NavItem
                                            fontWeight="400"
                                            pl="60px"
                                            py="2"
                                            _hover={{
                                                color: subMenuTextHover,
                                                background: subMenuHover,
                                            }}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Text>
                                                    Return to PreFlight homepage
                                                </Text>
                                                <Icon
                                                    style={{
                                                        marginRight: '1px',
                                                    }}
                                                    width="18px"
                                                    height="18px"
                                                    as={GoBackIcon}
                                                />
                                            </Flex>
                                        </NavItem>
                                    </Link>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>

                <Flex
                    {...noFocus}
                    onClick={() =>
                        logout({
                            returnTo: `${window.location.origin}`,
                        })
                    }
                    pl={6}
                    pr={4}
                    py={3}
                    _hover={{
                        color: menuTextExpanded,
                        cursor: 'pointer',
                        background: MenuHover,
                    }}
                    color={menuText}
                    alignItems="center"
                >
                    <LogOutIcon width="22px" height="22px" />
                    <Box
                        fontSize={{
                            base: '14px',
                            md: '0.85rem',
                        }}
                        fontWeight={'400'}
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        flex="1"
                        textAlign="left"
                        paddingLeft="14px"
                    >
                        Log Out
                    </Box>
                </Flex>
            </Flex>
        )
    }

    const SideBarFooterContent = (props) => {
        const menuTextExpanded = useColorModeValue('light.80', 'dark.80')
        const MenuHover = useColorModeValue('light.20', 'dark.20')

        return (
            <Flex
                direction="column"
                bottom="0"
                w="100%"
                {...props}
                fontFamily="'Poppins', 'Open Sans', sans-serif"
            >
                <Divider
                    height="0px !important"
                    borderColor={divider}
                    opacity={useColorModeValue('0.15', '0.2')}
                />

                <Flex
                    {...noFocus}
                    onClick={() => {
                        window.Userback.open()
                        setSettingsMenuOpen(false)
                    }}
                    pl={6}
                    pr={4}
                    py={3}
                    _hover={{
                        color: menuTextExpanded,
                        cursor: 'pointer',
                        background: MenuHover,
                    }}
                    color={menuText}
                    alignItems="center"
                >
                    <FeedbackIcon width="22px" height="22px" />
                    <Box
                        fontSize={{ base: '14px', md: '0.85rem' }}
                        fontWeight="400"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        flex="1"
                        textAlign="left"
                        paddingLeft="14px"
                        ml="-1px"
                    >
                        Send Us Feedback
                    </Box>
                </Flex>

                <Divider
                    height="0px !important"
                    borderColor={divider}
                    opacity={useColorModeValue('0.15', '0.2')}
                />

                <Flex
                    alignItems="center"
                    fontSize="0.8rem"
                    fontWeight="300"
                    color={menuText}
                    pl={6}
                    pt={'14px'}
                    pb={'10px'}
                >
                    <Button
                        bg={useColorModeValue('#F2F5F8', 'dark.20')}
                        opacity={
                            isPremium && simulateFreeUser
                                ? '0.6 !important'
                                : '1'
                        }
                        color="#a8b2c0"
                        variant="outline"
                        fontSize="11px"
                        fontWeight="500"
                        height={{ base: '26px', md: '24px' }}
                        width="92px"
                        style={{
                            minWidth: '92px',
                        }}
                        justifyContent="initial"
                        paddingLeft="2px"
                        paddingRight="2px"
                        py="2px"
                        borderRadius="20px"
                        boxShadow="inset 0px 0px 1px 0px rgba(0,0,0,0.35)"
                        border="none"
                        {...noFocus}
                        index={timeZone === 'UTC' ? 0 : 1}
                        onClick={() => {
                            if (mfaExpired) {
                                setShowResetMfaModal(true)
                                setSettingsMenuOpen(false)
                            } else if (isPremium) {
                                setShowPricingTiers(true)
                                setSettingsMenuOpen(false)
                            } else {
                                handleChangeTimeZone((prevState) => !prevState)
                            }
                        }}
                        _active={{
                            bg: useColorModeValue('#F2F5F8', 'dark.30'),
                        }}
                        _focus={{
                            bg: useColorModeValue('#F2F5F8', 'dark.30'),
                        }}
                        _hover={{
                            bg: useColorModeValue('#EDF0F2', 'dark.30'),
                        }}
                    >
                        <Box
                            mt="0px"
                            ml="0px"
                            px="22px"
                            height={{ base: '22px', md: '20px' }}
                            width="40px"
                            background="white"
                            borderRadius="20px"
                            bg={useColorModeValue('#ffffff', 'dark.42')}
                            boxShadow="0px 0px 1px 0px rgba(0,0,0,0.35)"
                            style={{
                                borderColor:
                                    colorMode === 'light'
                                        ? '#E9EAEB'
                                        : '#33424f',
                                transform:
                                    timeZone === 'UTC'
                                        ? 'translateX(0%)'
                                        : 'translateX(100%)',
                                transition: 'transform 600ms',
                            }}
                            transform={
                                timeZone === 'UTC'
                                    ? 'translateX(0%)'
                                    : 'translateX(100%)'
                            }
                            transition="transform 600ms"
                        ></Box>
                        <Flex justifyContent="space-evenly" marginLeft="-34px">
                            <Text
                                zIndex="1"
                                paddingLeft="0px"
                                lineHeight="1.3"
                                opacity={timeZone === 'UTC' ? '1' : '0.4'}
                                color={useColorModeValue(
                                    'light.100',
                                    'dark.80'
                                )}
                                _selected={{
                                    opacity: '1',
                                }}
                                _active={{
                                    opacity: '1',
                                }}
                                transition="opacity 600ms"
                            >
                                UTC
                            </Text>
                            <Text
                                zIndex="1"
                                paddingLeft="21px"
                                lineHeight="1.3"
                                opacity={timeZone === 'UTC' ? '0.4' : '1'}
                                color={useColorModeValue(
                                    'light.100',
                                    'dark.80'
                                )}
                                _selected={{
                                    opacity: '1',
                                }}
                                _active={{
                                    opacity: '1',
                                }}
                                transition="opacity 600ms"
                            >
                                {timeZoneAbbreviation(
                                    new Date(),
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone
                                )}
                            </Text>
                        </Flex>
                    </Button>

                    {/* *** PREMIUM BADGE *** */}
                    {isPremium && (
                        <Flex position="absolute" left="106px" mt="-22px">
                            <PremiumBadge />
                        </Flex>
                    )}

                    <Text pl={'12px'} pr="5px" fontSize="12px" opacity="0.4">
                        Time
                    </Text>
                </Flex>
                <Flex
                    alignItems="center"
                    fontSize="0.8rem"
                    fontWeight="300"
                    color={menuText}
                    pl={6}
                    pt={'5px'}
                    pb={'14px'}
                >
                    <Button
                        bg={useColorModeValue('#F2F5F8', 'dark.20')}
                        variant="outline"
                        fontSize="11px"
                        fontWeight="600"
                        height={{ base: '26px', md: '24px' }}
                        width="92px"
                        style={{
                            minWidth: '92px',
                        }}
                        justifyContent="initial"
                        paddingLeft="2px"
                        paddingRight="2px"
                        py="2px"
                        borderRadius="20px"
                        boxShadow="inset 0px 0px 1px 0px rgba(0,0,0,0.35)"
                        border="none"
                        // borderColor={
                        //     colorMode === 'light' ? '#E9EAEB' : '#33424f'
                        // }
                        {...noFocus}
                        index={colorMode === 'light' ? 0 : 1}
                        onClick={() => {
                            toggleColorMode((prevState) => !prevState)
                        }}
                        _active={{
                            bg: useColorModeValue('#F2F5F8', 'dark.30'),
                        }}
                        _focus={{
                            bg: useColorModeValue('#F2F5F8', 'dark.30'),
                        }}
                        _hover={{
                            bg: useColorModeValue('#EDF0F2', 'dark.30'),
                        }}
                    >
                        <Box
                            mt="0px"
                            ml="0px"
                            px="22px"
                            height={{ base: '22px', md: '20px' }}
                            width="40px"
                            borderRadius="20px"
                            bg={useColorModeValue('#ffffff', 'dark.42')}
                            boxShadow="0px 0px 1px 0px rgba(0,0,0,0.35)"
                            style={{
                                borderColor:
                                    colorMode === 'light'
                                        ? '#E9EAEB'
                                        : '#33424f',
                                transform:
                                    colorMode === 'dark'
                                        ? 'translateX(100%)'
                                        : 'translateX(0%)',
                                transition: 'transform 600ms',
                            }}
                            transform={
                                colorMode === 'dark'
                                    ? 'translateX(100%)'
                                    : 'translateX(0%)'
                            }
                            transition="transform 600ms"
                        ></Box>
                        <Flex justifyContent="space-evenly" marginLeft="-34px">
                            <Box
                                zIndex="1"
                                paddingLeft="3px"
                                opacity={colorMode === 'dark' ? '0.4' : '1'}
                                color={useColorModeValue(
                                    'light.100',
                                    'dark.80'
                                )}
                                _selected={{
                                    opacity: '1',
                                }}
                                _active={{
                                    opacity: '1',
                                }}
                                transition="opacity 600ms"
                            >
                                <Icon
                                    mx="2px"
                                    mt="2px"
                                    width="1.1em"
                                    height="1.1em"
                                    as={FiSun}
                                />
                            </Box>
                            <Box
                                zIndex="1"
                                paddingLeft="29px"
                                opacity={colorMode === 'light' ? '0.4' : '1'}
                                color={useColorModeValue(
                                    'light.100',
                                    'dark.80'
                                )}
                                _selected={{
                                    opacity: '1',
                                }}
                                _active={{
                                    opacity: '1',
                                }}
                                transition="opacity 600ms"
                            >
                                <Icon
                                    mx="2px"
                                    mt="2px"
                                    width="1.1em"
                                    height="1.1em"
                                    as={FiMoon}
                                />
                            </Box>
                        </Flex>
                    </Button>

                    <Text pl={'12px'} fontSize="12px" opacity="0.4">
                        Menu Theme
                    </Text>
                </Flex>
                <Divider
                    height="0px !important"
                    borderColor={divider}
                    opacity={useColorModeValue('0.15', '0.2')}
                />
                <EffectiveDate />
                <Divider
                    height="0px !important"
                    borderColor={divider}
                    opacity={useColorModeValue('0.15', '0.2')}
                />
                <Flex
                    x="100%"
                    justifyContent="space-around"
                    px={5}
                    pt={4}
                    pb={4}
                    bg={useColorModeValue('#F6F8FA', 'dark.20')}
                >
                    <AeropathLogo
                        onClick={() =>
                            window.open('https://aeropath.aero', '_blank')
                        }
                    />
                    <MetserviceLogo
                        onClick={() =>
                            window.open('https://metservice.com', '_blank')
                        }
                    />
                </Flex>
                <Divider
                    height="0px !important"
                    borderColor={divider}
                    opacity={useColorModeValue('0.15', '0.2')}
                />
                <Flex
                    justifyContent="space-evenly"
                    alignItems="center"
                    pt={3}
                    fontWeight="300"
                >
                    <Text
                        onClick={() => {
                            setShowTerms(true)
                            setSettingsMenuOpen(false)
                        }}
                        _hover={{
                            cursor: 'pointer',
                            color: 'light.200',
                        }}
                        fontSize="0.75rem"
                        color={menuText}
                    >
                        Terms and Conditions
                    </Text>
                </Flex>

                {isOldDevice && (
                    <Flex
                        justifyContent="space-evenly"
                        alignItems="center"
                        pt={2}
                        pb={2}
                        fontWeight="300"
                    >
                        <Text
                            onClick={() => {
                                setShowDeviceWarning(true)
                                setSettingsMenuOpen(false)
                            }}
                            _hover={{
                                cursor: 'pointer',
                                color: 'red.400',
                            }}
                            fontSize="0.65rem"
                            letterSpacing="0.5px"
                            color={menuText}
                        >
                            DETECTED: Potential Performance Issues
                        </Text>
                    </Flex>
                )}

                <Flex
                    pt={2}
                    pb={{
                        base: isMobile && isIOS ? '8' : '4',
                        md: '4',
                    }}
                    px={1}
                    width="100%"
                    fontFamily="'Open Sans', sans-serif"
                    fontSize="10px"
                    color={'#a8b0b2'}
                    justifyContent="space-evenly"
                >
                    <Flex>
                        <Text pr="3px">&copy; </Text>
                        <Text
                            _hover={{
                                cursor: 'pointer',
                                color: 'light.200',
                            }}
                            onClick={() =>
                                window.open('https://aeropath.aero', '_blank')
                            }
                        >
                            Aeropath Ltd
                        </Text>
                    </Flex>
                    {/*** Update after any updates. Keep at "0." until public version which will be 1.0.0 BETA ***/}
                    {/*** Located at: ./src/theme/components/versionNumber ***/}
                    <Flex gap="3px">
                        <Text>Version: </Text>
                        <VersionNumber />
                    </Flex>
                    <Flex gap="3px">
                        <Text>Updated: </Text>
                        <VersionDate />
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    return (
        <>
            <Portal>
                <IconButton //* ** Sidebar Admin Dashboard (Hamburger Button) ** */
                    {...noFocus}
                    id={'admin'}
                    zIndex={{
                        base: briefingChartsFullscreen ? '1100' : '1400',
                        md: '1400',
                    }}
                    display="inline-flex"
                    fontSize="18px"
                    pos={{ base: 'fixed', md: 'fixed' }}
                    top={{ base: '15px', md: '0px' }}
                    left={{ base: '15px', md: '0px' }}
                    height={{ base: '40px', md: '70px' }}
                    width={{ base: '40px', md: '50px' }}
                    minWidth="36px"
                    boxShadow={{
                        base:
                            fullscreenModalOpen || briefingStandard
                                ? 'none'
                                : isBriefingOnly || mapDisabled
                                ? '0px 4px 12px -5px rgba(0, 0, 0, 0.3)'
                                : hamburgerIconBoxShadow,
                        md: 'none',
                    }}
                    opacity={{
                        base: fullscreenModalOpen ? '0.8' : '1',
                        md: '1',
                    }}
                    background={{
                        base: fullscreenModalOpen
                            ? 'none'
                            : isBriefingOnly || mapDisabled
                            ? 'light.10'
                            : contrastHandler === 'high'
                            ? collapseButtonMobileHigh // If High Contrast Layer
                            : contrastHandler === 'medium'
                            ? collapseButtonMobileMedium // If Medium Contrast Layer
                            : collapseButtonMobileStandard, // All other layers
                        md: collapseButtonClosed,
                    }}
                    color={{
                        base:
                            fullscreenModalOpen || isBriefingOnly || mapDisabled
                                ? 'light.100'
                                : collapseButtonColor,
                        md: collapseButtonColor,
                    }}
                    backdropFilter="blur(1.5px)"
                    borderRadius={{ base: '50px', md: 'none' }}
                    _hover={{
                        border: 'none',
                        background: {
                            base: fullscreenModalOpen
                                ? 'none'
                                : isBriefingOnly || mapDisabled
                                ? 'light.10'
                                : contrastHandler === 'high'
                                ? collapseButtonMobileMedium // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? collapseButtonMobileStandard // If Medium Contrast Layer
                                : collapseButtonHoverMobile, // All other layers
                            md: collapseButtonHover,
                        },
                    }}
                    _active={{
                        border: 'none',
                        opacity: '1',
                        background: {
                            base: fullscreenModalOpen
                                ? 'none'
                                : isBriefingOnly || mapDisabled
                                ? 'light.10'
                                : contrastHandler === 'high'
                                ? collapseButtonMobileMedium // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? collapseButtonMobileStandard // If Medium Contrast Layer
                                : collapseButtonHoverMobile, // All other layers
                            md: collapseButtonHover,
                        },
                    }}
                    _focus={{
                        border: 'none',
                        opacity: '1',
                        background: {
                            base: fullscreenModalOpen
                                ? 'none'
                                : isBriefingOnly || mapDisabled
                                ? 'light.10'
                                : contrastHandler === 'high'
                                ? collapseButtonMobileMedium // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? collapseButtonMobileStandard // If Medium Contrast Layer
                                : collapseButtonHoverMobile, // All other layers
                            md: collapseButtonHover,
                        },
                    }}
                    icon={<FiMenu fontSize="20px" />}
                    onClick={() => setOpenMenu(!openMenu)}
                />
                {showMfaBadge && ( // MFA badge shows when MFA is due to expire soon or has expired
                    <Badge
                        pointerEvents="none"
                        zIndex="1400"
                        position="fixed"
                        left={{ base: '14px', md: '4px' }}
                        top={{ base: '19px', md: '19px' }}
                        variant="outline"
                        border="1px solid"
                        bg={
                            mfaState.state === 'EXPIRED' ||
                            mfaState.state === 'WARNING'
                                ? '#FFE0E0'
                                : mfaState.state === 'CAUTION'
                                ? '#FFEDDB'
                                : 'gray.100'
                        }
                        boxShadow="none"
                        borderColor={
                            mfaState.state === 'EXPIRED' ||
                            mfaState.state === 'WARNING'
                                ? 'red.500'
                                : mfaState.state === 'CAUTION'
                                ? 'warning.200'
                                : 'gray.300'
                        }
                        color={
                            mfaState.state === 'EXPIRED' ||
                            mfaState.state === 'WARNING'
                                ? 'red.500'
                                : mfaState.state === 'CAUTION'
                                ? 'warning.200'
                                : 'gray.400'
                        }
                        borderRadius="10"
                        p="0px"
                        fontSize="0.5rem"
                        fontWeight="700"
                        height="14px"
                        width="25px"
                        minWidth="25px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        cursor="pointer"
                    >
                        MFA
                    </Badge>
                )}
                {hasServicesMessages && ( // green dot shows when there are current messages
                    <Tooltip
                        label={
                            readMessages
                                ? 'All current messages read'
                                : messages?.length > newMessages?.length
                                ? `${
                                      newMessages?.length +
                                      ' new and ' +
                                      (messages?.length - newMessages?.length) +
                                      ' read messages'
                                  }`
                                : newMessages?.length === 1
                                ? '1 new message'
                                : `${newMessages?.length} new messages`
                        }
                        display={isDesktop ? 'flex' : 'none'}
                        hasArrow
                        arrowSize={8}
                        borderRadius="7px"
                        height="24px"
                        fontSize="0.75rem"
                        alignItems="center"
                        placement="right"
                        m="1px"
                        color="light.10"
                        background={'gray.500'}
                    >
                        <Badge
                            onClick={() => setSettingsMenuOpen(true)}
                            cursor="pointer"
                            zIndex="1400"
                            position="fixed"
                            left={{
                                base: showMfaBadge ? '41px' : '38px',
                                md: showMfaBadge ? '31px' : '28px',
                            }}
                            top={{
                                base: showMfaBadge ? '21px' : '22px',
                                md: showMfaBadge ? '21px' : '22px',
                            }}
                            background={
                                readMessages
                                    ? {
                                          base: fullscreenModalOpen
                                              ? 'light.10'
                                              : badgeCentre,
                                          md: collapseButtonClosed,
                                      }
                                    : 'light.200'
                            }
                            border="2px solid"
                            borderColor={'light.200'}
                            variant="solid"
                            color="white"
                            borderRadius="10"
                            fontSize="0.75rem"
                            fontWeight="600"
                            height={{ base: '10px', md: '10px' }}
                            maxWidth={{ base: '10px', md: '10px' }}
                            minWidth={{ base: '10px', md: '10px' }}
                            p="0"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        />
                    </Tooltip>
                )}
            </Portal>

            <Drawer
                variant="settings"
                isOpen={sidebar.isOpen}
                onClose={() => setSettingsMenuOpen(false)}
                placement="left"
                zIndex="1502"
                trapFocus={false}
            >
                <DrawerOverlay zIndex="1500" />
                <DrawerContent>
                    <SidebarContent
                        w="full"
                        borderRight="none"
                        paddingBottom="0"
                    />
                </DrawerContent>
            </Drawer>
        </>
    )
}

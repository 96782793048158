import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useUserMetaData } from '../../auth/hooks'
import { usePubNub } from 'pubnub-react'
import { useAuth0 } from '@auth0/auth0-react'
import { isEqual } from 'lodash'
import {
    Text,
    Flex,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useDisclosure,
    LightMode,
    ModalBody,
    ModalFooter,
    Tooltip,
    Button,
    Divider,
    keyframes,
    Box,
    useColorModeValue,
    Portal,
    Badge,
    // useToast,
} from '@chakra-ui/react'
import { isMobileOnly, isIOS } from 'react-device-detect'
import { ReactComponent as ServiceMessagesIcon } from '../../icons/assets/service-messages.svg'
import parse from 'html-react-parser'
import { useQuery } from 'react-query'

import {
    modalVisibilityState,
    setModalVisibilityState,
    onlineState,
    loginMessagesCheckedState,
    dataModeState,
    displayDisclaimerShowState,
    messagesButtonState,
    loginMessagesState,
    userProfileState,
} from '../../globalState'

//BUTTON PULSE
const pulsate = keyframes`
0% {-webkit-transform: scale(0.8, 0.8); opacity: 0.0; }
30% {opacity: 0.22; }
60% {opacity: 0.11; }
100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;  }`

export default function LoginMessages() {
    const { isLoading, getAccessTokenSilently } = useAuth0()
    const fetchLoginMessages = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/loginmessages`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }
    const userMetaData = useUserMetaData()
    const [profileData, setProfileData] = useRecoilState(userProfileState)
    const online = useRecoilValue(onlineState)
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setLoginMessagesChecked = useSetRecoilState(loginMessagesCheckedState)

    const setShowLoginMessageModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'viewLoginMessage', value })
        },
        [setModalVisibility]
    )

    const [messages, setMessages] = useRecoilState(loginMessagesState)
    const [messagesUpdated, setMessagesUpdated] =
        useRecoilState(messagesButtonState)
    const [newMessages, setNewMessages] = useState([])
    const [allMessages, setAllMessages] = useState([])
    const dataMode = useRecoilValue(dataModeState)
    const aeroMode = dataMode === 'aero'
    const advsMode = dataMode === 'advs'
    const displayDisclaimerShow = useRecoilValue(displayDisclaimerShowState)
    const [buttonShow, setButtonShow] = useState(false)
    const startTimer = useRef(null)
    const endTimer = useRef(null)

    const { data, status, refetch } = useQuery(
        'loginmessages',
        fetchLoginMessages,
        { refetchOnWindowFocus: false }
    )

    useEffect(() => {
        if (data) {
            setMessages(data)
        }
    }, [data, setMessages])

    const { isOpen, onOpen, onClose } = useDisclosure()

    const messagesRef = useRef(null)
    // const toast = useToast()

    // *** Check for Previously Read Service Messages ***
    useEffect(() => {
        if (!isLoading && messages?.length) {
            // Extracting the "id" and "starttime" values and append into a new array
            const messageIdsArray =
                messages &&
                messages.map(
                    (item) => `${item.id.toString()}-${item.starttime}`
                )
            const metaDataMessageIdsArray =
                profileData && profileData.readMessages
                    ? profileData.readMessages.map((item) => item)
                    : userMetaData && userMetaData.read_messages
                    ? userMetaData.read_messages.map((item) => item)
                    : []
            // // Finding the common "id" values between the two arrays
            const checkForNewMessages =
                messageIdsArray?.length &&
                messageIdsArray.filter(
                    (id) => !metaDataMessageIdsArray.includes(id)
                )
            setAllMessages(messageIdsArray?.length ? messageIdsArray : [])
            if (checkForNewMessages.length > 0) {
                setNewMessages(
                    checkForNewMessages?.length ? checkForNewMessages : []
                )
            }
        }
    }, [isLoading, data, messages, profileData, userMetaData])

    const readMessages = Boolean(
        !newMessages ||
            newMessages?.length === 0 ||
            (profileData?.allMessagesRead ?? false)
    )

    const readMessagesCallback = async () => {
        const accessToken = await getAccessTokenSilently()
        fetch(`${window.location.origin}/user/updatereadmessages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ readMessages: allMessages }),
        }).then((res) => {
            if (res.status === 200) {
                setProfileData((prevState) => {
                    const profile = prevState || {}
                    const allMessagesRead =
                        profile.allMessagesRead !== undefined
                            ? profile.allMessagesRead
                            : true
                    return {
                        ...prevState,
                        ...profile,
                        allMessagesRead,
                    }
                })
                onClose()
            } else {
                console.log('Error setting read messages')
                // toast({
                //     title: `Error connecting with our databases.`,
                //     description:
                //         'It looks like there was an error connecting to our server, please try again later',
                //     status: 'error',
                //     duration: 5000,
                //     isClosable: true,
                // })
            }
        })
    }

    function onCloseHandler() {
        readMessagesCallback()
        onClose()
        setShowLoginMessageModal(false)
    }

    useEffect(() => {
        modalVisibility.viewLoginMessage && onOpen()
    }, [onOpen, modalVisibility.viewLoginMessage])

    useEffect(() => {
        // trigger to open messages modal on page load (only when new messages not read by user)
        if (
            !isEqual(messages, messagesRef.current) &&
            messages.length >
                (Array.isArray(messagesRef.current)
                    ? messagesRef.current.length
                    : 0) &&
            newMessages?.length
        ) {
            setShowLoginMessageModal(messages.length ? true : false)
            setLoginMessagesChecked(true)
            messagesRef.current = messages
        }
    }, [
        setShowLoginMessageModal,
        messages,
        newMessages,
        allMessages,
        setLoginMessagesChecked,
    ])

    useEffect(() => {
        if (online && modalVisibility.viewLoginMessage) {
            onOpen()
            messages?.length && refetch()
        } else {
            onClose()
            setShowLoginMessageModal(false)
        }
        if (!online || !messages.length) {
            onClose()
            setShowLoginMessageModal(false)
        }
    }, [
        onOpen,
        onClose,
        setShowLoginMessageModal,
        online,
        messages,
        refetch,
        modalVisibility.viewLoginMessage,
    ])

    // Listen for message and update
    const pubnub = usePubNub()

    useEffect(() => {
        if (!aeroMode) {
            setButtonShow(true)
        } else if (displayDisclaimerShow) {
            setButtonShow(false)
        } else {
            setButtonShow(true)
            clearTimeout(startTimer.current)
            clearTimeout(endTimer.current)
        }
    }, [setButtonShow, aeroMode, displayDisclaimerShow])

    useEffect(() => {
        const channels = ['loginMessage']
        pubnub.addListener({
            message: (messageEvent) => {
                if (messageEvent.channel === channels[0]) {
                    setMessagesUpdated(true)
                }
            },
        })
        pubnub.subscribe({ channels })
    }, [pubnub, setMessagesUpdated, setShowLoginMessageModal])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const pulseAnimation = `${pulsate} infinite 2s linear`
    const buttonPulse = useColorModeValue('#00D99B', '#00D99B')

    // useEffect(() => {
    //     console.log(profileData, 'profileData')
    //     console.log(readMessages, 'readMessages')
    //     console.log('messages', messages)
    //     console.log(newMessages, 'newMessages')
    //     console.log('allMessages', allMessages)
    // }, [allMessages])

    return (
        <>
            <Portal>
                <Tooltip
                    label="New Messages"
                    placement={'left'}
                    background="none"
                    color="white"
                    boxShadow={'none'}
                    textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                    fontSize="0.85rem"
                    letterSpacing="0.2px"
                    fontWeight="500"
                    mb="3px"
                >
                    <Center
                        display="none !important" // TODO - to fix so doesn't show for messages set to display in future start times
                        position="fixed"
                        bottom={{
                            base:
                                isMobileOnly && isIOS && !messagesUpdated
                                    ? '140px'
                                    : !messagesUpdated
                                    ? '80px'
                                    : isMobileOnly && isIOS
                                    ? advsMode
                                        ? '180px'
                                        : '121px'
                                    : advsMode
                                    ? '165px'
                                    : '106px',
                            md:
                                !messagesUpdated && advsMode
                                    ? '50px'
                                    : !messagesUpdated
                                    ? '10px'
                                    : advsMode
                                    ? '85px'
                                    : '32px',
                        }}
                        right="15px"
                        transition="all ease-in-out 300ms"
                        zIndex="1201"
                        opacity={
                            isOpen || !buttonShow || !messagesUpdated
                                ? '0'
                                : '1'
                        }
                        visibility={
                            isOpen || !buttonShow || !messagesUpdated
                                ? 'hidden'
                                : 'visible'
                        }
                    >
                        <Flex
                            height="40px"
                            width="40px"
                            zIndex="1"
                            as="button"
                            pt="3px"
                            justifyContent="center"
                            alignItems="center"
                            _hover={{
                                transform: 'scale(1.1)',
                                background: 'rgba(20, 192, 154, 0.9)',
                            }}
                            _active={{
                                transform: 'scale(1.1)',
                                background: 'rgba(20, 192, 154, 0.9)',
                            }}
                            _focus={{
                                transform: 'scale(1.1)',
                                background: 'rgba(20, 192, 154, 0.9)',
                            }}
                            willChange="auto"
                            transition={'transform 0.5s, right 1s'}
                            borderRadius={'35px'}
                            bgColor={'rgba(0, 213, 153, 0.8)'}
                            backdropFilter="blur(5px)"
                            onClick={() => {
                                setMessagesUpdated(false)
                                setShowLoginMessageModal(true)
                            }}
                        >
                            <ServiceMessagesIcon
                                width="26px"
                                height="26px"
                                color="white"
                            />
                        </Flex>
                        <Box // PULSE
                            position="absolute"
                            w={{ base: '56px', md: '56px' }}
                            h={{ base: '56px', md: '56px' }}
                            margin="auto"
                            borderRadius="100%"
                            background={{
                                base: buttonPulse,
                                md: buttonPulse,
                            }}
                            sx={{
                                animationDuration: '1500ms',
                                // animationIterationCount: '1',
                                animationDelay: '0s',
                            }}
                            opacity={{
                                base: pulseAnimation ? '0' : '1',
                                md: pulseAnimation ? '0' : '1',
                            }}
                            transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 200ms"
                            animation={pulseAnimation}
                        ></Box>
                    </Center>
                </Tooltip>
            </Portal>

            {!modalVisibility.terms && !modalVisibility.announcements && (
                <LightMode>
                    <Modal
                        size="2xl"
                        onClose={onCloseHandler}
                        isOpen={isOpen}
                        isCentered
                        trapFocus={false}
                        variant="loginMessages"
                    >
                        <ModalOverlay />
                        <ModalContent
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            fontWeight="300"
                        >
                            <ModalHeader
                                backgroundColor="white"
                                textColor="light.100"
                                fontWeight="500"
                                boxShadow={'0px 0px 15px -1px rgba(0,0,0,0.15)'}
                                borderBottom="1px solid #eaeaea"
                            >
                                <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    ml="-15px"
                                >
                                    <ServiceMessagesIcon
                                        width="30px"
                                        height="30px"
                                        style={{ marginTop: '5px' }}
                                        color="#00D599"
                                    />
                                    <Text pl="10px">
                                        {messages.length === 1
                                            ? 'Service Message'
                                            : 'Service Messages'}
                                    </Text>
                                </Flex>
                            </ModalHeader>
                            <ModalBody
                                textColor="light.101"
                                overflowY="auto"
                                maxHeight={{
                                    base: 'calc(100vh - 250px)',
                                    md: '75vh',
                                }}
                            >
                                <Flex
                                    justifyContent="center"
                                    flexDirection="column"
                                    width="100%"
                                    mt="15px"
                                    mb="15px"
                                    fontSize={{ base: '0.8rem', md: '0.85rem' }}
                                    {...noFocus}
                                >
                                    {messages.length &&
                                        messages.map((e, i) => {
                                            const isNewMessage =
                                                newMessages?.includes(
                                                    `${e.id.toString()}-${
                                                        e.starttime
                                                    }`
                                                ) ?? false
                                            return (
                                                <Flex
                                                    key={e.id}
                                                    id={e.id}
                                                    justifyContent="flex-start"
                                                    flexDirection="column"
                                                    width="100%"
                                                    {...noFocus}
                                                >
                                                    {isNewMessage &&
                                                        !readMessages && (
                                                            <Flex
                                                                width="100%"
                                                                justifyContent="flex-end"
                                                                alignItems="center"
                                                                marginTop={
                                                                    i === 0
                                                                        ? '-5px'
                                                                        : '-20px'
                                                                }
                                                                marginBottom="7px"
                                                            >
                                                                <Badge
                                                                    pointerEvents="none"
                                                                    zIndex="2"
                                                                    position="relative"
                                                                    variant="outline"
                                                                    border="1px solid"
                                                                    bg={
                                                                        'light.200'
                                                                    }
                                                                    boxShadow="none"
                                                                    borderColor={
                                                                        'light.200'
                                                                    }
                                                                    color={
                                                                        'light.10'
                                                                    }
                                                                    borderRadius="10"
                                                                    p="0px"
                                                                    fontSize="0.7rem"
                                                                    fontWeight="600"
                                                                    letterSpacing="0.5px"
                                                                    height="18px"
                                                                    width="42px"
                                                                    minWidth="42px"
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    cursor="pointer"
                                                                    lineHeight="1.2"
                                                                >
                                                                    NEW
                                                                </Badge>
                                                            </Flex>
                                                        )}
                                                    <Flex
                                                        className="service-message-content"
                                                        justifyContent="center"
                                                        flexDirection="column"
                                                        width="100%"
                                                        marginBottom={6}
                                                        {...noFocus}
                                                        lineHeight="1.7"
                                                    >
                                                        {parse(e.content.text)}
                                                    </Flex>
                                                    {i + 1 !==
                                                        messages.length && (
                                                        <Divider
                                                            variant="modalFooter"
                                                            borderColor="gray.300"
                                                            marginTop={2}
                                                            marginBottom={8}
                                                        />
                                                    )}
                                                </Flex>
                                            )
                                        })}
                                </Flex>
                            </ModalBody>
                            <ModalFooter borderTop="1px solid #eaedef">
                                <Flex w="100%" justifyContent="center">
                                    <Button
                                        background="light.200"
                                        color="light.10"
                                        borderRadius="10px"
                                        isLoading={status === 'loading'}
                                        ml="5px"
                                        size="sm"
                                        _focus={{ border: 'none' }}
                                        onClick={onCloseHandler}
                                        minWidth="150px"
                                        maxWidth="100%"
                                        fontWeight="500"
                                        _hover={{
                                            background: 'light.201',
                                        }}
                                        _active={{
                                            background: 'light.201',
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </LightMode>
            )}
        </>
    )
}
